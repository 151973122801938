import React from "react";
import { FcPortraitMode, FcHighPriority } from "react-icons/fc";

function Footer() {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  //   const open = Boolean(anchorEl);

  //   const handleMenu = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };
  const user = JSON.parse(localStorage.getItem("user"));
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("log");
    window.location.href = "/";
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white">
      <a className="sidebar-toggle d-flex mr-2">
        <i className="hamburger align-self-center"></i>
      </a>

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-icon dropdown-toggle d-inline-block d-sm-none"
              href="#"
              data-toggle="dropdown">
              <i className="align-middle" data-feather="settings"></i>
            </a>

            <a
              className="nav-link dropdown-toggle d-none d-sm-inline-block"
              href="#"
              data-toggle="dropdown">
              <img
                src="/img/logo/tl.jpeg"
                className="avatar img-fluid rounded-circle mr-1"
                alt="Chris Wood"
              />{" "}
              <span className="text-dark">{user.username}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a className="dropdown-item" href="profile">
                <i className="align-middle mr-1" data-feather="user"></i>
                <FcPortraitMode></FcPortraitMode> Profile{" "}
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" onClick={handleLogout} href="#">
                {" "}
                <FcHighPriority></FcHighPriority>Sign out{" "}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Footer;
