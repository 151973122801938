
import { Outlet } from "react-router-dom";
import { onMessageListener } from "../firebase";
import swal from "sweetalert";


const Layout = () => {
  onMessageListener()
    .then((payload) => {
      swal(
        payload.data.title,
        payload.data.body,
        'info'
      );
    })
    .catch((err) => console.log(err));
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
