import React, { useEffect, useState } from "react";
const $ = require("jquery");
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import { Chrono } from "react-chrono";
import axios from "axios";
import moment from "moment";
import { Url } from "../../../global_konstanta/global";





function ActivityPage() {
  const [activity, setactivityTiket] = useState([]);
  const [datas, setDatas] = useState([]);

  const getactivityTiket = async () => {
    await axios
      .get(`${Url}/activity/DT0645594648`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setactivityTiket(value.data.activity);
        var data = activity.map(items => {
          return {
            title: items.activity,
            cardTitle: moment(items?.date_activity).format(
              "D MMMM YYYY"
            ),
            cardSubtitle: moment(items?.date_activity).format(
              "D MMMM YYYY"
            ),
            cardDetailedText: items.flag_activity
          };
        });
        console.log(data);
        setDatas(data);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };

  useEffect(() => {
    // setDatas();

    // getTikets();
    // getTiket();
    // getCategoris();
    // getTiketsClose();
    // getactivityTiket();
    // setDatas();

    $(document).ready(function () {
      setTimeout(function () {
        getactivityTiket();
      }, 1000);

    });
  }, []);
  return (<>
    <Navbar />
    <main className="main">
      <Sidebar />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="d-table-cell align-middle">
              <div className="card mt-5">
                <div className="card-body">
                  <Chrono
                    items={datas}
                    mode="VERTICAL"
                    flipLayout
                  />
                </div>
                <div className="p-2">
                  <a className="btn btn-warning btn-sm mb-4 p-2 container-fluid" href="dashboard">Back to Home</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>

  );
}

export default ActivityPage;
