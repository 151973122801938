import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { FcOpenedFolder } from "react-icons/fc";
import * as Icon from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import moment from "moment";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import { Url } from "../../../global_konstanta/global";
import axios from "axios";
import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row,
  Badge
} from "react-bootstrap";

export default function Close_Aproved(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [tikets, setTikets] = useState([]);
  //   const [setTiketsClose] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show2, setShow2] = useState(false);
  const [judul, setJudul] = useState();
  const [tiket, setTiket] = useState([]);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose3 = () => setShow3(false);
  const handleClose2 = () => setShow2(false);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);
  const handleShow = () => setShow(true);
  const tulisJudul = (jdl) => setJudul(jdl);

  // 
  useEffect(() => {
    if (props.report === "done") {
      setJudul("Data Report Approved");
    } else if (props.report === "pendingdone") {
      setJudul("Data Report  Approval");
    } else if (props.report === "riject") {
      setJudul("Data Report Rejected");
    } else {
      setJudul(" Insert Link Report");
    }
    getTikets();
    getTiket();
    // getTiketsClose();
    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 1000);
    });
  }, []);

  const getTikets = async () => {
    await axios
      .get(`${Url}/tiketLink?status=all&report=${props.report}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTikets(value.data.report);
      })
      .catch((err) => {
        console.log(`errorrr${err}`);
      });
  };

  const getTiket = async (id) => {
    await axios
      .get(`${Url}/tiketbyId/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTiket(value.data.data);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };


  const onUpdate = async (values) => {
    if (values === null) {
      console.log(values);
    } else {
      await axios({
        method: 'put',
        url: Url + `/tiket/${values.id}`, headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        },
        data:
          values,
      }).then(function () {
        getTikets();
        handleClose();
        handleClose3();
      });
    }
  };
  const Aproved = async () => {
    console.table(tiket);
    await axios({
      method: "put",
      url: Url + `/tiket/${tiket.id}`,
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: {
        status_link: "done",
        dt_status_ticket: "Close"
      }
    }).then(function () {
      getTikets();
      handleClose();
      handleClose3();
    });
  };

  const Reject = async (values) => {
    await axios({
        method: "put",
        url: `${Url}/tiket/${values.id}`, headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
        },

        data: values
    })
        .then(function (response) {
            console.log(response);
            getTikets();
            handleClose();
            handleClose3();
           
        })
        .catch(function (error) {
            console.log(error);
            handleClose();
            handleClose3();
           
        });
};
  if (user.role === "user" || user.role === "manager") {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title text-bold">{judul}</div>
                  </div>
                  <div className="card-body table-responsive table-sm">
                    <table className="container table" id="table_user">
                      <thead>
                        <tr>
                          <th>Ticket Number</th>
                          <th>Date Request</th>
                          <th>Request By</th>
                          <th>Location</th>
                          <th>Ticket Name</th>
                          <th>Region</th>
                          <th>Status Ticket</th>
                          <th>Category</th>
                          <th>Link Report</th>
                          <th>
                            {props.report === "waiting"
                              ? "Action"
                              : props.report === "done"
                                ? "Action"
                                : props.report === "pendingdone"
                                  ? "Action"
                                  : props.report === "riject"
                                    ? "Action"
                                    : ""}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tikets.map((orang) => (
                          <tr key={orang.id}>
                            <td>{orang.dt_ticket_number}</td>
                            <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                            <td>{orang.User?.request_name}</td>
                            <td>{orang.dt_city}</td>
                            <td>{orang.dt_primary_plan}</td>
                            <td>{orang.dt_region}</td>
                            <td>{orang.dt_status_ticket}</td>
                            <td>{orang.dt_category}</td>
                            <td>
                              {props.report === "done" ? (
                                <Badge bg="primary">
                                  {" "}
                                  Link Report :{" "}
                                  <a
                                    href={orang.dt_link_report}
                                    target="_blank"
                                    className="text-white"
                                    rel="noopener noreferrer">
                                    Open{" "}
                                    <FcOpenedFolder></FcOpenedFolder>
                                  </a>
                                </Badge>
                              ) : props.report === "pendingdone" ? (
                                <Badge bg="primary">
                                  {" "} Link Report :{" "}
                                  <a
                                    href={orang.dt_link_report}
                                    target="_blank"
                                    className="text-white"
                                    rel="noopener noreferrer">
                                    Open{" "}
                                    <FcOpenedFolder></FcOpenedFolder>
                                  </a>
                                </Badge>
                              ) : props.report === "waiting" ? (
                                <Badge bg="danger">
                                  {" "}
                                  Silahkan Update Link{" "}
                                </Badge>
                              ) : props.report === "riject" ? (
                                <Badge bg="danger">
                                  {" "}
                                  Silahkan Update Link{" "}
                                </Badge>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <div className="btn-group">
                                {props.report === "pendingdone" ? (
                                  <ButtonBS
                                    variant="primary float-right"
                                    onClick={() => {
                                      handleShow();
                                      tulisJudul("Approval  Report");
                                      getTiket(orang.id);
                                    }}>
                                    Approval
                                  </ButtonBS>
                                  
                                ) : props.report === "waiting" ? (
                                  <ButtonBS
                                    size="sm"
                                    variant="primary"
                                    onClick={() => {
                                      handleShow();
                                      tulisJudul("Tiket Update");
                                      getTiket(orang.id);
                                    }}>
                                    Action
                                  </ButtonBS>
                                ) : props.report === "done" ? (
                                  <ButtonBS
                                    variant="warning float-right"
                                    onClick={() => {
                                      handleShow1();
                                      tulisJudul("Report Show");
                                      getTiket(orang.id);
                                    }}>
                                    show
                                  </ButtonBS>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <FormBS onSubmit={Aproved}>
              <Modal.Body>
                <div className="col-12">
                  <Row className="form-group">
                  Silahkan melakukan Approval pada Report ini!
            
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <ButtonBS variant="secondary" size="sm" onClick={handleClose}>
                  Close
                </ButtonBS>
                <ButtonBS variant="success" size="sm" type="submit">
                  <Icon.Check2Square className="mx-1" size={15} />
                  Approve
                </ButtonBS>
                <ButtonBS ariant="danger" size="sm" onClick={() => {
                                      handleShow3();
                                      getTiket(tiket.id);
                                    }}>
                  <Icon.Check2Square className="mx-1" size={15} />
                  Reject
                </ButtonBS>
              </Modal.Footer>
            </FormBS>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Detail Data Tiket</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_request_date: moment.utc(tiket?.dt_request_date).format('D MMMM YYYY'),
                dt_region: tiket?.dt_region,
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                dt_link_report: tiket?.dt_link_report
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_category"
                              type="text"
                              component="input"
                              placeholder="category"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Team Dt</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_team"
                              type="text"
                              component="input"
                              placeholder="team_dt"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Kota</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">primary Plan</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="primary_plan"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="dt_customer_name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Pic Tsel</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_pic_request_tsel"
                              type="text"
                              component="input"
                              placeholder="pic_tsel"
                              rows="2"
                            />
                          </Col>
                        </Row>
                       
                    
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show3} onHide={handleClose3}>
                        <Modal.Header closeButton>
                            <Modal.Title>Catatan</Modal.Title>
                        </Modal.Header>
                        {/* {console.log("inisalah=" + user.username)} */}
                        <Form
                            onSubmit={Reject} initialValues={{

                                id: tiket?.id,
                                // dt_ticket_number: tiket?.dt_ticket_number,
                                dt_city: tiket?.dt_city,
                                dt_primary_plan: tiket?.dt_primary_plan,
                                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                                dt_customer_name: tiket?.dt_customer_name,
                                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                                dt_issue: tiket?.dt_issue,
                                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                                dt_site_cover: tiket?.dt_site_cover,
                                // dt_status_activity: tiket?.dt_status_activity,
                                dt_status_ticket: tiket?.dt_status_ticket,
                                status_link: "riject",
                                dt_remark: tiket?.dt_remark,
                                dt_category: tiket?.dt_category,
                                dt_issue_reject2: tiket?.dt_issue_reject2
                                // dt_link_report: tiket?.dt_link_report,

                            }}
                            render={({ handleSubmit, }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>
                                        <Row className="form-group">
                                            <Col xs={4} md={3}>
                                                <label className="form-label">Rejected Note</label>
                                            </Col>
                                            <Col xs={8} md={9}>
                                                <Field
                                                    name="dt_issue_reject2"
                                                    component="textarea"
                                                    placeholder="Rejected Note"
                                                    className="form-control"
                                                    rows="2"
                                                />
                                            </Col>
                                        </Row></Modal.Body>
                                    <Modal.Footer>
                                        <ButtonBS variant="secondary" onClick={handleClose3}>
                                            Close
                                        </ButtonBS>
                                        <ButtonBS variant="danger" type="submit">
                                            <Icon.Check2Square className="mx-1" size={15} />
                                            Reject
                                        </ButtonBS>
                                    </Modal.Footer>
                                </FormBS>
                            )}
                        />
                    </Modal>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title text-bold">{judul}</div>
                  </div>
                  <div className="card-body table-responsive table-sm">
                    <table className="container table" id="table_user">
                      <thead>
                        <tr>
                          <th>Ticket Number</th>
                          <th>Date Request</th>
                          <th>Request By</th>
                          <th>Location</th>
                          <th>Ticket Name</th>
                          <th>Region</th>
                          <th>Status Ticket</th>
                          <th>Category</th>
                          <th> Link Report</th>
                          <th>
                            {props.report === "waiting"
                              ? "Action"
                              : props.report === "done"
                                ? "Action"
                                : props.report == "riject"
                                  ? "Action"
                                  : ""}
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {tikets.map((orang) => (
                          <tr key={orang.id}>
                            <td>{orang.dt_ticket_number}</td>
                            <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                            <td>{orang.User?.request_name}</td>
                            <td>{orang.dt_city}</td>
                            <td>{orang.dt_primary_plan}</td>
                            <td>{orang.dt_region}</td>
                            <td>{orang.dt_status_ticket}</td>
                            <td>{orang.dt_category}</td>
                            <td>
                              {props.report === "done" ? (
                                <Badge bg="primary">
                                  {" "}
                                  Link Report :{" "}
                                  <a
                                    href={orang.dt_link_report}
                                    target="_blank"
                                    className="text-white"
                                    rel="noopener noreferrer">
                                    Open{" "}
                                    <FcOpenedFolder></FcOpenedFolder>
                                  </a>
                                </Badge>
                              ) : props.report === "pendingdone" ? (
                                <Badge bg="primary">
                                  {" "}
                                  Link Report :{" "}
                                  <a
                                    href={orang.dt_link_report}
                                    target="_blank"
                                    className="text-white"
                                    rel="noopener noreferrer">
                                    Open{" "}
                                    <FcOpenedFolder></FcOpenedFolder>
                                  </a>{" "}
                                </Badge>
                              ) : props.report === "waiting" ? (
                                <Badge bg="danger">
                                  {" "}
                                  Silahkan Update Link{" "}
                                </Badge>
                              ) : props.report === "riject" ? (
                                <Badge bg="warning">
                                  {" "}
                                  Silahkan Update ulang Link{" "}
                                </Badge>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <div className="btn-group">
                                {props.report === "done" ? (
                                  <ButtonBS
                                    variant="success float-right"
                                    onClick={() => {
                                      handleShow1();
                                      tulisJudul("Detail Data Report");
                                      getTiket(orang.id);
                                    }}>
                                    Show
                                  </ButtonBS>
                                ) : props.report === "waiting" ? (
                                  <ButtonBS
                                    size="sm"
                                    variant="primary"
                                    onClick={() => {
                                      handleShow();
                                      tulisJudul("Insert  Report");
                                      getTiket(orang.id);
                                    }}>
                                    Action
                                  </ButtonBS>
                                ) : props.report === "riject" ? (
                                 <div className="btn-group">
                                   <ButtonBS
                                      size="sm"
                                      variant="success"
                                      onClick={() => {
                                        handleShow2();
                                        tulisJudul("Insert  Report");
                                        getTiket(orang.id);
                                      }}>
                                      Note
                                    </ButtonBS>
                                  <ButtonBS
                                    size="sm"
                                    variant="primary"
                                    onClick={() => {
                                      handleShow3();
                                      tulisJudul("Insert  Report");
                                      getTiket(orang.id);
                                    }}>
                                    Update
                                  </ButtonBS>
                                  </div>
                                  ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                // dt_request_date: moment(tiket?.dt_request_date).format('D MMMM YYYY'),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                status_link: "pendingdone",
                dt_issue_reject2: tiket?.dt_issue_reject2,
                dt_link_report: tiket?.dt_link_report
                // updated_at: moment(tiket?.updated_at).format('D MMMM YYYY'),
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Link Report</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_link_report"
                              type="url"
                              value="ClosePending"
                              component="input"
                              placeholder="https://example.com"
                              pattern="https://.*"
                              required
                              rows="2"
                            />
                          </Col>
                        </Row>
                        
                  
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS
                      variant="secondary"
                      size="sm"
                      onClick={handleClose}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="warning" size="sm" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Insert Link Report
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                // dt_request_date: moment(tiket?.dt_request_date).format('D MMMM YYYY'),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                status_link: "pendingdone",
                dt_issue_reject2: tiket?.dt_issue_reject2,
                dt_link_report: tiket?.dt_link_report
                // updated_at: moment(tiket?.updated_at).format('D MMMM YYYY'),
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Link Report</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_link_report"
                              type="url"
                              value="ClosePending"
                              component="input"
                              placeholder="https://example.com"
                              pattern="https://.*"
                              required
                              rows="2"
                            />
                          </Col>
                        </Row>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS
                      variant="secondary"
                      size="sm"
                      onClick={handleClose3}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="warning" size="sm" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Link Report
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                // dt_request_date: moment(tiket?.dt_request_date).format('D MMMM YYYY'),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                status_link: "pendingdone",
                dt_issue_reject2: tiket?.dt_issue_reject2,
                dt_link_report: tiket?.dt_link_report
                // updated_at: moment(tiket?.updated_at).format('D MMMM YYYY'),
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                        <Row className="form-group">
                                            <Col xs={4} md={3}>
                                                <label className="form-label"> Rejected Note</label>
                                            </Col>
                                            <Col xs={8} md={9}>
                                                <Field
                                                    name="dt_issue_reject2"
                                                    component="textarea"
                                                    placeholder="Rejected Note"
                                                    className="form-control"
                                                    rows="2"
                                                />
                                            </Col>
                                        </Row>
                  
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS
                      variant="secondary"
                      size="sm"
                      onClick={handleClose2}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="warning" size="sm" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Link Report
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Detail Data Tiket</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment.utc(tiket?.dt_request_date).format('D MMMM YYYY'),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                dt_link_report: tiket?.dt_link_report
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_category"
                              type="text"
                              component="input"
                              placeholder="category"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Team Dt</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_team"
                              type="text"
                              component="input"
                              placeholder="team_dt"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Kota</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">primary Plan</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="primary_plan"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="dt_customer_name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Pic Tsel</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_pic_request_tsel"
                              type="text"
                              component="input"
                              placeholder="pic_tsel"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        {/* <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Issue</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_issue"
                              type="text"
                              component="input"
                              placeholder="Issue"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row> */}
                        {/* <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Status Activity
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_status_activity"
                              type="text"
                              component="input"
                              placeholder="status_activity"
                              rows="2"
                            />
                          </Col>
                        </Row> */}
                        {/* <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Status Ticket</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        className="form-control sm"
                                                        name="dt_status_ticket"
                                                        type="text"
                                                        component="input"
                                                        placeholder="status_ticket"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row> */}
                        {/* <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Data Remark</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_remark"
                              type="text"
                              component="input"
                              placeholder="remark"
                              rows="2"
                            />
                          </Col>
                        </Row> */}
                      
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
        </div>
      </>
    );
  }
}
