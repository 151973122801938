import React, { useEffect, useState } from "react";
// import { Token } from "../../firebase";
import { Url } from "../../global_konstanta/global";
import swal from "sweetalert";
import "./login.css";

async function loginUser(credentials) {
  return await fetch(`${Url}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(credentials)
  }).then((data) => data.json());
}

function Password() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showLoading, setshowLoading] = useState(false);


  const handleSubmit = async (e) => {

    setshowLoading(true);
    e.preventDefault();
    const response = await loginUser({
      username,
      password
    });


    if (response.status == true) {
      setTimeout(() => {
        setshowLoading(false);
      }, 5000);

      localStorage.setItem("user", JSON.stringify(response["users"]));
      localStorage.setItem("accessToken", response.token);
      window.location.href = "/dashboard";
    } else {
      setshowLoading(true);
      setTimeout(() => {
        setshowLoading(false);
      }, 5000);
      swal(
        "Failed",
        "Silahkan cek Kembali Username And Password anda",
        "error"
      );
    }
  };

  useEffect(() => {

  }, []);
  return (
    <main className="main mainbgtelkom" id="color-change-5x">
      <div className="container">
        <div className="row h-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="card mt-5 transparant mainbgtelkom">
                <div className="card-body mt-5">
                  <div className="m-sm-4">
                    <div className="text-center">
                      <img src="/img/logo/telkom.png" width={180} />
                      <p className="lead">
                        Change Password
                      </p>
                    </div>
                    {showLoading ? (
                      <div className="justify-content-center text-center">
                        <img
                          src="/img/logo/LOADING.png"
                          className="img-fluid"
                          alt="oke"
                        />
                      </div>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Username
                          </label>
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            id="username"
                            name="username"
                            label="Username"
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="Masukan Nomor Hp Kamu"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            className="form-control form-control-sm"
                            type="password"
                            id="password"
                            name="password"
                            label="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                          />
                          <small>
                            <a
                              className="text-warning"
                              href="/login">
                              Back to Login
                            </a>
                          </small>
                        </div>

                        <div className="text-center mt-3 mb-5">
                          <button
                            type="submit"
                            color="outline-warning"
                            className="btn btn-warning btn-block">
                            SEND OTP
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center mt-mt-auto">
                <h5 className="text-light font-weight-bold lead">
                  {" "}
                  Download Aplikasi On
                </h5>
                <img src="/img/logo/playstore.png" width="20%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Password;
