import { useEffect, useState } from "react";
import { FiDatabase, FiUpload, FiFolder } from "react-icons/fi";
import { AiOutlineEnvironment } from "react-icons/ai";
const $ = require("jquery");
import moment from "moment";
$.DataTable = require("datatables.net");
import {
  FcPieChart,
  FcPositiveDynamic,
  FcNegativeDynamic,
  FcAdvertising,
  FcLock,FcAlarmClock
} from "react-icons/fc";
// import Chartcolumn from "../../components/Chartcolumn";
import Barcolumn from "../../components/Barcolumn";
// import MiringBar from "../../components/MiringBar";
import Linecharts from "../../components/Linechart";
// import Donut from "../../components/Dunut";
 import Piechart from "../../components/Piechart";
// import Herizontalchart from "../../components/Herizontalchart";
// import Donutchart from "../../components/Donutchart";
import Navbar from "../../components/Navbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { Form, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Chrono } from "react-chrono";
export default function Dashboard() {
  const [ip, setIP] = useState("");
  const [show, setShow] = useState(false);
  const [showfile, setShowfile] = useState(false);
  const [showdownload, setShowdownload] = useState(false);
  const [showProggres, setShowProggres] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleClosefile = () => setShowfile(false);
  const handleShowfile = () => setShowfile(true);
  const handleClosedownload = () => setShowdownload(false);
  const handleShowdownload = () => setShowdownload(true);
  const handleProggres = () => setShowProggres(true);
  const handleCloseProggres = () => setShowProggres(false);
  const [statistics, setStatistics] = useState([]);
   const [statisticsopen, setStatisticsopen] = useState([]);
   const [statisticsclose, setStatisticsclose] = useState([]);
  const [userlog, setUserlog] = useState([]);
  const [progress, setProgress] = useState(null);
  const [totalTicket, settotalTicket] = useState(null);
  const [submitted, setSubmitted] = useState(null);
  const [approved, setApproved] = useState(null);
  const [submit_percentage, setsubmit_percentage] = useState(null);
  const [Approved_percentage, setApproved_percentage] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    setLoading(false);
    try {
      const response = await axios({
        method: "post",
        url: `${Url}/tiket/upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      });

      setTimeout(function () {
        handleClosefile();
        console.log(response);
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const getSubmitAprov = async () => {
    const response = await axios.get(`${Url}/tiket/static_data`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    settotalTicket(parseInt(response.data.static_data[0]["jml"]));
    setSubmitted(parseInt(response.data.static_data[3]["jml"]));
    setApproved(parseInt(response.data.static_data[1]["jml"]));
    setsubmit_percentage(response.data.static_data[4]["jml"]);
    setApproved_percentage(response.data.static_data[6]["jml"]);
  };
  const getDataAchv = async () => {
    const response = await axios.get(`${Url}/dashboard/tiket_tottal?region=all`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    
    setStatistics(response.data);
    // console.log(`ini=>` + response.data.Tiket[1].total);
    setStatisticsopen(response.data.Tiket[0]);
    setStatisticsclose(response.data.Tiket[1]);
    console.log = ("nama=> " );
    setProgress(parseInt(response.data.Achievement));
    await axios.get(`${Url}/log`).then((res) => {
      setUserlog(res.data.log);
    });
  };
  const onHandleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries());
    // console.log(values.start_date);
    await axios
      .get(
        `${Url}/tiket/export?start_date=${values.start_date}&end_date=${values.end_date}`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Disposition": "attachment; filename=" + "tiket.xlsx",
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        }
      )
      .then(function (response) {
        // console.log(response);
        const temp = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "tiket.xlsx",moment.utc().format(
          "D MMMM YYYY")); //or any other extension
        document.body.appendChild(link);
        link.click();
        handleClosedownload();
      })
      .catch(function (error) {
         console.log(error);
      });
  };
  const infoSubmit = async () => {
    var cek = localStorage.getItem("log");
    await axios.get("https://geolocation-db.com/json/").then((res) => {
      navigator.geolocation.getCurrentPosition(function (position) {
        if (!cek) {
          axios({
            method: "post",
            url: Url + "/insertlog",
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`
            },
            data: {
              ip_address: res.data.IPv4,
              long: position.coords.longitude,
              lat: position.coords.latitude
            }
          })
            .then(() => {
              localStorage.setItem("log", "okelH");
            })
            .catch(() => {
             
            });
        }
      });
      setIP(res.data.IPv4);
    });
  };
 
   
  
  useEffect(() => {
    infoSubmit();
    getDataAchv();
    getSubmitAprov();
    setTimeout(() => {
      $(document).ready(function () {
        $("#table_log").dataTable();
      });
    }, 2000);
  }, [userlog]);
  const user = JSON.parse(localStorage.getItem("user"));
  if (user.role === "root") {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
           <main className="content">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="container-fluid mb-4 p-2">
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={handleShowdownload}>
                    <FiDatabase></FiDatabase> Download Format Excel
                  </Button>{" "}
                  <Button
                    variant="outline-warning"
                    size="sm"
                    onClick={handleShowfile}>
                    <FiUpload></FiUpload> Import Data Ticket Xls
                  </Button>
                  {" "}
                  <Button
                    variant="outline-warning"
                    size="sm"
                    onClick={handleProggres}>
                    <FiUpload></FiUpload> proggress
                  </Button>
                  <div className="float-right">
                    <h4 className="w-80 text-warning">
                      <b>
                        <FcLock></FcLock> Your IP Address is
                      </b>
                    </h4>
                    <h6 className="text-warning float-right">{ip}</h6>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      <table className="container">
                        <tr>
                          <td>Dt 20 april 2020</td>
                          <td>
                            {" "}
                            <Button variant="warning float-right" size="sm">
                              <FiFolder></FiFolder> unduh{" "}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showfile} onHide={handleClosefile}>
                    <Form onSubmit={handleSubmit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Upload Ticket</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group controlId="formFileLg">
                          <Form.Label>File Data Tiket</Form.Label>
                          <Form.Control
                            type="file"
                            name="file"
                            size="sm"
                            onChange={handleFileSelect}
                          />
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClosefile}>
                          Close
                        </Button>
                        {loading ? (
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading...
                          </Button>
                        ) : (
                          <Button variant="warning" type="submit">
                            Import Ticket
                          </Button>
                        )}
                      </Modal.Footer>
                    </Form>
                  </Modal>
                  <Modal show={showdownload} onHide={handleClosedownload}>
                    <Form onSubmit={onHandleSubmit}>
                      <Modal.Header>
                        <Modal.Title>Download Ticket</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="start_date"
                            size="sm"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control type="date" name="end_date" size="sm" />
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleClosedownload}>
                          Close
                        </Button>
                        <Button variant="warning" type="submit">
                          Download Ticket
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                  <Modal show={showProggres} onHide={handleCloseProggres}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      {" "}
                      <Chrono
                        items={[
                          {
                            title: "May 1940",
                            cardTitle: "Dunkirk",
                            url: "http://google.com",
                            cardSubtitle:
                              "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
                            cardDetailedText: `On 10 May 1940, Hitler began his long-awaited offensive in the west by invading neutral Holland and Belgium and attacking northern France. Holland capitulated after only five days of fighting, and the Belgians surrendered on 28 May. With the success of the German ‘Blitzkrieg’, the British Expeditionary Force and French troops were in danger of being cut off and destroyed.`
                          },
                          {
                            title: "25 July 1940",
                            cardTitle: "The Battle of Britain",
                            cardSubtitle: `RAF Spitfire pilots scramble for their planes`,
                            cardDetailedText: `After Frances surrender in June 1940, Churchill told the British people, “Hitler knows that he will have to break us in this island or lose the war”. To mount a successful invasion, the Germans had to gain air superiority. The first phase of the battle began on 10 July with Luftwaffe attacks on shipping in the Channel.
The following month, RAF Fighter Command airfields and aircraft factories came under attack. Under the dynamic direction of Lord Beaverbrook, production of Spitfire and Hurricane fighters increased, and despite its losses in pilots and planes, the RAF was never as seriously weakened as the Germans supposed.`
                          },
                          {
                            title: "June 1941",
                            cardTitle: "Operation Barbarossa",
                            cardSubtitle: `A column of Red Army prisoners taken during the first days of the German invasion`,
                            cardDetailedText: `Since the 1920s, Hitler had seen Russia, with its immense natural resources, as the principal target for conquest and expansion. It would provide, he believed, the necessary ‘Lebensraum’, or living space, for the German people. And by conquering Russia, Hitler would also destroy the “Jewish pestilential creed of Bolshevism”. His non-aggression pact with Stalin in August 1939 he regarded as a mere temporary expedient.
Barely a month after the fall of France, and while the Battle of Britain was being fought, Hitler started planning for the Blitzkrieg campaign against Russia, which began on 22 June 1941. Despite repeated warnings, Stalin was taken by surprise, and for the first few months the Germans achieved spectacular victories, capturing huge swathes of land and hundreds of thousands of prisoners. But they failed to take Moscow or Leningrad before winter set in.
On 5/6 December, the Red Army launched a counter-offensive which removed the immediate threat to the Soviet capital. It also brought the German high command to the brink of a catastrophic military crisis. Hitler stepped in and took personal command. His intervention was decisive and he later boasted, “That we overcame this winter and are today in a position again to proceed victoriously… is solely attributable to the bravery of the soldiers at the front and my firm will to hold out…”`
                          }
                        ]}
                        mode="VERTICAL"
                        flipLayout
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseProggres}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-xl-6 d-flex">
                  <div className="card l-bg-telkom mh-100 flex-fill">
                    <div className="card-body py-4">
                      <div className="float-right text-danger"></div>
                      <h2 className="mb-2 text-light">Statistic</h2>
                      <div className="text-light">
                        {submit_percentage}% : ({submitted} / {totalTicket})
                      </div>
                      <div className="mb-1">
                        <strong> Submited Ticket </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 d-flex">
                  <div className="card mh-100 flex-fill">
                    <div className="card-body py-4">
                      <div className="float-right text-danger"></div>
                      <h2 className="mb-2 text-warning">Statistic</h2>
                      <div className="text-warning">
                        {Approved_percentage}% : ({approved} / {totalTicket})
                      </div>
                      <div className="mb-1 text-warning">
                        <strong>Approved Ticket</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-xl-12 ">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <span className="badge badge-warning">Information</span>
                      <FcAdvertising size={40}></FcAdvertising>
                    </div>
                    <div className="card-body table-responsive table-sm">
                      <table
                        className="container-fluid table table-striped "
                        id="table_log">
                        <thead>
                          <tr>
                            <th>Name </th>
                            <th>Ip Device </th>
                            <th>Lokasi</th>
                            <th>Jam </th>
                          
                          </tr>
                        </thead>
                        <tbody>
                          {userlog?.map((user) => (
                            <tr key={user?.id}>
                              <td>{user?.nama}</td>
                              <td>{user?.ip_address}</td>
                              <td>
                                <a
                                  className="text-warning"
                                  href={`https://waze.com/ul?ll=${user?.lat},${user?.long}&navigate=yes'`}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  {user?.lat} {user?.long}
                                </a>
                                <AiOutlineEnvironment></AiOutlineEnvironment>
                              </td>
                              <td> <FcAlarmClock></FcAlarmClock> {user?.time_login}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
              </div>
          </main>
          <div className="row">
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className=" mt-1 mr-3">
                          <FcPieChart size={70}></FcPieChart>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Total Ticket
                          </div>
                          <h2 className="d-flex align-items-center  mt-3  font-weight-normal text-muted">
                            {statistics.total_tiket}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className="mt-2 mr-3">
                          <FcPositiveDynamic size={60}></FcPositiveDynamic>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Open Ticket
                            
                          </div>
                          <h2 className="d-flex align-items-center mt-3 font-weight-normal text-muted">
                            {statisticsopen.total}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card rounded flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className="d-inline-block mt-2 mr-3">
                          <FcNegativeDynamic size={70}></FcNegativeDynamic>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Close
                          </div>
                          <h2 className="d-flex align-items-center mt-3 font-weight-normal text-muted">
                          {statisticsclose.total}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card rounded flex-fill">
                    <div className="card-header">
                      <span className="badge badge-warning float-right">
                        Achievement
                      </span>
                      <h5 className="card-title mb-0"></h5>
                    </div>
                    <div className="card-body my-2">
                      <div className="row d-flex align-items-center mb-4">
                        <div className="col-8">
                          <h4 className="d-flex align-items-center mb-0 font-weight-light"></h4>
                        </div>
                        <div className="col-4 text-right">
                          <span className="text-muted">
                            {statistics.Achievement}%
                          </span>
                        </div>
                      </div>
                      <ProgressBar
                        animated
                        now={parseInt(progress)}
                        variant="success"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
              <div className="row">
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="barchart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-4">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
              <Barcolumn />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="donutchart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-3">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
            <Piechart />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row d-flex justify-content-center">
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="linechart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-4">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
              <Linecharts />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </>
    );
  } else if (user.role === "manager" || user.role === "pm") {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="container-fluid mb-4 p-2">
                  <Button variant="warning" onClick={handleShowdownload}>
                    <FiDatabase></FiDatabase> Download Data Ticket
                  </Button>{" "}
                  <Button variant="outline-warning" onClick={handleShowfile}>
                    <FiUpload></FiUpload> Import Data Ticket Xls
                  </Button>
                  <div className="float-right">
                    <h4 className="w-80 text-warning">
                      <b>
                        <FcLock></FcLock> Your IP Address is
                      </b>
                    </h4>
                    <h6 className="text-warning float-right">{ip}</h6>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      <table className="container">
                        <tr>
                          <td>Dt 20 april 2020</td>
                          <td>
                            {" "}
                            <Button variant="warning float-right" size="sm">
                              <FiFolder></FiFolder> unduh{" "}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showfile} onHide={handleClosefile}>
                    <Modal.Header closeButton>
                      <Modal.Title>Upload Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      <Form>
                        <Form.Group controlId="formFileLg">
                          <Form.Label>File Data Tiket</Form.Label>
                          <Form.Control type="file" size="sm" />
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClosefile}>
                        Close
                      </Button>
                      <Button variant="warning" onClick={handleClosefile}>
                        Import Ticket
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showdownload} onHide={handleClosedownload}>
                    <Form onSubmit={onHandleSubmit}>
                      <Modal.Header>
                        <Modal.Title>Download Ticket</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="start_date"
                            size="sm"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control type="date" name="end_date" size="sm" />
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleClosedownload}>
                          Close
                        </Button>
                        <Button variant="warning" type="submit">
                          Download Ticket
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                  <Modal show={showProggres} onHide={handleCloseProggres}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      {" "}
                      <Chrono
                        items={[
                          {
                            title: "May 1940",
                            cardTitle: "Dunkirk",
                            url: "http://google.com",
                            cardSubtitle:
                              "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
                            cardDetailedText: `On 10 May 1940, Hitler began his long-awaited offensive in the west by invading neutral Holland and Belgium and attacking northern France. Holland capitulated after only five days of fighting, and the Belgians surrendered on 28 May. With the success of the German ‘Blitzkrieg’, the British Expeditionary Force and French troops were in danger of being cut off and destroyed.`
                          },
                          {
                            title: "25 July 1940",
                            cardTitle: "The Battle of Britain",
                            cardSubtitle: `RAF Spitfire pilots scramble for their planes`,
                            cardDetailedText: `After Frances surrender in June 1940, Churchill told the British people, “Hitler knows that he will have to break us in this island or lose the war”. To mount a successful invasion, the Germans had to gain air superiority. The first phase of the battle began on 10 July with Luftwaffe attacks on shipping in the Channel.
The following month, RAF Fighter Command airfields and aircraft factories came under attack. Under the dynamic direction of Lord Beaverbrook, production of Spitfire and Hurricane fighters increased, and despite its losses in pilots and planes, the RAF was never as seriously weakened as the Germans supposed.`
                          },
                          {
                            title: "June 1941",
                            cardTitle: "Operation Barbarossa",
                            cardSubtitle: `A column of Red Army prisoners taken during the first days of the German invasion`,
                            cardDetailedText: `Since the 1920s, Hitler had seen Russia, with its immense natural resources, as the principal target for conquest and expansion. It would provide, he believed, the necessary ‘Lebensraum’, or living space, for the German people. And by conquering Russia, Hitler would also destroy the “Jewish pestilential creed of Bolshevism”. His non-aggression pact with Stalin in August 1939 he regarded as a mere temporary expedient.
Barely a month after the fall of France, and while the Battle of Britain was being fought, Hitler started planning for the Blitzkrieg campaign against Russia, which began on 22 June 1941. Despite repeated warnings, Stalin was taken by surprise, and for the first few months the Germans achieved spectacular victories, capturing huge swathes of land and hundreds of thousands of prisoners. But they failed to take Moscow or Leningrad before winter set in.
On 5/6 December, the Red Army launched a counter-offensive which removed the immediate threat to the Soviet capital. It also brought the German high command to the brink of a catastrophic military crisis. Hitler stepped in and took personal command. His intervention was decisive and he later boasted, “That we overcame this winter and are today in a position again to proceed victoriously… is solely attributable to the bravery of the soldiers at the front and my firm will to hold out…”`
                          }
                        ]}
                        mode="VERTICAL"
                        flipLayout
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseProggres}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-6 col-xl-6 d-flex">
                  <div className="card l-bg-telkom mh-100 flex-fill">
                    <div className="card-body py-4">
                      <div className="float-right text-danger"></div>
                      <h2 className="mb-2 text-light">Statistic</h2>
                      <div className="text-light">
                        {submit_percentage}% : ({submitted} / {totalTicket})
                      </div>
                      <div className="mb-1">
                        <strong> Submited Ticket </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 d-flex">
                  <div className="card mh-100 flex-fill">
                    <div className="card-body py-4">
                      <div className="float-right text-danger"></div>
                      <h2 className="mb-2 text-warning">Statistic</h2>
                      <div className="text-warning">
                        {Approved_percentage}% : ({approved} / {totalTicket})
                      </div>
                      <div className="mb-1 text-warning">
                        <strong>Approved Ticket</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                
              <div className="row">
                <div className="col-lg-12 col-xl-12 ">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <span className="badge badge-warning">Information</span>
                      <FcAdvertising size={40}></FcAdvertising>
                    </div>
                    <div className="card-body">
                                        <img
                                            className="d-block w-100 rounded"
                                            src="/img/logo/banner.gif"
                                            alt="Third slide"
                                        />
                                       
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className=" mt-1 mr-3">
                          <FcPieChart size={70}></FcPieChart>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Total Ticket
                          </div>
                          <h2 className="d-flex align-items-center  mt-3  font-weight-normal text-muted">
                            {statistics.total_tiket}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className="mt-2 mr-3">
                          <FcPositiveDynamic size={60}></FcPositiveDynamic>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Open Ticket
                          </div>
                          <h2 className="d-flex align-items-center mt-3 font-weight-normal text-muted">
                            {statisticsopen.total}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card rounded flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className="d-inline-block mt-2 mr-3">
                          <FcNegativeDynamic size={70}></FcNegativeDynamic>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Close
                          </div>
                          <h2 className="d-flex align-items-center mt-3 font-weight-normal text-muted">
                            {statisticsclose.total}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card rounded flex-fill">
                    <div className="card-header">
                      <span className="badge badge-warning float-right">
                        Achievement
                      </span>
                      <h5 className="card-title mb-0"></h5>
                    </div>
                    <div className="card-body my-2">
                      <div className="row d-flex align-items-center mb-4">
                        <div className="col-8">
                          <h4 className="d-flex align-items-center mb-0 font-weight-light"></h4>
                        </div>
                        <div className="col-4 text-right">
                          <span className="text-muted">
                            {statistics.Achievement}%
                          </span>
                        </div>
                      </div>
                      <ProgressBar
                        animated
                        now={parseInt(progress)}
                        variant="success"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
              <div className="row">
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="barchart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-4">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
              <Barcolumn />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="donutchart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-3">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
            <Piechart />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row d-flex justify-content-center">
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="linechart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-4">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
              <Linecharts />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
            </div>
          </main>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container-fluid p-0 ">
              <div className="row">
                <div className="container-fluid mb-2">
                  <Button variant="warning" onClick={handleShowdownload}>
                    <FiDatabase></FiDatabase> Download Format Excel
                  </Button>{" "}
                  <Button variant="outline-warning" onClick={handleShowfile}>
                    <FiUpload></FiUpload> Import Data Ticket Xls
                  </Button>
                  <div className="float-right">
                    <h4 className="w-80 text-warning">
                      <b>
                        <FcLock></FcLock> Your IP Address is
                      </b>
                    </h4>
                    <h6 className="text-warning float-right">{ip}</h6>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      <table className="container">
                        <tr>
                          <td>Dt 20 april 2020</td>
                          <td>
                            {" "}
                            <Button variant="warning float-right" size="sm">
                              <FiFolder></FiFolder> unduh{" "}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showfile} onHide={handleClosefile}>
                    <Modal.Header>
                      <Modal.Title>Upload Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      <Form>
                        <Form.Group controlId="formFileLg">
                          <Form.Label>File Data Tiket</Form.Label>
                          <Form.Control type="file" size="sm" />
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClosefile}>
                        Close
                      </Button>
                      <Button variant="warning" onClick={handleClosefile}>
                        Import Ticket
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showdownload} onHide={handleClosedownload}>
                    <Form onSubmit={onHandleSubmit}>
                      <Modal.Header>
                        <Modal.Title>Download Ticket</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="start_date"
                            size="sm"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control type="date" name="end_date" size="sm" />
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleClosedownload}>
                          Close
                        </Button>
                        <Button variant="warning" type="submit">
                          Download Ticket
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                  <Modal show={showProggres} onHide={handleCloseProggres}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      {" "}
                      <Chrono
                        items={[
                          {
                            title: "May 1940",
                            cardTitle: "Dunkirk",
                            url: "http://google.com",
                            cardSubtitle:
                              "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
                            cardDetailedText: `On 10 May 1940, Hitler began his long-awaited offensive in the west by invading neutral Holland and Belgium and attacking northern France. Holland capitulated after only five days of fighting, and the Belgians surrendered on 28 May. With the success of the German ‘Blitzkrieg’, the British Expeditionary Force and French troops were in danger of being cut off and destroyed.`
                          },
                          {
                            title: "25 July 1940",
                            cardTitle: "The Battle of Britain",
                            cardSubtitle: `RAF Spitfire pilots scramble for their planes`,
                            cardDetailedText: `After France’s surrender in June 1940, Churchill told the British people, “Hitler knows that he will have to break us in this island or lose the war”. To mount a successful invasion, the Germans had to gain air superiority. The first phase of the battle began on 10 July with Luftwaffe attacks on shipping in the Channel.
The following month, RAF Fighter Command airfields and aircraft factories came under attack. Under the dynamic direction of Lord Beaverbrook, production of Spitfire and Hurricane fighters increased, and despite its losses in pilots and planes, the RAF was never as seriously weakened as the Germans supposed.`
                          },
                          {
                            title: "June 1941",
                            cardTitle: "Operation Barbarossa",
                            cardSubtitle: `A column of Red Army prisoners taken during the first days of the German invasion`,
                            cardDetailedText: `Since the 1920s, Hitler had seen Russia, with its immense natural resources, as the principal target for conquest and expansion. It would provide, he believed, the necessary ‘Lebensraum’, or living space, for the German people. And by conquering Russia, Hitler would also destroy the “Jewish pestilential creed of Bolshevism”. His non-aggression pact with Stalin in August 1939 he regarded as a mere temporary expedient.
Barely a month after the fall of France, and while the Battle of Britain was being fought, Hitler started planning for the Blitzkrieg campaign against Russia, which began on 22 June 1941. Despite repeated warnings, Stalin was taken by surprise, and for the first few months the Germans achieved spectacular victories, capturing huge swathes of land and hundreds of thousands of prisoners. But they failed to take Moscow or Leningrad before winter set in.
On 5/6 December, the Red Army launched a counter-offensive which removed the immediate threat to the Soviet capital. It also brought the German high command to the brink of a catastrophic military crisis. Hitler stepped in and took personal command. His intervention was decisive and he later boasted, “That we overcame this winter and are today in a position again to proceed victoriously… is solely attributable to the bravery of the soldiers at the front and my firm will to hold out…”`
                          }
                        ]}
                        mode="VERTICAL"
                        flipLayout
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseProggres}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-xl-12 ">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <span className="badge badge-warning">Information</span>
                      <FcAdvertising size={40}></FcAdvertising>
                    </div>
                    <div className="card-body">
                    
                                        <img
                                            className="d-block w-100 rounded"
                                            src="/img/logo/banner.gif"
                                            alt="Third slide"
                                        />
                                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className=" mt-1 mr-3">
                          <FcPieChart size={70}></FcPieChart>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Total Ticket
                          </div>
                          <h2 className="d-flex align-items-center  mt-3  font-weight-normal text-muted">
                            {statistics.total_tiket}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className="mt-2 mr-3">
                          <FcPositiveDynamic size={60}></FcPositiveDynamic>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Open Ticket
                          </div>
                          <h2 className="d-flex align-items-center mt-3 font-weight-normal text-muted">
                          {statisticsopen.total}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card rounded flex-fill">
                    <div className="card-body py-4 ">
                      <div className="media mt-4">
                        <div className="d-inline-block mt-2 mr-3">
                          <FcNegativeDynamic size={70}></FcNegativeDynamic>
                        </div>
                        <div className="media-body">
                          <div className="mb-0 font-weight-bold text-warning">
                            Close
                          </div>
                          <h2 className="d-flex align-items-center mt-3 font-weight-normal text-muted">
                          {statisticsclose.total}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 d-flex">
                  <div className="card rounded flex-fill">
                    <div className="card-header">
                      <span className="badge badge-warning float-right">
                        Achievement
                      </span>
                      <h5 className="card-title mb-0"></h5>
                    </div>
                    <div className="card-body my-2">
                      <div className="row d-flex align-items-center mb-4">
                        <div className="col-8">
                          <h4 className="d-flex align-items-center mb-0 font-weight-light"></h4>
                        </div>
                        <div className="col-4 text-right">
                          <span className="text-muted">
                            {statistics.Achievement}%
                          </span>
                        </div>
                      </div>
                      <ProgressBar
                        animated
                        now={parseInt(progress)}
                        variant="success"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
  <div className="row">
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="barchart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-4">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
              <Barcolumn />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="donutchart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-3">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
            <Piechart />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row d-flex justify-content-center">
    <div className="col-md-6">
      <div className="d-flex justify-content-center">
        <div id="linechart"></div>
      </div>
      <div className="card flex-fill">
        <div className="card-body py-4">
          <div className="table-responsive table-sm">
            <div className="d-flex justify-content-center">
              <Linecharts />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
            </div>
          </main>
        </div>
      </>
    );
  }

}
