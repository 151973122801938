import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from 'react';
import Dashboard from "./pages/Dashboard/Dashboard";
import Group from "./pages/group/Group";
import Layout from "./pages/Layout";
import Login from "./pages/Auth/Login";
import Password from "./pages/Auth/password";
import ChangePassword from "./pages/profile/change_password";
import Region from "./pages/region/Region";
import Ticket from "./pages/ticket/Ticket";
import DetailTicket from "./pages/ticket/DetailTicket";
import TicketDraft from "./pages/ticket/draft/TicketDraft";
import TicketWaiting from "./pages/ticket/waiting/TicketWaiting";
import Close_Aproved from "./pages/ticket/aproved/Close_Aproved";
import Report_Aproved from "./pages/ticket/aproved/Report_Aproved";
import Role from "./pages/role/Role";
import Area from "./pages/area/Area";
import Level from "./pages/MasterData/Level";
import User from "./pages/User/User";
import Event from "./pages/event/Event";
import Categori from "./pages/categori/Categori";
import SubCategori from "./pages/subcategori/SubCategori";
import Kota from "./pages/kota/Kota";
import Profile from "./pages/profile/Profile";
import PendingUser from "./pages/ticket/pending/PendingUser";
import Reject_user from "./pages/ticket/reject/Reject_user";
import UserAssigment from "./pages/User/UserAssigment";
import Page404 from "./pages/page404/404";
import PageWaOtp from "./pages/whatsappBot/pageWa";
import Wa_number from "./pages/whatsappBot/Wa_number";
import Pending_Pm from "./pages/ticket/pending/Pending_Pm";
import Activity from "./pages/ticket/activity/activity_ticket";
import LineChart from "./components/Linechart";


function App() {


  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return <Login />;
  }

  if (!token) {
    return <Password />;
  }
  if (user.role === "root") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="role" element={<Role />} />
            <Route path="user" element={<User />} />
            <Route path="userAssigment" element={<UserAssigment />} />
            <Route path="region" element={<Region />} />
            <Route path="group" element={<Group />} />
            <Route path="ticket" element={<Ticket name="all" />} />
            <Route path="ticket-draft" element={<TicketDraft name="Draft" />} />
            <Route path="ticket-aprove" element={<TicketWaiting name="Pending" />} />
            <Route path="closeaprove" element={<Close_Aproved />} />
            <Route path="level" element={<Level />} />
            <Route path="area" element={<Area />} />
            <Route path="event" element={<Event />} />
            <Route path="categori" element={<Categori />} />
            <Route path="subcategori" element={<SubCategori />} />
            <Route path="linechart" element={<LineChart />} />
            <Route path="kota" element={<Kota />} />
            <Route path="profile" element={<Profile />} />
            <Route path="detailticket" element={<DetailTicket />} />
            <Route path="waotp" element={<PageWaOtp />} />
            <Route path="wa" element={< Wa_number />} />
            <Route path="rejectuser" element={<Reject_user name="Reject" />} />
            <Route path="password" element={< Password />} />
            <Route path="activity" element={< Activity name="DT0645594648" />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else if (user.role === "pm") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="ticket" element={<Ticket name="all" />} />
            <Route path="detailticket" element={<DetailTicket />} />
            <Route path="profile" element={<Profile />} />
            <Route path="closeaprove" element={<Close_Aproved />} />
            <Route path="pendingpm" element={<Pending_Pm />} />
            <Route path="report-pending" element={<Report_Aproved report="pendingdone" />} />
            <Route path="report" element={<Report_Aproved report="done" />} />
            <Route path="report-submit" element={<Report_Aproved report="waiting" />} />
            <Route path="report-riject" element={<Report_Aproved report="riject" />} />
            <Route path="password" element={< Password />} />
            <Route path="rejectuser" element={<Reject_user name="Reject" />} />
            <Route path="*" element={<Page404 />} />
            <Route path="wa" element={< Wa_number />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );

  } else if (user.role === "manager") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="ticket" element={<Ticket name="all" />} />
            <Route path="ticket-draft" element={<TicketDraft name="Draft" name2="reject" />} />
            {/* <Route path="ticket-draft" element={<TicketDraft name="reject" />} /> */}
            <Route path="ticket-aprove" element={<TicketWaiting name="Pending" />} />
            <Route path="detailticket" element={<DetailTicket />} />
            <Route path="closeaprove" element={<Close_Aproved />} />
            <Route path="report" element={<Report_Aproved report="done" />} />
            <Route path="report-submit" element={<Report_Aproved report="waiting" />} />
            <Route path="report-pending" element={<Report_Aproved report="pendingdone" />} />
            <Route path="profile" element={<Profile />} />
            <Route path="user" element={<User />} />
            <Route path="rejectuser" element={<Reject_user name="Reject" />} />
            <Route path="wa" element={< Wa_number />} />
            <Route path="password" element={< Password />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );

  } else if (user.role === "leader") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="ticket" element={<Ticket name="all" />} />
            <Route path="ticket-draft" element={<TicketDraft name="Draft" />} />
            <Route path="ticket-aprove" element={<TicketWaiting name="Pending" />} />
            <Route path="closeaprove" element={<Close_Aproved />} />
            <Route path="detailticket" element={<DetailTicket />} />
            <Route path="report" element={<Report_Aproved report="done" />} />
            <Route path="report-riject" element={<Report_Aproved report="riject" />} />
            <Route path="report-submit" element={<Report_Aproved report="waiting" />} />
            <Route path="report-pending" element={<Report_Aproved report="pendingdone" />} />
            <Route path="profile" element={<Profile />} />
            <Route path="user" element={<User />} />
            <Route path="rejectuser" element={<Reject_user name="Reject" />} />
            <Route path="*" element={<Page404 />} />
            <Route path="wa" element={< Wa_number />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );

  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="ticket" element={<Ticket name="all" />} />
            <Route path="ticket-draft" element={<TicketDraft name="Draft" name2="reject" />} />
            <Route path="closeaprove" element={<Close_Aproved />} />
            <Route path="ticket-aprove" element={<TicketWaiting name="Pending" />} />
            <Route path="profile" element={<Profile />} />
            <Route path="report-pending" element={<Report_Aproved report="pendingdone" />} />
            <Route path="report" element={<Report_Aproved report="done" />} />
            <Route path="report-submit" element={<Report_Aproved report="waiting" />} />
            <Route path="pendinguser" element={<PendingUser />} />
            <Route path="rejectuser" element={<Reject_user name="Reject" />} />
            <Route path="password" element={< Password />} />
            <Route path="changepassword" element={< ChangePassword />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

}
export default App;
