import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import * as Icon from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import axios from "axios";
import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row
} from "react-bootstrap";
export default function User() {
  const [validated, setValidated] = useState(false);
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [judul, setJudul] = useState();
  const [user, setUser] = useState([]);
  const [showSpinner, setshowSpinner] = useState(false);
  const handleClose = () => setShow(false);
  const handleClosevalid = () => setValidated(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const tulisJudul = (jdl) => setJudul(jdl);
  const handleDelete = async (id) => {
    if (confirm(id) == true) {
      try {
        await axios.delete(`${Url}/users/${id}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });
        getUsers();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onSubmit = async (event) => {
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries());
    console.log(values.start_date);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setshowSpinner(true);
    }
    await axios({
      method: "post",
      url: `${Url}/users/create`, headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: {
        username: values.username,
        email: values.email,
        nama: values.nama,
        nomor_handphone: values.nomor_handphone,
        alamat: values.alamat,
      }
    })
      .then(function () {
        setTimeout(() => {
          getUsers();
          handleClose();
          setValidated(false);
          setshowSpinner(false);
        }, 1000);
      })
      .catch(function (error) {
        setshowSpinner(false);
        console.log(error);
      });
    setValidated(true);
    setTimeout(() => {
      setValidated(false);
    }, 5000);
  };
  const closeModal = () => {
    handleClose();
    handleClosevalid();
  };
  const onUpdate = async (values) => {
    await axios({
      method: "post",
      url: `${Url}/users/` + values.id, headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: values
    })
      .then(function (response) {
        console.log(response);
        getUsers();
        handleClose1();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getUsers();
    getUser();
    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 1000);
    });
  }, []);
  const getUsers = async () => {
    const response = await axios.get(`${Url}/users`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    setUsers(response.data.data);
  };
  const getUser = async (id) => {
    const response = await axios.get(`${Url}/usersbyId/${id}`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.log(`ini=>` + response.data.data.id);
    setUser(response.data.data);
  };
  return (
    <>
      <Navbar />
      <div className="main">
        <Sidebar />
        <main className="content">
          <div className="container">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title text-bold">
                    Master data user
                    <ButtonBS
                      variant="warning float-right"
                      onClick={() => {
                        handleShow();
                        tulisJudul("Tambah User");
                      }}>
                      <Icon.PlusCircleFill className="mx-1" size={17} />
                      Tambah data user
                    </ButtonBS>
                  </div>
                </div>
                <div className="card-body table-responsive table-sm">
                  <table className="table table-striped" id="table_user">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((orang) => (
                        <tr key={orang.id}>
                          <td>{orang.nama}</td>
                          <td>{orang.email}</td>
                          <td>{orang.nomor_handphone}</td>
                          <td>{orang.alamat}</td>
                          <td>
                            <ButtonBS
                              className="mx-1"
                              variant="warning"
                              onClick={() => {
                                handleShow1();
                                tulisJudul("Edit User");
                                getUser(orang.id);
                              }}>
                              <Icon.PencilFill className="mx-1" size={16} />
                              Edit
                            </ButtonBS>

                            <ButtonBS
                              className="mx-1"
                              variant="danger"
                              onClick={() => handleDelete(orang.id)}>
                              <Icon.XOctagonFill className="mx-1" size={16} />
                              Hapus
                            </ButtonBS>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{judul}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(showSpinner) ? <div className="justify-content-center text-center"><img src="/img/logo/LOADING.png" className="img-fluid" alt="oke" /></div> : <FormBS noValidate validated={validated}
              onSubmit={onSubmit}>
              <Modal.Body>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <FormBS.Label>Username</FormBS.Label>
                  <FormBS.Control
                    required
                    type="text"
                    placeholder="Username"
                    name="username"
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Input username.
                  </FormBS.Control.Feedback>
                </FormBS.Group>

                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <FormBS.Label>Email</FormBS.Label>
                  <FormBS.Control
                    required
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Email Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput3">
                  <FormBS.Label>Nama</FormBS.Label>
                  <FormBS.Control
                    required
                    type="text"
                    placeholder="Full Name"
                    name="nama"
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Nama Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput4">
                  <FormBS.Label>Nomor Handphone</FormBS.Label>
                  <FormBS.Control
                    required
                    type="text"
                    placeholder="Nomor Handphone"
                    name="nomor_handphone"
                    maxLength={12}
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Nomor Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>

                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <FormBS.Label>Alamat Lengkap</FormBS.Label>
                  <FormBS.Control as="textarea" name="alamat" rows={3} />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Alamat Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>

              </Modal.Body>
              <Modal.Footer>
                <ButtonBS variant="secondary" onClick={closeModal}>
                  Close
                </ButtonBS>
                <ButtonBS variant="warning" type="submit">
                  <Icon.Check2Square className="mx-1" size={15} />
                  Simpan User
                </ButtonBS>
              </Modal.Footer>
            </FormBS>}
          </Modal.Body>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>{judul}</Modal.Title>
          </Modal.Header>
          <Form
            onSubmit={onUpdate}
            initialValues={{
              id: user.id,
              username: user.username,
              email: user.email,
              nama: user.nama,
              nomor_handphone: user.nomor_handphone,
              alamat: user.alamat,

            }}
            render={({ handleSubmit }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="col-12">
                    <form>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">username</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="username"
                            component="input"
                            className="form-control"
                            type="text"
                            placeholder="username"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Email</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="email"
                            component="input"
                            className="form-control"
                            type="text"
                            placeholder="Email"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Nama</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="nama"
                            component="input"
                            className="form-control"
                            type="text"
                            placeholder="nama"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Nomor Telp</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="nomor_handphone"
                            type="text"
                            className="form-control"
                            component="input"
                            placeholder="No handphone"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Alamat</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="alamat"
                            component="textarea"
                            placeholder="Alamat"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose1}>
                    Close
                  </ButtonBS>
                  <ButtonBS variant="primary" type="submit">
                    <Icon.Check2Square className="mx-1" size={15} />
                    Simpan User
                  </ButtonBS>
                </Modal.Footer>
              </FormBS>
            )}
          />
        </Modal>
      </div>
    </>
  );
}
