import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
//import { useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import $ from "jquery";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import axios from "axios";
import { Form, Field } from "react-final-form";
import {
    Button as ButtonBS,
    Col,
    Form as FormBS,
    Modal,
    Row
} from "react-bootstrap";

//import { useParams } from "react-router-dom";

// import Button from "../../components/Button";

// import Modaltes from "../../components/Modaltes";

export default function Kota() {
    const [kotas, setKotas] = useState([]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [kota, setKota] = useState({});
    // const [region_code, setRegion_code] = useState([]);
    // const [id_area, setId_area] = useState([]);
    // const { id } = useParams();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);

    useEffect(() => {
        getKotas();
        getKota();
        $(document).ready(function () {
            setTimeout(function () {
                $("#table_user").DataTable();
            }, 1000);
        });
    }, []);


    const getKotas = async () => {
        const response = await axios.get(`${Url}/kota`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        });
        console.log('byid=>' + response.data.data);
        setKotas(response.data.data);
    };

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    const onSubmit = async (values) => {
        await sleep(300);
        axios({
            method: 'post',
            url: Url + '/kota', headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            },
            data: values
        })
            .then(function () {
                getKotas();
                handleClose();
            });

    };
    const onUpdate = async (values) => {
        await axios({
            method: 'put',
            url: Url + '/kota/' + values.id, headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            },
            data: values
        })
            .then(function () {
                getKotas();
                handleClose1();
            });

    };



    const handleDelete = async (id) => {
        if (confirm(id) == true) {
            try {
                await axios.delete(`${Url}/kota/${id}`, {
                    headers: {
                        Authorization: `${localStorage.getItem("accessToken")}`
                    }
                });
                getKotas();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getKota = async (id) => {
        const response = await axios.get(`${Url}/kotabyId/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        });
        // console.log(`ini=>` + response.data.data.Groups[0].Role.name);
        console.table('byid=>' + response.data.data.name);
        setKota(response.data.data);
        // console.log(response.da`ta.data.area);
        //setArea(response.data.Area.id_area);
    };
    return (
        <>
            <Navbar />
            <div className="main">
                <Sidebar />
                <main className="content">
                    <div className="container">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title"> Data kota</div>
                                    <ButtonBS
                                        variant="warning float-right"
                                        onClick={handleShow}>
                                        <Icon.PlusCircleFill className="mx-1" size={17} />
                                        Tambah data Kota
                                    </ButtonBS>
                                </div>
                                <div className="table-responsive table-sm ">
                                    <table className="table table-striped" id="table_user">
                                        <thead>
                                            <tr>
                                                <th>name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {kotas.map((orang) => (
                                                <tr key={orang.id}>
                                                    <td>{orang.name}</td>


                                                    <td>
                                                        <ButtonBS
                                                            size="sm"
                                                            className="mx-1"
                                                            variant="warning"
                                                            onClick={() => {
                                                                handleShow1();
                                                                getKota(orang.id);
                                                            }}>
                                                            <Icon.PencilFill className="mx-1" size={16} />
                                                            Edit
                                                        </ButtonBS>

                                                        <ButtonBS
                                                            size="sm"
                                                            className="mx-1"
                                                            variant="danger"
                                                            onClick={() => handleDelete(orang.id)}>
                                                            <Icon.XOctagonFill className="mx-1" size={16} />
                                                            Hapus
                                                        </ButtonBS>
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Data Kota</Modal.Title>
                    </Modal.Header>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, values }) => (
                            <FormBS onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <form>
                                        <Row className="form-group">
                                            <Col xs={4} md={3}>
                                                <label className="form-label">Name Kota</label>
                                            </Col>
                                            <Col xs={8} md={9}>
                                                <Field
                                                    name="name"
                                                    component="input"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="name"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-group">
                                            <Col xs={4} md={3}>
                                                <label className="form-label">province id</label>
                                            </Col>
                                            <Col xs={8} md={9}>
                                                <Field
                                                    name="province_id"
                                                    component="input"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="province_id"
                                                />
                                            </Col>
                                        </Row>
                                    </form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonBS variant="secondary" onClick={handleClose}>
                                        Close
                                    </ButtonBS>
                                    <ButtonBS variant="primary" type="submit">
                                        <Icon.Check2Square className="mx-1" size={15} />
                                        Simpan Pengaturan
                                    </ButtonBS>
                                </Modal.Footer>
                                <pre>{JSON.stringify(values, 0, 2)}</pre>
                            </FormBS>
                        )}
                    />
                </Modal>
                <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Data Region</Modal.Title>
                    </Modal.Header>
                    {/* {console.log("araaa" + area)} */}
                    {console.log("name" + kota)}
                    <Form
                        onSubmit={onUpdate}
                        initialValues={{
                            id: kota.id,
                            name: kota.name,


                        }}
                        render={({ handleSubmit, }) => (
                            <FormBS onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <div className="col-12">
                                        <form>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Name Kota</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        name="name"
                                                        component="input"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="name"
                                                    />
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonBS variant="secondary" onClick={handleClose1}>
                                        Close
                                    </ButtonBS>
                                    <ButtonBS variant="primary" type="submit">
                                        <Icon.Check2Square className="mx-1" size={15} />
                                        Simpan Edit
                                    </ButtonBS>
                                </Modal.Footer>

                            </FormBS>
                        )}
                    />
                </Modal>
            </div>
        </>
    );

}

