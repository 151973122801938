import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import * as Icon from "react-bootstrap-icons";
//import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import $ from "jquery";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import axios from "axios";
import { Form, Field } from "react-final-form";
import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row
} from "react-bootstrap";



//import { useParams } from "react-router-dom";

// import Button from "../../components/Button";

// import Modaltes from "../../components/Modaltes";

export default function Level() {
  const [validated, setValidated] = useState(false);

  const [levels, setLevels] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [level, setLevel] = useState([]);
  const [showSpinner, setshowSpinner] = useState(false);
  //  const navigate = useNavigate();

  const handleClosevalid = () => setValidated(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  // const { id } = useParams();
  //const [name, setName] = useState("");
  useEffect(() => {
    getLevels();
    getLevel();
    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 1000);
    });
  }, []);

  const getLevels = async () => {
    const response = await axios.get(`${Url}/level`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }

    });
    console.log(`ini=>` + response.data.role.id);
    setLevels(response.data.role);
  };

  const onSubmit = async (event) => {
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries());
    console.log(values.start_date);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setshowSpinner(true);
    }
    await axios({
      method: 'post',
      url: Url + '/level', headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: {
        name: values.name
      }
    })
      .then(function () {
        setTimeout(() => {
          getLevels();
          handleClose();
          setValidated(false);
          setshowSpinner(false);
        }, 1000);
      })
      .catch(function (error) {
        setshowSpinner(false);
        console.log(error);
      });
    setValidated(true);
    setTimeout(() => {
      setValidated(false);
    }, 5000);

  };
  const closeModal = () => {
    handleClose();
    handleClosevalid();
  };
  // const handleChange = event => {
  //    const { values, name } = event.target;
  //    setGroups({ ...groups, [name]: values });
  // };

  const onUpdate = async (values) => {
    await axios({
      method: 'put',
      url: Url + '/level/' + values.id, headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: values
    })
      .then(function () {
        getLevels();
        handleClose1();
      });

  };
  // const onUpdate = event => {
  //  event.preventDefault();
  //  axios.put(`http://localhost:4100/group/${id}`, groups)
  //     .then(function () {
  //        getGroups();
  //    }).catch(error => {
  //        console.log(error);
  //  });
  //  };

  const handleDelete = async (id) => {
    if (confirm(id) == true) {
      try {
        await axios.delete(`${Url}/level/${id}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });
        getLevels();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getLevel = async (id) => {
    const response = await axios.get(`${Url}/levelbyId/${id}`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    // console.log(`ini=>` + response.data.data.Groups[0].Role.name);
    console.log('byid=>' + response.data.data.name);
    setLevel(response.data.data);
  };
  // function refreshPage() {
  //     setTimeout(() => {
  //         window.location.reload(false);
  //     }, 500);
  //     console.log('page to reload');
  // }

  // const onUpdate = async (e) => {
  //   e.preventDefault();
  // try {
  //   await axios.patch(`http://localhost:4100/group/${id}`, {
  //      name,

  //   });
  //   navigate("/group");
  //    } catch (error) {
  //    console.log(error);
  //   }
  //  };


  return (
    <>
      <Navbar />
      <div className="main">
        <Sidebar />
        <main className="content">
          <div className="container">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Master data Level</div>
                  <ButtonBS
                    variant="outline-warning float-right"
                    onClick={handleShow}>
                    <Icon.PlusCircleFill className="mx-1" size={17} />
                    Tambah data Level
                  </ButtonBS>
                </div>
                <div className="card-body">
                  <table className="table table-striped" id="table_user">
                    <thead>
                      <tr>
                        <th>Name Level</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {levels.map((orang) => (
                        <tr key={orang.id}>
                          <td>{orang.name}</td>
                          <td>
                            <ButtonBS
                              size="sm"
                              className="mx-1"
                              variant="warning"
                              onClick={() => {
                                handleShow1();
                                getLevel(orang.id);
                              }}>
                              <Icon.PencilFill className="mx-1" size={16} />
                              Edit
                            </ButtonBS>

                            <ButtonBS
                              size="sm"
                              className="mx-1"
                              variant="danger"
                              onClick={() => handleDelete(orang.id)}>

                              <Icon.XOctagonFill className="mx-1" size={16} />
                              Hapus
                            </ButtonBS>
                          </td>
                        </tr>

                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>

          </Modal.Header>
          <Modal.Body>
            {(showSpinner) ? <div className="justify-content-center text-center"><img src="/img/logo/LOADING.png" className="img-fluid" alt="oke" /></div> : <FormBS noValidate validated={validated}
              onSubmit={onSubmit}>
              <Modal.Body>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <FormBS.Label>Name</FormBS.Label>
                  <FormBS.Control
                    required
                    type="text"
                    placeholder="Name"
                    name="name"
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Input name.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
              </Modal.Body>
              <Modal.Footer>
                <ButtonBS variant="secondary" onClick={closeModal}>
                  Close
                </ButtonBS>
                <ButtonBS variant="warning" type="submit">
                  <Icon.Check2Square className="mx-1" size={15} />
                  Simpan User
                </ButtonBS>
              </Modal.Footer>
            </FormBS>}
          </Modal.Body>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Data Level</Modal.Title>
          </Modal.Header>
          {console.log("name" + level)}
          <Form
            onSubmit={onUpdate}
            initialValues={{
              name: level.name,
              id: level.id


            }}



            //  onChange={handleChange}
            //   onChange={(e) => setName(e.target.values)}


            render={({ handleSubmit }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="col-12">
                    <form>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">name</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="name"
                            component="input"
                            className="form-control"
                            type="text"
                            placeholder="name"
                          />
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose1}>
                    Close
                  </ButtonBS>
                  <ButtonBS variant="primary" type="submit" onClick={handleClose}>
                    <Icon.Check2Square className="mx-1" size={15} />
                    Simpan Edit
                  </ButtonBS>
                </Modal.Footer>

              </FormBS>
            )}
          />
        </Modal>
      </div>
    </>
  );


}