import { initializeApp } from 'firebase/app';
import {getMessaging,  getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyA7HqMuqAP8U00sn6EEv9D-b8rGTUDegec",
    authDomain: "pico-repository.firebaseapp.com",
    projectId: "pico-repository",
    storageBucket: "pico-repository.appspot.com",
    messagingSenderId: "265282268064",
    appId: "1:265282268064:web:18e0d9acfc7b5ff31752c5",
    measurementId: "G-6Z9MZTESM5"
  };

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});
export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BGLlXWilbKS48paFnydINrF7azrpZQz4ATmwGGKNO2RmaS_AzF9IQAl0QBKYtX7MbGhKjnzDLqS574iBQgWYnwg'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
};

export const Token = (setFcm) => {

  getToken(messaging, {vapidKey: 'BGLlXWilbKS48paFnydINrF7azrpZQz4ATmwGGKNO2RmaS_AzF9IQAl0QBKYtX7MbGhKjnzDLqS574iBQgWYnwg'}).then((currentToken) => {
    if (currentToken) {
      setFcm(currentToken);
    } else {
      setFcm(currentToken);
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});