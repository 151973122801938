import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
//import { useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import $ from "jquery";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import axios from "axios";
import { Form, Field } from "react-final-form";
import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row
} from "react-bootstrap";

//import { useParams } from "react-router-dom";

// import Button from "../../components/Button";

// import Modaltes from "../../components/Modaltes";

export default function Region() {
  const [validated, setValidated] = useState(false);
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  // const [area, setArea] = useState([]);
  const [showSpinner, setshowSpinner] = useState(false);
  const handleClosevalid = () => setValidated(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [region, setRegion] = useState({});
  // const [region_code, setRegion_code] = useState([]);
  // const [id_area, setId_area] = useState([]);
  // const { id } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  useEffect(() => {
    getRegions();
    getRegion();
    getAreas();
    // getRegion();
    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 1000);
    });
  }, []);


  const getRegions = async () => {
    const response = await axios.get(`${Url}/region`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    // console.log('byid=>' + response.data.data);
    setRegions(response.data.data);
  };

 

  const onSubmit = async (event) => {
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries());
    console.log(values.start_date);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setshowSpinner(true);
    }
    await axios({
      method: 'post',
      url: Url + "/region", 
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: {
        region: values.region,
        region_code: values.region_code,
        id_area: values.id_area,
      }
    })
      .then(function () {
        setTimeout(() => {
        getRegions();
        handleClose();
        setValidated(false);
        setshowSpinner(false);
      }, 1000);
    })
    .catch(function (error) {
      setshowSpinner(false);
      console.log(error);
    });
  setValidated(true);
  setTimeout(() => {
    setValidated(false);
  }, 5000);
};

const closeModal = () => {
  handleClose();
  handleClosevalid();
};
const onUpdate = async (values) => {
  await axios({
    method: 'put',
    url: Url + "/region/" + values.id_region, headers: {
      Authorization: `${localStorage.getItem("accessToken")}`
    },
    data: values
  })
    .then(function () {
      getRegions();
      handleClose1();
    });

};




  const handleDelete = async (id) => {
    if (confirm(id) == true) {
      try {
        await axios.delete(`${Url}/region/${id}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });
        getRegions();
      } catch (error) {
        console.log(error);
      }
    }
  };


  const getAreas = async () => {
    const response = await axios.get(`${Url}/area`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }

    });
    console.table(`ini=>` + response.data.role);
    setAreas(response.data.role);
  };
  const getRegion = async (id) => {
    const response = await axios.get(`${Url}/regionbyId/${id}`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    // console.log(`ini=>` + response.data.data.Groups[0].Role.name);
    console.table('byid=>' + response.data.data.region);
    setRegion(response.data.data);
    // console.log(response.da`ta.data.area);
    //setArea(response.data.Area.id_area);
  };
  return (
    <>
      <Navbar />
      <div className="main">
        <Sidebar />
        <main className="content">
          <div className="container">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Master Data Regioon</div>
                  <ButtonBS
                    variant="primary float-right"
                    onClick={handleShow}>
                    <Icon.PlusCircleFill className="mx-1" size={17} />
                    Tambah data Region
                  </ButtonBS>
                </div>
                <div className="card-body">
                  <table className="table table-striped" id="table_user">
                    <thead>
                      <tr>
                        <th>Region</th>
                        <th>Code Region</th>
                        <th>Area</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {regions.map((orang) => (
                        <tr key={orang?.id_region}>
                          <td>{orang?.region}</td>
                          <td>{orang?.region_code}</td>
                          <td>{orang?.Area?.area}</td>
                          {/* <td>{orang.id_area}</td> */}

                          <td>
                            <ButtonBS
                              className="mx-1"
                              variant="warning"
                              onClick={() => {
                                handleShow1();
                                getRegion(orang.id_region);
                              }}>
                              <Icon.PencilFill className="mx-1" size={16} />
                              Edit
                            </ButtonBS>

                            <ButtonBS
                              className="mx-1"
                              variant="danger"
                              onClick={() => handleDelete(orang.id_region)}>
                              <Icon.XOctagonFill className="mx-1" size={16} />
                              Hapus
                            </ButtonBS>
                          </td>
                        </tr>

                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal show={show} onHide={handleClose}>
<Modal.Header>

</Modal.Header>
<Modal.Body>
  {(showSpinner) ? <div className="justify-content-center text-center"><img src="/img/logo/LOADING.png" className="img-fluid" alt="oke" /></div> :
    <FormBS noValidate validated={validated}
      onSubmit={onSubmit}>
      <Modal.Body>
        <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <FormBS.Label>Region</FormBS.Label>
          <FormBS.Control
            required
            type="text"
            placeholder="Region"
            name="region"
          />
          <FormBS.Control.Feedback type="invalid">
            Please Input region.
          </FormBS.Control.Feedback>
        </FormBS.Group>
        <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <FormBS.Label>Code Region</FormBS.Label>
          <FormBS.Control
            required
            type="text"
            placeholder="Code Region"
            name="region_code"
          />
          <FormBS.Control.Feedback type="invalid">
            Please Input code_region.
          </FormBS.Control.Feedback>
        </FormBS.Group>
        <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <FormBS.Label>Role</FormBS.Label>
          <FormBS.Select size="lg" name="id_area"   className="form-control">
          {areas.map((aturan) => (
                                  <option value={aturan.id_area} key={aturan.id_area}>
                                    {aturan.area}
                                  </option>
                                ))}
      </FormBS.Select>
          <FormBS.Control.Feedback type="invalid">
            Please Input Role.
          </FormBS.Control.Feedback>
        </FormBS.Group>
      </Modal.Body>
      <Modal.Footer>
        <ButtonBS variant="secondary" onClick={closeModal}>
          Close
        </ButtonBS>
        <ButtonBS variant="warning" type="submit">
          <Icon.Check2Square className="mx-1" size={15} />
          Simpan User
        </ButtonBS>
      </Modal.Footer>
    </FormBS>}
</Modal.Body>
</Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Region</Modal.Title>
          </Modal.Header>
       
          <Form
            onSubmit={onUpdate}
            initialValues={{
              region: region?.region,
              region_code: region?.region_code,
              id_area: region?.id_area,
              id_region: region?.id_region


            }}
            render={({ handleSubmit,  }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="col-12">
                   
                          <Row className="form-group">
                            <Col xs={4} md={3}>
                              <label className="form-label">Region</label>
                            </Col>
                            <Col xs={8} md={9}>
                              <Field
                                name="region"
                                component="input"
                                type="text"
                                placeholder="region"
                                className="form-control"
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col xs={4} md={3}>
                              <label className="form-label">Code Region</label>
                            </Col>
                            <Col xs={8} md={9}>
                              <Field
                                name="region_code"
                                component="input"
                                type="text"
                                placeholder="region_code"
                                className="form-control"
                              />
                            </Col>
                          </Row>
                          <Row className="form-group">
                            <Col xs={4} md={3}>
                              <label className="form-label">Role</label>
                            </Col>
                            <Col xs={8} md={9}>
                              <Field name="id_area" component="select"   className="form-control">
                                {areas.map((aturan) => (
                                  <option value={aturan.id_area} key={aturan.id_area}>
                                    {aturan.area}
                                  </option>
                                ))}
                              </Field>
                            </Col>
                          </Row>
                      </div>
                   
                </Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose1}>
                    Close
                  </ButtonBS>
                  <ButtonBS variant="primary" type="submit">
                    <Icon.Check2Square className="mx-1" size={15} />
                    Simpan Edit
                  </ButtonBS>
                </Modal.Footer>
                
              </FormBS>
            )}
          />
        </Modal>
      </div>
    </>
  );

}

