import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import * as Icon from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import moment from "moment";
import { Chrono } from "react-chrono";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import { Url } from "../../../global_konstanta/global";
import axios from "axios";
import {
    Button as ButtonBS,
    Col,
    Form as FormBS,
    Modal,
    Row
} from "react-bootstrap";

export default function Close_Aproved() {
    const user = JSON.parse(localStorage.getItem("user"));
    // const [setTikets] = useState([]);
    const [tiketsClose, setTiketsClose] = useState([]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [judul, setJudul] = useState();
    const [tiket, setTiket] = useState([]);
    const [activity, setactivityTiket] = useState([]);
    const [datas, setDatas] = useState([]);
    const [showProggres, setShowProggres] = useState(false);

    const handleProggres = () => setShowProggres(true);
    const handleCloseProggres = () => setShowProggres(false);
    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleShow1 = () => setShow1(true);
    const handleShow2 = () => setShow2(true);
    const handleShow3 = () => setShow3(true);
    const tulisJudul = (jdl) => setJudul(jdl);

    // function refreshPage() {
    //     window.location.reload(false);
    //   }
    // const handleSubmit = async (id) => {
    //     if (confirm(id) == true) {
    //         try {
    //             await axios.delete(`${Url}/tiket/${id}`, {
    //                 headers: {
    //                     Authorization: `${localStorage.getItem("accessToken")}`
    //                 }
    //             });
    //             getTikets();
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // };


    const onSubmit = async (values) => {
        await axios({
            method: "post",
            url: `${Url}/tiket?tiketid=&status=all`, headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            },

            data: values
        })
            .then(function (response) {
                console.log(response);
                getTiketsClose();
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const getactivityTiket = async (id) => {
        await axios
            .get(`${Url}/activity/${id}`, {
                headers: {
                    Authorization: `${localStorage.getItem("accessToken")}`
                }
            })
            .then((value) => {
                setactivityTiket(value.data.activity);
                setDatas(value.data.activity.map(items => {
                    return {
                        title: items.activity,
                        cardTitle: moment.utc(items?.date_activity).format(
                            "D MMMM YYYY"
                        ),
                        cardSubtitle: moment.utc(items?.date_activity).tz("Asia/Jakarta").format(
                            "HH:mm:ss"
                        ),
                        cardDetailedText: items.flag_activity
                    };
                }));
                getactivit();
                console.log(activity);
            })
            .catch((err) => {
                console(`errorrr${err}`);
            });
    };

    const getactivit = async () => {

        setTimeout(function () {


            handleProggres();
        }, 2000);
    };
    const Aproved = async (values) => {
        await axios({
            method: "put",
            url: `${Url}/tiket/${values.id}`, headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            },

            data: {
                "dt_status_ticket": "Close"
            }
        })
            .then(function (response) {
                console.log(response);
                getTiketsClose();
                handleClose2();
            })
            .catch(function (error) {
                console.log(error);
                getTiketsClose();
                handleClose2();
            });
    };
    const Reject = async (values) => {
        await axios({
            method: "put",
            url: `${Url}/tiket/${values.id}`, headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            },

            data: values
        })
            .then(function (response) {
                console.log(response);
                getTiketsClose();
                handleClose3();
            })
            .catch(function (error) {
                console.log(error);
                getTiketsClose();
                handleClose3();
            });
    };
    useEffect(() => {
        // getTikets();
        getTiket();
        getTiketsClose();
        //     getUser();
        $(document).ready(function () {
            setTimeout(function () {
                $("#table_user").DataTable();
            }, 1000);
        });
    }, []);

    // const getTikets = async () => {
    //     await axios.get(`${Url}/tiket?tiketid=&status=${props.name}`, {
    //         headers: {
    //             Authorization: `${localStorage.getItem("accessToken")}`
    //         }
    //     }).then(value => {
    //         setTikets(value.data.photos);
    //     }).catch(err => {
    //         console.log(`errorrr${err}`);
    //     });


    // };

    const getTiket = async (id) => {
        await axios.get(`${Url}/tiketbyId/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        }).then(value => {
            setTiket(value.data.data);
        }).catch(err => {
            console(`errorrr${err}`);
        });



    };
    const getTiketsClose = async () => {

        await axios.get(`${Url}/tiket?tiketid=&status=ClosePending`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        }).then(value => {
            setTiketsClose(value.data.photos);
        }).catch(err => {
            console(`errorrr${err}`);
        });



    };

    const onUpdate = async (values) => {
        if (values === null) {
            console.log(values);
        } else {
            await axios({
                method: 'put',
                url: Url + `/tiket/${values.id}`, headers: {
                    Authorization: `${localStorage.getItem("accessToken")}`
                },
                data: values
            })
                .then(function () {
                    getTiketsClose();
                    handleClose();
                });
        }


    };
    if (user.role === "manager") {
        return (
            <>
                <Navbar />
                <div className="main">
                    <Sidebar />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title text-bold">
                                            Data Tiket Closing Approval
                                        </div>
                                    </div>
                                    <div className="table-responsive table-sm">
                                        <table className="container table" id="table_user">
                                            <thead>
                                                <tr>
                                                    <th>Ticket Number</th>
                                                    <th>Request By</th>
                                                    <th>Kota</th>
                                                    <th>Status Ticket</th>
                                                    <th>Region</th>
                                                    <th>Status Ticket</th>
                                                    <th>Category</th>
                                                    {/* <th>Link Report</th> */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tiketsClose.map((orang) => (
                                                    <tr key={orang.id}>
                                                        <td>{orang.dt_ticket_number}</td>
                                                        <td>{orang.User?.request_name}</td>
                                                        <td>{orang.dt_city}</td>
                                                        <td>{orang.dt_primary_plan}</td>
                                                        <td>{orang.dt_region}</td>
                                                        <td>{orang.dt_status_ticket}</td>
                                                        <td>{orang.dt_category}</td>
                                                        {/* <td><a href={orang.dt_link_report} target="_blank" rel="noopener noreferrer">link driver</a></td> */}
                                                        <td>
                                                            <div className="btn-group">
                                                                <ButtonBS
                                                                    variant="success float-right"
                                                                    onClick={() => {
                                                                        handleShow1();
                                                                        tulisJudul("Edit User");
                                                                        getTiket(orang.id);
                                                                    }}>
                                                                    Show
                                                                </ButtonBS>
                                                                <ButtonBS
                                                                    variant="primary float-right"
                                                                    onClick={() => {
                                                                        handleShow2();
                                                                        tulisJudul("Edit User");
                                                                        getTiket(orang.id);
                                                                    }}>
                                                                    Aproved
                                                                </ButtonBS>
                                                                <ButtonBS
                                                                    variant="danger float-right"
                                                                    onClick={() => {
                                                                        handleShow3();
                                                                        tulisJudul("Edit User");
                                                                        getTiket(orang.id);
                                                                    }}>
                                                                    Reject
                                                                </ButtonBS>
                                                                <ButtonBS
                                                                    size="sm"
                                                                    variant="success"
                                                                    onClick={() => {
                                                                        getactivityTiket(orang.dt_ticket_number);
                                                                        getTiket(orang.id);
                                                                        //  handleProggres();

                                                                    }}>
                                                                    Activity
                                                                </ButtonBS>


                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{judul}</Modal.Title>
                        </Modal.Header>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{

                            }}
                            render={({ handleSubmit, values }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <form>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Ticket Number</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_ticket_number"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder="tiket_number"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Area</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_area"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder="area"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Region</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_region"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder="region"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Date</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_request_date"
                                                                    type="datetime"
                                                                    component="input"
                                                                    placeholder="date"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Team Dt</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_team"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="team_dt"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Kota</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_city"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="kota"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">primary Plan</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_primary_plan"
                                                                    type="text"
                                                                    component="input" placeholder="primary_plan"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Phone Number Customer</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_customer_phone_number"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="number customer"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Customer Name</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_customer_name"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="dt_customer_name"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Pic Tsel</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_pic_request_tsel"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="pic_tsel"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Issue</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_issue"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="Issue"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Troubleshoot</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_action_troubleshoot"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="troubleshoot"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Site Cover</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_site_cover"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="site_cover"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Status Activity</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_status_activity"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="status_activity"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className="form-group">
                                                        <Col xs={4} md={3}>
                                                            <label className="form-label">Status Ticket</label>
                                                        </Col>
                                                        <Col xs={8} md={9}>
                                                            <Field
                                                                name="dt_status_ticket"
                                                                type="text"
                                                                component="input"
                                                                placeholder="status_ticket"
                                                                rows="2"
                                                            />
                                                        </Col>
                                                    </Row> */}
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">Data Remark</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_remark"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="remark"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-group">
                                                            <Col xs={4} md={3}>
                                                                <label className="form-label">category</label>
                                                            </Col>
                                                            <Col xs={8} md={9}>
                                                                <Field
                                                                    name="dt_category"
                                                                    type="text"
                                                                    component="input"
                                                                    placeholder="category"
                                                                    rows="2"
                                                                />
                                                            </Col>
                                                        </Row>



                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ButtonBS variant="secondary" onClick={handleClose}>
                                            Close
                                        </ButtonBS>
                                        <ButtonBS variant="primary" type="submit">
                                            <Icon.Check2Square className="mx-1" size={15} />
                                            Simpan Tiket
                                        </ButtonBS>
                                    </Modal.Footer>
                                    <pre>{JSON.stringify(values, 0, 2)}</pre>
                                </FormBS>
                            )}
                        />
                    </Modal>

                    <Modal show={showProggres} onHide={handleCloseProggres}>
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
                            render={({ handleSubmit }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>
                                        <div className="col-12">

                                            {" "}

                                            <Chrono

                                                items={datas}
                                                mode="VERTICAL"
                                                flipLayout
                                            />

                                            {/* <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Activity</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            component="input"
                            rows="2"
                            type="text"
                            placeholder=" activity"
                            name="activity"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Keteranagan
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="flag_activity"
                            component="textarea"
                            placeholder="Ticket Description"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row> */}

                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {/* <ButtonBS variant="secondary" onClick={handleCloseProggres}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS> */}
                                    </Modal.Footer>
                                </FormBS>
                            )}
                        />
                    </Modal>

                    <Modal show={show1} onHide={handleClose1}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detail Data Tiket</Modal.Title>
                        </Modal.Header>
                        {/* {console.log("inisalah=" + user.username)} */}
                        <Form
                            onSubmit={onUpdate}
                            initialValues={{
                                id: tiket?.id,
                                dt_ticket_number: tiket?.dt_ticket_number,
                                dt_area: tiket?.dt_area,
                                dt_region: tiket?.dt_region,
                                dt_request_date: tiket?.dt_request_date,
                                dt_team: tiket?.dt_team,
                                dt_city: tiket?.dt_city,
                                dt_primary_plan: tiket?.dt_primary_plan,
                                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                                dt_customer_name: tiket?.dt_customer_name,
                                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                                dt_issue: tiket?.dt_issue,
                                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                                dt_site_cover: tiket?.dt_site_cover,
                                // dt_status_activity: tiket?.dt_status_activity,
                                dt_status_ticket: tiket?.dt_status_ticket,
                                dt_remark: tiket?.dt_remark,
                                dt_category: tiket?.dt_category,
                                dt_link_report: tiket?.dt_link_report,

                            }}
                            render={({ handleSubmit, }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>
                                        <div className="col-12">
                                            <form>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Ticket Number</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_ticket_number"
                                                            component="input"
                                                            type="text"
                                                            placeholder="Tiket ID"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Area</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_area"
                                                            component="input"
                                                            type="text"
                                                            placeholder="area"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Region</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_region"
                                                            component="input"
                                                            type="text"
                                                            placeholder="region"
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Date</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_request_date"
                                                            type="datetime"
                                                            component="input"
                                                            placeholder="date"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Team Dt</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_team"
                                                            type="text"
                                                            component="input"
                                                            placeholder="team_dt"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Kota</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_city"
                                                            type="text"
                                                            component="input"
                                                            placeholder="kota"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">primary Plan</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_primary_plan"
                                                            type="text"
                                                            component="input" placeholder="primary_plan"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Phone Number Customer</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_customer_phone_number"
                                                            type="text"
                                                            component="input"
                                                            placeholder="number customer"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Customer Name</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_customer_name"
                                                            type="text"
                                                            component="input"
                                                            placeholder="dt_customer_name"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Pic Tsel</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_pic_request_tsel"
                                                            type="text"
                                                            component="input"
                                                            placeholder="pic_tsel"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Issue</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_issue"
                                                            type="text"
                                                            component="input"
                                                            placeholder="Issue"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                {/* <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Troubleshoot</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        className="form-control sm"
                                                        name="dt_action_troubleshoot"
                                                        type="text"
                                                        component="input"
                                                        placeholder="troubleshoot"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Site Cover</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        className="form-control sm"
                                                        name="dt_site_cover"
                                                        type="text"
                                                        component="input"
                                                        placeholder="site_cover"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row> */}
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">category</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                             disabled="true"
                                                            className="form-control sm"
                                                            name="dt_category"
                                                            type="text"
                                                            component="input"
                                                            placeholder="category"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>

                                            </form>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {/* <ButtonBS variant="secondary" onClick={handleClose1}>
                                        Close
                                    </ButtonBS>
                                    <ButtonBS variant="primary" type="submit">
                                        <Icon.Check2Square className="mx-1" size={15} />
                                        Update Tiket
                                    </ButtonBS> */}
                                    </Modal.Footer>
                                </FormBS>
                            )}
                        />
                    </Modal>
                    <Modal show={show2} onHide={handleClose2}>
                        <Modal.Header closeButton>
                            <Modal.Title>Pembertiahuan</Modal.Title>
                        </Modal.Header>
                        {/* {console.log("inisalah=" + user.username)} */}
                        <Form
                            onSubmit={Aproved} initialValues={{

                                id: tiket?.id,
                                // dt_ticket_number: tiket?.dt_ticket_number,
                                dt_city: tiket?.dt_city,
                                dt_primary_plan: tiket?.dt_primary_plan,
                                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                                dt_customer_name: tiket?.dt_customer_name,
                                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                                dt_issue: tiket?.dt_issue,
                                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                                dt_site_cover: tiket?.dt_site_cover,
                                // dt_status_activity: tiket?.dt_status_activity,
                                dt_status_ticket: tiket?.dt_status_ticket,
                                dt_remark: tiket?.dt_remark,
                                dt_category: tiket?.dt_category,
                                // dt_link_report: tiket?.dt_link_report,

                            }}
                            render={({ handleSubmit, }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>Apa anda yakin Ingin Aproved </Modal.Body>
                                    <Modal.Footer>
                                        <ButtonBS variant="secondary" onClick={handleClose2}>
                                            Close
                                        </ButtonBS>
                                        <ButtonBS variant="primary" type="submit">
                                            <Icon.Check2Square className="mx-1" size={15} />
                                            Aproved
                                        </ButtonBS>
                                    </Modal.Footer>
                                </FormBS>
                            )}
                        />
                    </Modal>
                    <Modal show={show3} onHide={handleClose3}>
                        <Modal.Header closeButton>
                            <Modal.Title>Pembertiahuan</Modal.Title>
                        </Modal.Header>
                        {/* {console.log("inisalah=" + user.username)} */}
                        <Form
                            onSubmit={Reject} initialValues={{

                                id: tiket?.id,
                                // dt_ticket_number: tiket?.dt_ticket_number,
                                dt_city: tiket?.dt_city,
                                dt_primary_plan: tiket?.dt_primary_plan,
                                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                                dt_customer_name: tiket?.dt_customer_name,
                                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                                dt_issue: tiket?.dt_issue,
                                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                                dt_site_cover: tiket?.dt_site_cover,
                                // dt_status_activity: tiket?.dt_status_activity,
                                dt_status_ticket: "Reject",
                                dt_remark: tiket?.dt_remark,
                                dt_category: tiket?.dt_category,
                                dt_issue_reject2: tiket?.dt_issue_reject2
                                // dt_link_report: tiket?.dt_link_report,

                            }}
                            render={({ handleSubmit, }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>
                                        <Row className="form-group">
                                            <Col xs={4} md={3}>
                                                <label className="form-label"> Berikan keterangan</label>
                                            </Col>
                                            <Col xs={8} md={9}>
                                                <Field
                                                    name="dt_issue_reject2"
                                                    component="textarea"
                                                    placeholder=" Description"
                                                    className="form-control"
                                                    rows="2"
                                                />
                                            </Col>
                                        </Row></Modal.Body>
                                    <Modal.Footer>
                                        <ButtonBS variant="secondary" onClick={handleClose3}>
                                            Close
                                        </ButtonBS>
                                        <ButtonBS variant="danger" type="submit">
                                            <Icon.Check2Square className="mx-1" size={15} />
                                            Reject
                                        </ButtonBS>
                                    </Modal.Footer>
                                </FormBS>
                            )}
                        />
                    </Modal>
                </div>
            </>
        );
    } else {
        return (
            <>
                <Navbar />
                <div className="main">
                    <Sidebar />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title text-bold">
                                            Data Tiket Closing Approval
                                        </div>
                                    </div>
                                    <div className="table-responsive table-sm">
                                        <table className="container table" id="table_user">
                                            <thead>
                                                <tr>
                                                    <th>Ticket Number</th>
                                                    <th>Request By</th>
                                                    <th>Kota</th>
                                                    <th>Primary Plan</th>
                                                    <th>Region</th>
                                                    <th>Status Ticket</th>
                                                    <th>Category</th>
                                                    {/* <th>Link Report</th> */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tiketsClose.map((orang) => (
                                                    <tr key={orang.id}>
                                                        <td>{orang.dt_ticket_number}</td>
                                                        <td>{orang.User?.request_name}</td>
                                                        <td>{orang.dt_city}</td>
                                                        <td>{orang.dt_primary_plan}</td>
                                                        <td>{orang.dt_region}</td>
                                                        <td>{orang.dt_status_ticket}</td>
                                                        <td>{orang.dt_category}</td>
                                                        {/* <td><a href={orang.dt_link_report} target="_blank" rel="noopener noreferrer">link driver</a></td> */}
                                                        <td>
                                                            <div className="btn-group">
                                                                <ButtonBS
                                                                    variant="success float-right"
                                                                    onClick={() => {
                                                                        handleShow1();
                                                                        tulisJudul("Edit User");
                                                                        getTiket(orang.id);
                                                                    }}>
                                                                    Show
                                                                </ButtonBS>
                                                                <ButtonBS
                                                                    size="sm"
                                                                    variant="primary"
                                                                    onClick={() => {
                                                                        getactivityTiket(orang.dt_ticket_number);
                                                                        getTiket(orang.id);
                                                                        //  handleProggres();

                                                                    }}>
                                                                    Activity
                                                                </ButtonBS>



                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>



                    <Modal show={show1} onHide={handleClose1}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detail Data Tiket</Modal.Title>
                        </Modal.Header>
                        {/* {console.log("inisalah=" + user.username)} */}
                        <Form
                            onSubmit={onUpdate}
                            initialValues={{
                                id: tiket?.id,
                                dt_ticket_number: tiket?.dt_ticket_number,
                                dt_area: tiket?.dt_area,
                                dt_region: tiket?.dt_region,
                                dt_request_date: moment.utc(tiket?.dt_request_date).format(
                                    "D MMMM YYYY"
                                ),
                                dt_team: tiket?.dt_team,
                                dt_city: tiket?.dt_city,
                                dt_primary_plan: tiket?.dt_primary_plan,
                                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                                dt_customer_name: tiket?.dt_customer_name,
                                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                                dt_issue: tiket?.dt_issue,
                                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                                dt_site_cover: tiket?.dt_site_cover,
                                // dt_status_activity: tiket?.dt_status_activity,
                                dt_status_ticket: tiket?.dt_status_ticket,
                                dt_remark: tiket?.dt_remark,
                                dt_category: tiket?.dt_category,
                                dt_link_report: tiket?.dt_link_report,

                            }}
                            render={({ handleSubmit, }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>
                                        <div className="col-12">
                                            <form>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Ticket Number</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_ticket_number"
                                                            component="input"
                                                            type="text"
                                                            placeholder="Tiket ID"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Area</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_area"
                                                            component="input"
                                                            type="text"
                                                            placeholder="area"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Region</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_region"
                                                            component="input"
                                                            type="text"
                                                            placeholder="region"
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Date</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_request_date"
                                                            type="datetime"
                                                            component="input"
                                                            placeholder="date"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Team Dt</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_team"
                                                            type="text"
                                                            component="input"
                                                            placeholder="team_dt"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Kota</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_city"
                                                            type="text"
                                                            component="input"
                                                            placeholder="kota"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">primary Plan</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_primary_plan"
                                                            type="text"
                                                            component="input" placeholder="primary_plan"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Phone Number Customer</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_customer_phone_number"
                                                            type="text"
                                                            component="input"
                                                            placeholder="number customer"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Customer Name</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_customer_name"
                                                            type="text"
                                                            component="input"
                                                            placeholder="dt_customer_name"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Pic Tsel</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_pic_request_tsel"
                                                            type="text"
                                                            component="input"
                                                            placeholder="pic_tsel"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Issue</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_issue"
                                                            type="text"
                                                            component="input"
                                                            placeholder="Issue"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>
                                                {/* <Row className="form-group">
                                                                        <Col xs={4} md={3}>
                                                                            <label className="form-label">Troubleshoot</label>
                                                                        </Col>
                                                                        <Col xs={8} md={9}>
                                                                            <Field
                                                                                className="form-control sm"
                                                                                name="dt_action_troubleshoot"
                                                                                type="text"
                                                                                component="input"
                                                                                placeholder="troubleshoot"
                                                                                rows="2"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="form-group">
                                                                        <Col xs={4} md={3}>
                                                                            <label className="form-label">Site Cover</label>
                                                                        </Col>
                                                                        <Col xs={8} md={9}>
                                                                            <Field
                                                                                className="form-control sm"
                                                                                name="dt_site_cover"
                                                                                type="text"
                                                                                component="input"
                                                                                placeholder="site_cover"
                                                                                rows="2"
                                                                            />
                                                                        </Col>
                                                                    </Row> */}
                                                <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">category</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="dt_category"
                                                            type="text"
                                                            component="input"
                                                            placeholder="category"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row>

                                            </form>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {/* <ButtonBS variant="secondary" onClick={handleClose1}>
                                                                Close
                                                            </ButtonBS>
                                                            <ButtonBS variant="primary" type="submit">
                                                                <Icon.Check2Square className="mx-1" size={15} />
                                                                Update Tiket
                                                            </ButtonBS> */}
                                    </Modal.Footer>
                                </FormBS>
                            )}
                        />
                    </Modal>
                    <Modal show={showProggres} onHide={handleCloseProggres}>
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
                            render={({ handleSubmit }) => (
                                <FormBS onSubmit={handleSubmit}>
                                    <Modal.Body>
                                        <div className="col-12">

                                            {" "}

                                            <Chrono

                                                items={datas}
                                                mode="VERTICAL"
                                                flipLayout
                                            />

                                            {/* <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Activity</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            component="input"
                            rows="2"
                            type="text"
                            placeholder=" activity"
                            name="activity"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Keteranagan
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="flag_activity"
                            component="textarea"
                            placeholder="Ticket Description"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row> */}

                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {/* <ButtonBS variant="secondary" onClick={handleCloseProggres}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS> */}
                                    </Modal.Footer>
                                </FormBS>
                            )}
                        />
                    </Modal>


                </div>
            </>
        );
    }
}
