import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import * as Icon from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import { Url } from "../../../global_konstanta/global";
import axios from "axios";
import Select from "react-select";
import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row
} from "react-bootstrap";
import { FcPlus } from "react-icons/fc";

export default function TicketDraft(props) {
  const [validated, setValidated] = useState(false);
  // const user = JSON.parse(localStorage.getItem("user"));
  const [tikets, setTikets] = useState([]);
  const [categoris, setCategoris] = useState([]);
  // const [subcategoris, setSubCategoris] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  // const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [kotas, setKotas] = useState([]);
  const [showSpinner, setshowSpinner] = useState(false);
  const handleClosevalid = () => setValidated(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [judul, setJudul] = useState();
  const [tiket, setTiket] = useState([]);
  const [showA, setShowA] = useState(true);
  const [selectedValue, setSelectedValue] = useState(3);
  const [ value,setValue] = useState('');
  const handleChange1 = e => {
    setSelectedValue(e.value);
   
  };
  function handleChange(event) {
    setValue(event.target.value);
  }

   
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleShow3 = () => setShow3(true);
  const handleShow2 = () => setShow2(true);
  const handleShow1 = () => setShow1(true);
  const handleShow = () => setShow(true);
  const tulisJudul = (jdl) => setJudul(jdl);
  const toggleShowA = () => setShowA(!showA);

  const handleSubmit = async (values) => {


    
      try {
        await axios.delete(`${Url}/tiket/${values.id}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });
        getTikets();
        toggleShowA();
        handleClose3();
      } catch (error) {
        console.log(error);
      }
    
  };
  function randomString(len, an) {
    an = an && an.toLowerCase();
    var str = "",
      i = 0,
      min = an == "a" ? 10 : 0,
      max = an == "n" ? 10 : 62;
    for (; i++ < len;) {
      var r = (Math.random() * (max - min) + min) << 0;
      str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
    }
    return str;
  }
  const onSubmit1 = async (values) => {
    if (values === null) {
      console.log(values);
    } else {
      await axios({
        method: "put",
        url: Url + `/tiket/${values.id}`,
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        },
        data: {
          dt_status_ticket: "Pending"
        }
      }).then(function () {
        getTikets();
        handleClose2();
      });
    }
  };

  const onSubmit = async (event) => {
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries());
    console.log(values.start_date);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setshowSpinner(true);
    }

    await axios({
      method: "post",
      url: `${Url}/tiket?tiketid=&status=all`,
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },

      data: {
        dt_ticket_number: values.dt_ticket_number,
        dt_city: values.dt_city,
        dt_primary_plan: values.dt_primary_plan,
        dt_issue: values.dt_issue,
        dt_customer_name: values.dt_customer_name,
        dt_customer_phone_number: values.dt_customer_phone_number,
        dt_category: values.dt_category
      }
    })
      .then(function () {
        setTimeout(() => {
        getTikets();
        handleClose();
        setValidated(false);
          setshowSpinner(false);
        }, 1000);
      })
      .catch(function (error) {
        setshowSpinner(false);
        console.log(error);
      });
    setValidated(true);
    setTimeout(() => {
      setValidated(false);
    }, 5000);
  };
  const closeModal = () => {
    handleClose();
    handleClosevalid();
  };
  useEffect(() => {
    getTikets();
    getCategoris();
    getSubCategoris();
    getKotas();
    getTiket();
    //     getUser();
    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 1000);
    });
  }, []);

  const getTikets = async () => {
    await axios
      .get(`${Url}/tiketbyIdUser?status=${props.name2}&status=${props.name}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTikets(value.data.photos);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };
  const getCategoris = async () => {
    const response = await axios.get(`${Url}/category`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.table(`ini=>` + response.data.category);
    setCategoris(response.data.category);
  };
  const getSubCategoris = async (category_id) => {
    const response = await axios.get(`${Url}/sub-categorybyId/${category_id}`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.table(`ini=>` + response.data.category);
    // setSubCategoris(response.data.data);
  };
  const handleCategoryChange = (event) => {
    const category_id = event.target.value;
    setSelectedCategoryId(category_id);
    // setSelectedSubcategoryId(category_id);
    setCategoris([]);
    getCategoris(category_id);
  };
  // const handleSubcategoryChange = (event) => {
    // const subcategoryId = parseInt(event.target.value);
    // setSelectedSubcategoryId(subcategoryId);
  // };

  const getKotas = async () => {
    const response = await axios.get(`${Url}/kota`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    // console.log('byid=>' + response.data.data);
    setKotas(response.data.data);
  };

  const getTiket = async (id) => {
    await axios
      .get(`${Url}/tiketbyId/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTiket(value.data.data);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };

  const onUpdate = async (values) => {
    if (values === null) {
      console.log(values);
    } else {
      await axios({
        method: "put",
        url: Url + `/tiket/${values.id}`,
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        },
        data: values
      }).then(function () {
        getTikets();
        handleClose1();
      });
    }
  };
  return (
    <>
      <Navbar />
      <div className="main">
        <Sidebar />
        <main className="content">
          <div className="container">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title text-bold">
                    Draft Ticket
                    <ButtonBS
                      size="sm"
                      variant="outline-primary float-right"
                      onClick={() => {
                        handleShow();
                      }}>
                      Create ticket<FcPlus></FcPlus>
                    </ButtonBS>
                  </div>
                </div>
                <div className="table-responsive table-sm ">
                  <table className="container table" id="table_user">
                    <thead>
                      <tr>
                        <th>Ticket Number</th>
                        <th>Request By</th>
                        <th>Location</th>
                        <th>Ticket Name</th>
                        <th>Region</th>
                        <th>Status Ticket</th>
                        <th>Category</th>
                        {/* <th>Link Report</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tikets.map((orang) => (
                        <tr key={orang.id}>
                          <td>{orang.dt_ticket_number}</td>
                          <td>{orang.User?.request_name}</td>
                          <td>{orang.dt_city}</td>
                          <td>{orang.dt_primary_plan}</td>
                          <td>{orang.dt_region}</td>
                          <td>{orang.dt_status_ticket}</td>
                          <td>{orang.dt_category}</td>
                          
                          {/* <td><a href={orang.dt_link_report} target="_blank" rel="noopener noreferrer">link driver</a></td> */}
                          <td>
                            <div className="btn-group">
                            {orang?.dt_issue_reject != null ?<ButtonBS
                            variant="info float-right"
                            onClick={() => {
                              handleShow4();
                              getTiket(orang.id);
                            }}>
                            Note
                          </ButtonBS>: null }
                              <ButtonBS
                                variant="success float-right"
                                onClick={() => {
                                  handleShow2();
                                  getTiket(orang.id);
                                }}>
                                Submit
                              </ButtonBS>
                              <ButtonBS
                                variant="primary float-right"
                                onClick={() => {
                                  handleShow1();
                                  tulisJudul("Edit Tiket");
                                  getTiket(orang.id);
                                }}>
                                Edit
                              </ButtonBS>
                              <ButtonBS
                                variant="danger float-right"
                                onClick={() => {
                                  handleShow3();
                                  getTiket(orang.id);
                                }}>
                                Hapus
                              </ButtonBS>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{judul}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(showSpinner) ? <div className="justify-content-center text-center"><img src="/img/logo/LOADING.png" className="img-fluid" alt="oke" /></div> : <FormBS noValidate validated={validated}
              onSubmit={onSubmit}>
              <Modal.Body>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <FormBS.Label>Ticket Number</FormBS.Label>
                  <FormBS.Control
                    required
                    readOnly
                    type="text"
                    placeholder="Ticket Number"
                    name="dt_ticket_number"
                    Value={"DT" + randomString(10, "n")} 
                    onChange={handleChange} 
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Input Ticket Number.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <FormBS.Label>Category</FormBS.Label>
          <FormBS.Select size="lg" id="category-select" value={selectedCategoryId} onChange={handleCategoryChange} name="dt_category"  placeholder="Category"  className="form-control">
        <option>-Pilih Category</option>
          {categoris.map((aturan) => (

                                  <option  value={aturan.name} key={aturan.name}>
                                    {aturan.name}
                                  </option>
                                ))}
      </FormBS.Select>
      
          <FormBS.Control.Feedback type="invalid">
            Please Input Category.
          </FormBS.Control.Feedback>
        </FormBS.Group>
        {/* {subcategoris.length > 0 && (
        <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <FormBS.Label>Sub Category</FormBS.Label>
          <FormBS.Select size="lg"  value={selectedSubcategoryId || ''} onChange={handleSubcategoryChange} name="dt_category"  placeholder="Category"  className="form-control">
        
          {subcategoris.map((aturan) => (
                                  <option value={aturan.name} key={aturan.name}>
                                    {aturan.name}
                                  </option>
                                ))}
      </FormBS.Select>
          <FormBS.Control.Feedback type="invalid">
            Please Input Category.
          </FormBS.Control.Feedback>
        </FormBS.Group>
        )} */}
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <label className="form-label">Location</label>
                <Select
                            placeholder="Select Option"
                            name="dt_city"
                            value={kotas.find(obj => obj.value === selectedValue)} // set selected value
                            options={kotas.map((e) => ({
                              label: e.name,
                              value: e.name,
                              key: e.name,
                              selectedValue: e.name
                            }))}
                            onChange={handleChange1} />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter  Location Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput4">
                  <FormBS.Label>Ticket Name</FormBS.Label>
                  <FormBS.Control
                    required
                    type="text"
                    placeholder="Ticket Name"
                    name="dt_primary_plan"
                    
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Customer Name Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput3">
                  <FormBS.Label>Ticket Description</FormBS.Label>
                  <FormBS.Control
                    required
                    type="text"
                    placeholder="Ticket Description"
                    name="dt_issue"
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Ticket Description Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput4">
                  <FormBS.Label>Customer Name</FormBS.Label>
                  <FormBS.Control
                    required
                    type="text"
                    placeholder="Customer Name"
                    name="dt_customer_name"
                   
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Customer Name Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>

                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <FormBS.Label> Phone Number Customer</FormBS.Label>
                  <FormBS.Control
                    required
                    type="number"
                    placeholder="Phone Number Customer"
                    name="dt_customer_phone_number"
                    maxLength={12}
                  />
                  <FormBS.Control.Feedback type="invalid">
                    Please Enter Phone Number Customer Valid.
                  </FormBS.Control.Feedback>
                </FormBS.Group>
               
              </Modal.Body>
              <Modal.Footer>
                <ButtonBS variant="secondary" onClick={closeModal}>
                  Close
                </ButtonBS>
                <ButtonBS variant="warning" type="submit"  value={value} >
                  <Icon.Check2Square className="mx-1" size={15} />
                  Simpan Ticket
                </ButtonBS>
              </Modal.Footer>
            </FormBS>}
          </Modal.Body>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>{judul}</Modal.Title>
          </Modal.Header>
          {/* {console.log("inisalah=" + user.username)} */}
          <Form
            onSubmit={onUpdate}
            initialValues={{
              dt_ticket_number: "DT" + randomString(10, "n"),
              id: tiket?.id,
              // dt_ticket_number: tiket?.dt_ticket_number,
              dt_city: tiket?.dt_city,
              dt_primary_plan: tiket?.dt_primary_plan,
              dt_customer_phone_number: tiket?.dt_customer_phone_number,
              dt_customer_name: tiket?.dt_customer_name,
              dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
              dt_issue: tiket?.dt_issue,
              dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
              dt_site_cover: tiket?.dt_site_cover,
              // dt_status_activity: tiket?.dt_status_activity,
              dt_status_ticket: tiket?.dt_status_ticket,
              dt_remark: tiket?.dt_remark,
              dt_category: tiket?.dt_category
              // dt_link_report: tiket?.dt_link_report,
            }}
            render={({ handleSubmit }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="col-12">
                    <form>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Ticket Number</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            disabled
                            className="form-control sm"
                            name="dt_ticket_number"
                            component="input"
                            type="text"
                            placeholder="tiket_number"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Category</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field name="dt_category" className="form-control" component="select">
                            <option >
                              -Location -
                            </option>
                            {categoris.map((aturan) => (
                              <option value={aturan.name} key={aturan.name}>
                                {aturan.name}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row>
                      {/* <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Category</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                           id="category-select"
                            value={selectedCategoryId || ''}
                            onChange={handleCategoryChange}
                            name="dt_category"
                            className="form-control"
                            component="select">
                            <option>- Category -</option>
                            {categoris.map((aturan) => (
                              <option value={aturan.category_id} key={aturan.category_id}>
                                {aturan.name}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row> */}
                      
                      {/* <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">SubCategory</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="dt_category"
                            value={selectedSubcategoryId || ''} 
                            onChange={handleSubcategoryChange}
                            className="form-control"
                            component="select">
                            <option>- Sub Category -</option>
                            {subcategoris.map((aturan) => (
                              <option value={aturan.name} key={aturan.name}>
                                {aturan.name}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row> */}
                      
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Location</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field name="dt_city" className="form-control" component="select">
                            <option >
                              -Location -
                            </option>
                            {kotas.map((aturan) => (
                              <option value={aturan.name} key={aturan.name}>
                                {aturan.name}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Ticket Name</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            name="dt_primary_plan"
                            type="text"
                            component="input"
                            placeholder="Ticket Name"
                            rows="2"
                          />
                        </Col>
                      </Row>
                     
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Ticket Description
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="dt_issue"
                            component="textarea"
                            placeholder="Ticket Description"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Customer Name</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            name="dt_customer_name"
                            type="text"
                            component="input"
                            placeholder="Customer Name"
                            rows="2"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Phone Number Customer
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            name="dt_customer_phone_number"
                            type="text"
                            component="input"
                            placeholder="Phone Number Customer"
                            rows="2"
                          />
                        </Col>
                      </Row>
                     
                    </form>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose1}>
                    Close
                  </ButtonBS>
                  <ButtonBS variant="primary" type="submit">
                    <Icon.Check2Square className="mx-1" size={15} />
                    Update Tiket
                  </ButtonBS>
                </Modal.Footer>
              </FormBS>
            )}
          />
        </Modal>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Submit Ticket</Modal.Title>
          </Modal.Header>
          {/* {console.log("inisalah=" + user.username)} */}
          <Form
            onSubmit={onSubmit1}
            initialValues={{
              dt_ticket_number: "DT" + randomString(10, "n"),
              id: tiket?.id,
              // dt_ticket_number: tiket?.dt_ticket_number,
              dt_city: tiket?.dt_city,
              dt_primary_plan: tiket?.dt_primary_plan,
              dt_customer_phone_number: tiket?.dt_customer_phone_number,
              dt_customer_name: tiket?.dt_customer_name,
              dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
              dt_issue: tiket?.dt_issue,
              dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
              dt_site_cover: tiket?.dt_site_cover,
              // dt_status_activity: tiket?.dt_status_activity,
              dt_status_ticket: tiket?.dt_status_ticket,
              dt_remark: tiket?.dt_remark,
              dt_category: tiket?.dt_category
              // dt_link_report: tiket?.dt_link_report,
            }}
            render={({ handleSubmit }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                Apakah anda yakin ingin Submit  Draft Ticket ini?{" "}
                </Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose2}>
                    Close
                  </ButtonBS>
                  <ButtonBS variant="primary" type="submit">
                    <Icon.Check2Square className="mx-1" size={15} />
                    Submit
                  </ButtonBS>
                </Modal.Footer>
              </FormBS>
            )}
          />
        </Modal>
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Hapus Ticket</Modal.Title>
          </Modal.Header>
          {/* {console.log("inisalah=" + user.username)} */}
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              dt_ticket_number: "DT" + randomString(10, "n"),
              id: tiket?.id,
              // dt_ticket_number: tiket?.dt_ticket_number,
              dt_city: tiket?.dt_city,
              dt_primary_plan: tiket?.dt_primary_plan,
              dt_customer_phone_number: tiket?.dt_customer_phone_number,
              dt_customer_name: tiket?.dt_customer_name,
              dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
              dt_issue: tiket?.dt_issue,
              dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
              dt_site_cover: tiket?.dt_site_cover,
              // dt_status_activity: tiket?.dt_status_activity,
              dt_status_ticket: tiket?.dt_status_ticket,
              dt_remark: tiket?.dt_remark,
              dt_category: tiket?.dt_category
              // dt_link_report: tiket?.dt_link_report,
            }}
            render={({ handleSubmit }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>Apakah anda yakin ingin menghapus Draft Ticket ini?</Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose3}>
                    Close
                  </ButtonBS>
                  <ButtonBS variant="danger" type="submit">
                    <Icon.Check2Square className="mx-1" size={15} />
                    Hapus
                  </ButtonBS>
                </Modal.Footer>
              </FormBS>
            )}
          />
        </Modal>
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Catatan</Modal.Title>
          </Modal.Header>
          {/* {console.log("inisalah=" + user.username)} */}
          <Form
            onSubmit={onUpdate} initialValues={{


              dt_issue_reject: tiket?.dt_issue_reject


            }}
            render={({ handleSubmit, }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                  <Row className="form-group">
                    <Col xs={4} md={3}>
                      <label className="form-label"> Rejected Note</label>
                    </Col>
                    <Col xs={8} md={9}>
                      <Field
                        name="dt_issue_reject"
                        component="textarea"
                        placeholder="Rejected Note"
                        className="form-control"
                        rows="2"
                      />
                    </Col>
                  </Row></Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose4}>
                    Close
                  </ButtonBS>
                  {/* <ButtonBS variant="danger" type="submit">
                                        <Icon.Check2Square className="mx-1" size={15} />
                                        Reject
                                    </ButtonBS> */}
                </Modal.Footer>
              </FormBS>
            )}
          />
        </Modal>
      </div>
    </>
  );
}
