import { FcOk } from "react-icons/fc";
import React, { useEffect, useState } from "react";
import 'moment-timezone';
const $ = require("jquery");
$.DataTable = require("datatables.net");
import * as Icon from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import { Chrono } from "react-chrono";

import './ticket.css';
// import ReactModal from 'react-modal';

// import Button from 'react-bootstrap/Button';

// import Button from "react-bootstrap/Button";
import axios from "axios";
import moment from "moment";
import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row,
  Alert,
  Tab,
  Badge,
  Tabs,
  // Button
} from "react-bootstrap";
import swal from "sweetalert";

export default function Ticket() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [key, setKey] = useState("home");
  const [tikets, setTikets] = useState([]);
  // const [ setTiketsall] = useState([]);


  const [tiketsClose, setTiketsClose] = useState([]);
  const [categoris, setCategoris] = useState([]);
  const [showProggres, setShowProggres] = useState(false);
  const [showProggres2, setShowProggres2] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [judul, setJudul] = useState();
  const [tiket, setTiket] = useState([]);
  const [activity, setactivityTiket] = useState([]);
  const [activity2, setactivityTiket2] = useState([]);
  const [datas, setDatas] = useState([]);
  // const [region, setdata] = useState([]);
  
 
    // const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  // const handleClose3 = () => setShow3(false);

  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);
  const handleProggres = () => setShowProggres(true);
  const handleProggres2 = () => setShowProggres2(true);
  const handleCloseProggres = () => setShowProggres(false);
  const handleCloseProggres2 = () => setShowProggres2(false);
  // const handleShow3 = () => setShow3(true);
  const tulisJudul = (jdl) => setJudul(jdl);
  const onSubmit = async (values) => {
    await axios({
      method: "post",
      url: `${Url}/tiket?tiketid=&status=all`,
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: values
    })
      .then(function (response) {
        console.log(response);
        getTikets();
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 
  const onSubmit1 = async (values) => {
    await axios({
      method: "post",
      url: `${Url}/activity/insert`,
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: values
    })
      .then(function (response) {
        console.log(response);
        getTikets();
        handleCloseProggres();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 
  function randomString(len, an) {
    an = an && an.toLowerCase();
    var str = "",
      i = 0,
      min = an == "a" ? 10 : 0,
      max = an == "n" ? 10 : 62;
    for (; i++ < len;) {
      var r = (Math.random() * (max - min) + min) << 0;
      str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
    }
    return str;
  }

  useEffect(() => {
    getTikets();
    getTiket();
    getCategoris();
    getTiketsClose();
    // getTiketsall ();
   
    // data();


    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 1000);
      setTimeout(function () {
        $("#table_user_data").DataTable();
      }, 1000);
      // $("#table_user").DataTable({
      //     processing: true,
      // });
    });
  }, []);


//   const getTiketsall = async () => {
//     await axios
//     .get(`${Url}/tiketFiltering?category=${filter?.dt_category}&status=all&region=${filter?.dt_region}&tiketid=${filter?.dt_ticket_number}`, {
//       headers: {
//         Authorization: `${localStorage.getItem("accessToken")}`
//       }
//     })
//     .then((value) => {
//       setTiketsall(value.data.data);
//     })
//     .catch((err) => {
//       console(`errorrr${err}`);
//     });


// };
  // const getTikets = async () => {
  //   await axios
  //     .get(`${Url}/tiketFiltering?category=${filter?.dt_category}&status=Open&region=${filter?.dt_region}&tiketid=${filter?.dt_ticket_number}`, {
  //       headers: {
  //         Authorization: `${localStorage.getItem("accessToken")}`
  //       }
  //     })
  //     .then((value) => {
  //       setTikets(value.data.data);
  //     })
  //     .catch((err) => {
  //       console(`errorrr${err}`);
  //     });
     
  // };
  const getTikets = async () => {
    await axios
      .get(`${Url}/tiket?tiketid=&status=Open`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTikets(value.data.photos);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
     
  };

  const getTiketsClose = async () => {
    await axios
      .get(`${Url}/tiket?tiketid=&status=Close`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTiketsClose(value.data.photos);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };
  // const getCategoris = async () => {
  //   const response = await axios.get(`${Url}/category`, {
  //     headers: {
  //       Authorization: `${localStorage.getItem("accessToken")}`
  //     }
  //   });
  //   // console.table(`ini=>` + response.data.category);
  //   setCategoris(response.data.category);
  // };
  const getCategoris = async () => {
    const response = await axios.get(`${Url}/sub-category`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.table(`ini=>` + response.data.category);
    setCategoris(response.data.category);
  };
  // const data = async () => {
  //   const response = await axios.get(`${Url}/regionbyIdarea?area=`, {
  //     headers: {
  //       Authorization: `${localStorage.getItem("accessToken")}`
  //     }
  //   });
  //   // console.log('byid=>' + response.data.data);
  //   setdata(response.data.data);
  // };
  
   
    // const [filter, setFilter] = useState({
    //   dt_category: 'all',
    //   //  dt_status_ticket: 'all',
    //   dt_region: 'all',
    //   dt_ticket_number: 'all',
    //   startDate: '',
    //   endDate: '',

    // });
  
    // const handleFilterChange = (e) => {
    //   setFilter({
    //     ...filter,
    //     [e.target.name]: e.target.value
    //   });
    // };
    // const [error, setError] = useState(null);
  
    // const handleFilterSubmit = () => {
    //   setError(null);
    //   fetch(`${Url}/tiketFiltering?category=${filter.dt_category}&status=all&region=${filter?.dt_region}&tiketid=${filter.dt_ticket_number}&startDate=${filter.startDate}&endDate=${filter.endDate}`, {
    //     headers: {
    //       Authorization: `${localStorage.getItem("accessToken")}`
    //     }
    //   })
    //     .then(res => res.json())
    //     .then(data => {
    //       console.log(data);
    //       // handle data here
    //       getTikets(data);
    //       getTiketsClose(data);
    //     })
    //     .catch(err => {
    //       console.error(err);
    //     });
    // };
    
  const getTiket = async (id) => {
    await axios
      .get(`${Url}/tiketbyId/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTiket(value.data.data);

      })  
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };
 

  const getactivityTiket = async (id) => {
    await axios
      .get(`${Url}/activity/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setactivityTiket(value.data.activity);
        setDatas(value.data.activity.map(items => {
          return {
            title: items.activity,
            cardTitle: moment.utc(items?.date_activity).format(
              "D MMMM YYYY"
            ),
            cardSubtitle: moment.utc(items?.date_activity).tz("Asia/Jakarta").format(
              "HH:mm:ss"
            ),
            cardDetailedText: items.flag_activity
          };
        }));
        getactivit();
       
        console.log(activity);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };
  const getactivityTiket2 = async (id) => {
    await axios
      .get(`${Url}/activity/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setactivityTiket2(value.data.activity);
        setDatas(value.data.activity.map(items => {
          return {
            title: items.activity,
            cardTitle: moment.utc(items?.date_activity).format(
              "D MMMM YYYY"
            ),
            cardSubtitle: moment.utc(items?.date_activity).tz("Asia/Jakarta").format(
              "HH:mm:ss"
            ),
            cardDetailedText: items.flag_activity
          };
        }));
        getactivit2();
        console.log(activity2);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };

  // const tiketupdate = { updated_at: new Date() };
  const getactivit = async () => {

    setTimeout(function () {


      handleProggres();
    }, 2000);
  };
  const getactivit2 = async () => {

    setTimeout(function () {


      handleProggres2();
    }, 2000);
  };
  const onUpdate1 = async (values) => {
    if (user.role === "pm") {
      if (tiket.status_link === "waiting") {
        swal(
          "Pemberitahuan",
          "Please Waiting Report Link Aproved To Leader !",
          'info'
        );
      } else if (tiket.status_link === "pendingdone") {
        swal(
          "Pemberitahuan",
          "Please Waiting Report Link Aproved To Leader !",
          'info'
        );
      } else {
        await axios({
          method: 'put',
          url: Url + `/tiket/${values.id}`, headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          },
          data:
            values,
        })
          .then(function () {
            getTikets();
            handleClose1();
          });
      }
    } else {
      if (values === null) {
        console.log(values);
      } else {
        await axios({
          method: 'put',
          url: Url + `/tiket/${values.id}`, headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          },
          data:
            values,
        })
          .then(function () {
            getTikets();
            handleClose();
          });
      }
    }


  };
  const onUpdate = async (values) => {
    if (values === null) {
      console.log(values);
    } else {
      await axios({
        method: "put",
        url: Url + `/tiket/${values.id}`,
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        },
        data: values
      }).then(function () {
        getTikets();
        handleClose1();
        handleClose3();
      });
    }
  };
  const actiondate = async (values) => {
    if (tiket.updated_at != null) {
      swal("failed", "data sudah di masukan", "error");
      console.log(values);
      handleClose2();
    } else {
      await axios({
        method: "put",
        url: Url + `/tiket-action/${values.id}`,
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        },
        data: values
      }).then(function () {
        getTikets();
        handleClose2();
      });
      
    }
  };


  if (user.role === "root" || user.role === "manager") {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title text-bold">
                      All Data Ticket <FcOk></FcOk>
                    </div>
                  </div>
                  <div className="card-body">
                  
                  <div className="table-responsive table-sm">
                     </div>
  {/* <div className="filter-container">
    {error && <p className="filter-error">{error}</p>}
    <div className="filter-item">
      <label className="form-label">Category</label>{""}
      <select
        name="dt_category"
        onChange={handleFilterChange}
        value={filter?.dt_category}
      >
        {categoris.map((aturan) => (
          <option value={aturan.name} key={aturan.name}>
            {aturan.name}
          </option>
        ))}
        <option value="all">All</option>
      </select>
    </div>
    <div className="filter-item">
      <label className="form-label">Ticket Name</label>{""}
      <select
        name="dt_ticket_number"
        placeholder="Cari Tiket ID"
        onChange={handleFilterChange}
        value={filter?.dt_ticket_number}
      >
        {tiketsall.map((aturan) => (
          <option value={aturan.dt_ticket_number} key={aturan.dt_ticket_number}>
            {aturan.dt_primary_plan}
          </option>
        ))}
        <option value="all">All</option>
      </select>
    </div>
    <div className="filter-item">
      <label className="form-label">Region</label>{""}
      <select
        name="dt_region"
        onChange={handleFilterChange}
        value={filter?.dt_region}
      >
        {region.map((aturan) => (
          <option value={aturan.region} key={aturan.region}>
            {aturan.region}
          </option>
        ))}
      </select>
    </div>
    <div className="filter-item">
      <label className="form-label">Start Date</label>{""}
      <input
        className="filter-input"
        type="date"
        name="startDate"
        onChange={handleFilterChange}
        value={filter.startDate}
      />
    </div>
    <div className="filter-item">
      <label className="form-label">End Date</label>{""}
      <input
        className="filter-input"
        type="date"
        name="endDate"
        onChange={handleFilterChange}
        value={filter.endDate}
      />
    </div>
    <Button variant="danger" type="submit" onClick={handleFilterSubmit}>
      Filter
    </Button>

</div> */}
 
                    <Tabs
                      id="controlled-tab-example"
                      transition={true}
                      activeKey={key}
                      onSelect={(k) => setKey(k)}>
                      
                      <Tab eventKey="home" title="Open">
                     
                        
                                          
              
                        <div className="table-responsive table-sm">
                          <table
                            id="table_user"
                            className="table-striped table-sm ">
                            <thead>
                              <tr>
                                <th>Ticket Number</th>
                                <th>Date Request</th>
                                <th>Request By</th>
                                <th>Location</th>
                                <th>Ticket Name</th>
                                <th>Region</th>
                                <th>Status Ticket</th>
                                <th>Category</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="table-sm">
                              {tikets?.map((orang) => (
                                <tr key={orang?.id}>
                                  <td>{orang?.dt_ticket_number}</td>
                                  <td>{moment.utc(orang?.dt_request_date).format("D MMMM YYYY")}</td>
                                  <td>{orang.User?.request_name}</td>
                                  <td>{orang?.dt_city}</td>
                                  <td>{orang?.dt_primary_plan}</td>
                                  <td>{orang?.dt_region}</td>
                                  <td>
                                    <Badge pill bg="primary">
                                      {orang?.dt_status_ticket}
                                    </Badge>{" "}
                                  </td>
                                  <td>{orang?.dt_category}</td>
                                  <td>
                                    <div className="btn-group">
                                      <div className="btn-group">
                                      <ButtonBS
                                        variant="warning float-right"
                                        onClick={() => {
                                          if (orang?.updated_at != null) {
                                            swal("failed", "data sudah di masukan", "error");
                                            handleClose2();
                                          } else {
                                          handleShow2();
                                          tulisJudul("Schedule");
                                          getTiket(orang.id);
                                        }}}>
                                        Schedule
                                      </ButtonBS>
                                        <ButtonBS
                                          variant="success float-right"
                                          onClick={() => {
                                            handleShow1();
                                            tulisJudul("Detail Data Ticket");
                                            getTiket(orang.id);
                                          }}>
                                          Show
                                        </ButtonBS>

                                        <ButtonBS
                                          size="sm"
                                          variant="primary"
                                          onClick={() => {
                                            getactivityTiket(orang.dt_ticket_number);
                                            getTiket(orang.id);
                                            //  handleProggres();

                                          }}>
                                          Activity
                                        </ButtonBS>
                                        
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Tab>
                      <Tab eventKey="profile" title="Close">
                        <div className="table-responsive table-sm">
                            
                            <table
                              id="table_user_data"
                              className="table-striped table-sm ">
                              <thead>
                                <tr>
                                  <th>Ticket Number</th>
                                  <th>Date Request</th>
                                  <th>Request By</th>
                                  <th>Location</th>
                                  <th>Ticket Name</th>
                                  <th>Region</th>
                                  <th>Status Ticket</th>
                                  <th>Category</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="table-sm">
                                {tiketsClose.map((orang) => (
                                  <tr key={orang.id}>
                                    <td>{orang.dt_ticket_number}</td>
                                    <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                                    <td>{orang.User?.request_name}</td>
                                    <td>{orang.dt_city}</td>
                                    <td>{orang.dt_primary_plan}</td>
                                    <td>{orang.dt_region}</td>
                                    <td>
                                      <Badge pill bg="success" animation="glow">
                                        {orang.dt_status_ticket}
                                      </Badge>{" "}
                                    </td>
                                    <td>{orang.dt_category}</td>
                                    <td>
                                      <div className="btn-group">
                                        <ButtonBS
                                          variant="success float-right"
                                          onClick={() => {
                                            handleShow1();
                                            tulisJudul("Detail Data Ticket");
                                            getTiket(orang.id);
                                          }}>
                                          Show
                                        </ButtonBS>
                                        <ButtonBS
                                          size="sm"
                                          variant="warning"
                                          onClick={() => {
                                            getactivityTiket(orang.dt_ticket_number);
                                            getTiket(orang.id);


                                          }}>
                                          Activity
                                        </ButtonBS>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </main>

        

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment.utc(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                // request_name: tiket?.User.request_name,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment.utc(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date Request</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Schedule</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="updated_at"
                              type="text"
                              component="input"
                              placeholder="link_report"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        {/* <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Request By</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="request_name"
                                                            type="text"
                                                            component="input"
                                                            placeholder="Request By"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row> */}
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="primary_plan"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="dt_customer_name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        {/* <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row> */}

                      

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Ticket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_status_ticket"
                              type="text"
                              component="input"
                              placeholder="status_ticket"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>

                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={actiondate}
              initialValues={{
                id: tiket?.id,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Alert color="danger">
                              <Field
                                className="form-control sm"
                                name="updated_at"
                                type="date"
                                component="input"
                                placeholder="date"
                              />
                            </Alert>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                          {tiket?.updated_at != null ? <label className="form-label">Date sudah di Update</label> : null }
                          </Col>
                          <Col xs={8} md={9}>
                            <Alert color="danger">
                            {tiket?.updated_at != null ? <Field
                                className="form-control sm"
                                name="updated_at"
                                type="datetime"
                                component="input"
                                placeholder="date"
                              /> : null }
                            </Alert>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose2}>
                      Close
                    </ButtonBS>
                    <ButtonBS
                      variant="primary"
                      type="submit"
                      class="alert alert-success"
                      role="alert">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Schedule
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

          <Modal show={showProggres} onHide={handleCloseProggres}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Form
              onSubmit={onSubmit1}
              initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">

                      {" "}

                      <Chrono

                        items={datas}
                        mode="VERTICAL"
                        flipLayout
                      />

                      {/* <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Activity</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            component="input"
                            rows="2"
                            type="text"
                            placeholder=" activity"
                            name="activity"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Keteranagan
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="flag_activity"
                            component="textarea"
                            placeholder="Ticket Description"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row> */}

                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {/* <ButtonBS variant="secondary" onClick={handleCloseProggres}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS> */}
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
        

        </div>
      </>
    );
  } else if (user.role === "pm") {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title text-bold">All Data Ticket</div>
                  </div>
                  <div className="card-body">
                    <div className="col-12">
                      <Tabs
                        id="controlled-tab-example"
                        transition={true}
                        activeKey={key}
                        onSelect={(k) => setKey(k)}>
                        <Tab eventKey="home" title="Open">
                          <div className="table-responsive table-sm">
                            <table
                              id="table_user"
                              className="table-striped table-sm ">
                              <thead>
                                <tr>
                                  <th>Ticket Number</th>
                                  <th>Date Request</th>
                                  <th>Request By</th>
                                  <th>Location</th>
                                  <th>Ticket Name</th>
                                  <th>Region</th>
                                  <th>Status Ticket</th>
                                  <th>Category</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="table-sm">
                                {tikets.map((orang) => (
                                  <tr key={orang.id}>
                                    <td>{orang.dt_ticket_number}</td>
                                    <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                                    <td>{orang.User?.request_name}</td>
                                    <td>{orang.dt_city}</td>
                                    <td>{orang.dt_primary_plan}</td>
                                    <td>{orang.dt_region}</td>
                                    <td>
                                      <Badge pill bg="warning" animation="glow">
                                        {orang.dt_status_ticket}
                                      </Badge>{" "}
                                    </td>
                                    <td>{orang.dt_category}</td>
                                    <td>
                                      <div className="btn-group">
                                        <div className="btn-group">
                                          {/* <ButtonBS
                                            variant="success float-right"
                                            onClick={() => {
                                              handleShow2();
                                              tulisJudul("Detail Data Ticket");
                                              getTiket(orang.id);
                                            }}>
                                            Show
                                          </ButtonBS> */}
                                        </div>
                                        <ButtonBS
                                          size="sm"
                                          variant="primary"
                                          onClick={() => {
                                            handleShow3();
                                            tulisJudul("Update Ticket");
                                            getTiket(orang.id);
                                          }}>
                                           Update Ticket
                                        </ButtonBS>
                                        {/* <ButtonBS
                                          size="sm"
                                          variant="danger"
                                          onClick={() => {
                                            handleShow1();
                                            tulisJudul("Closing Ticket");
                                            getTiket(orang.id);
                                          }}>
                                          Action
                                        </ButtonBS> */}
                                        <ButtonBS
                                          size="sm"
                                          variant="warning"
                                          
                                          onClick={() => {
                                           
                                            getactivityTiket(orang.dt_ticket_number);
                                            getTiket(orang.id);


                                          }}>
                                          Activity
                                        </ButtonBS>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab>
                        <Tab eventKey="profile" title="Close">
                          <div className="table-responsive table-sm">
                            <table
                              id="table_user_data"
                              className="table-striped table-sm ">
                              <thead>
                                <tr>
                                  <th>Ticket Number</th>
                                  <th>Date Request</th>
                                  <th>Request By</th>
                                  <th>Location</th>
                                  <th>Ticket Name</th>
                                  <th>Region</th>
                                  <th>Status Ticket</th>
                                  <th>Category</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="table-sm">
                                {tiketsClose.map((orang) => (
                                  <tr key={orang.id}>
                                    <td>{orang.dt_ticket_number}</td>
                                    <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                                    <td>{orang.User?.request_name}</td>
                                    <td>{orang.dt_city}</td>
                                    <td>{orang.dt_primary_plan}</td>
                                    <td>{orang.dt_region}</td>
                                    <td>
                                      <Badge pill bg="success" animation="glow">
                                        {orang.dt_status_ticket}
                                      </Badge>{" "}
                                    </td>
                                    <td>{orang.dt_category}</td>
                                    <td>
                                      <div className="btn-group">
                                        <ButtonBS
                                          variant="success float-right"
                                          onClick={() => {
                                            handleShow2();
                                            tulisJudul("Detail Data Ticket");
                                            getTiket(orang.id);
                                          }}>
                                          Show
                                        </ButtonBS>
                                        <ButtonBS
                                          size="sm"
                                          variant="warning"
                                          onClick={() => {
                                            getactivityTiket2(orang.dt_ticket_number);
                                            getTiket(orang.id);
                                          }}>
                                          Activity
                                        </ButtonBS>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                // request_name: tiket?.User.request_name,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY"),
                request_name: tiket?.request_name,
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                            disabled="true"
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                            disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                            disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date Request</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Schedule</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="updated_at"
                              type="text"
                              component="input"
                              placeholder="Schedule"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                            disabled="true"
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="primary_plan"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled="true"
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled="true"
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="dt_customer_name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                            disabled="true"
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled="true"
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled="true"
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Ticket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled="true"
                              className="form-control sm"
                              name="dt_status_ticket"
                              type="text"
                              component="input"
                              placeholder="status_ticket"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate1}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Tiket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_status_ticket"
                              className="form-control"
                              component="select">
                              <option value="Open">Open</option>
                              <option value="Close">Close</option>
                            </Field>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS
                      variant="secondary"
                      size="sm"
                      onClick={handleClose1}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="warning" size="sm" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date Request</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Schedule</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="updated_at"
                              type="text"
                              component="input"
                              placeholder="link_report"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        {/* <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Request By</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="request_name"
                                                            type="text"
                                                            component="input"
                                                            placeholder="Request By"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row> */}
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder=""
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="Customer Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Ticket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_status_ticket"
                              type="text"
                              component="input"
                              placeholder="status_ticket"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS
                      variant="secondary"
                      size="sm"
                      onClick={handleClose3}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="warning" size="sm" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={showProggres} onHide={handleCloseProggres}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Form
              onSubmit={onSubmit1}
              initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">

                      {" "}

                      <Chrono

                        items={datas}
                        mode="VERTICAL"
                        flipLayout
                      />

                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Activity</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            component="input"
                            rows="2"
                            type="text"
                            placeholder=" Activity"
                            name="activity"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                          Summary Activity
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="flag_activity"
                            component="textarea"
                            placeholder="Summary Activity"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row>

                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleCloseProggres}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Activity
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={showProggres2} onHide={handleCloseProggres2}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Form
              onSubmit={onSubmit1}
              initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">

                      {" "}

                      <Chrono

                        items={datas}
                        mode="VERTICAL"
                        flipLayout
                      />

                    

                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
        </div>
      </>
    );
  } else if (user.role === "leader") {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title text-bold">All Data Ticket</div>
                  </div>
                  <div className="card-body">
                    <div className="col-12">
                      <Tabs
                        id="controlled-tab-example"
                        transition={true}
                        activeKey={key}
                        onSelect={(k) => setKey(k)}>
                        <Tab eventKey="home" title="Open">
                          <div className="table-responsive table-sm">
                            <table
                              id="table_user"
                              className="table-striped table-sm ">
                              <thead>
                                <tr>
                                  <th>Ticket Number</th>
                                  <th>Date Request</th>
                                  <th>Request By</th>
                                  <th>Location</th>
                                  <th>Ticket Name</th>
                                  <th>Region</th>
                                  <th>Status Ticket</th>
                                  <th>Category</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="table-sm">
                                {tikets.map((orang) => (
                                  <tr key={orang.id}>
                                    <td>{orang.dt_ticket_number}</td>
                                    <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                                    <td>{orang.User?.request_name}</td>
                                    <td>{orang.dt_city}</td>
                                    <td>{orang.dt_primary_plan}</td>
                                    <td>{orang.dt_region}</td>
                                    <td>
                                      <Badge pill bg="warning">
                                        {orang.dt_status_ticket}
                                      </Badge>{" "}
                                    </td>
                                    <td>{orang.dt_category}</td>
                                    <td>
                                      <div className="btn-group">
                                        <div className="btn-group">
                                        </div>
                                        <ButtonBS
                                          size="sm"
                                          variant="primary"
                                          onClick={() => {
                                            handleShow1();
                                            tulisJudul("Schedule");
                                            getTiket(orang.id);
                                          }}>
                                          Update Ticket
                                        </ButtonBS>
                                        <ButtonBS
                                          size="sm"
                                          variant="warning"
                                          onClick={() => {
                                            getactivityTiket(orang.dt_ticket_number);
                                            getTiket(orang.id);
                                          }}>
                                          Activity
                                        </ButtonBS>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab>
                        <Tab eventKey="profile" title="Close">
                          <div className="table-responsive table-sm">
                            <table
                              id="table_user_data"
                              className="table-striped table-sm ">
                              <thead>
                                <tr>
                                  <th>Ticket Number</th>
                                  <th>date Request</th>
                                  <th>Request By</th>
                                  <th>Location</th>
                                  <th>Ticket Name</th>
                                  <th>Region</th>
                                  <th>Status Ticket</th>
                                  <th>Category</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="table-sm">
                                {tiketsClose.map((orang) => (
                                  <tr key={orang.id}>
                                    <td>{orang.dt_ticket_number}</td>
                                    <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                                    <td>{orang.User?.request_name}</td>
                                    <td>{orang.dt_city}</td>
                                    <td>{orang.dt_primary_plan}</td>
                                    <td>{orang.dt_region}</td>
                                    <td>
                                      <Badge pill bg="success">
                                        {orang.dt_status_ticket}
                                      </Badge>{" "}
                                    </td>
                                    <td>{orang.dt_category}</td>
                                    <td>
                                      <div className="btn-group">
                                        <ButtonBS
                                          variant="success float-right"
                                          onClick={() => {
                                            handleShow2();
                                            tulisJudul("Detail Data Ticket");
                                            getTiket(orang.id);
                                          }}>
                                          Show
                                        </ButtonBS>
                                        <ButtonBS
                                          size="sm"
                                          variant="warning"
                                          onClick={() => {
                                            getactivityTiket2(orang.dt_ticket_number);
                                            getTiket(orang.id);


                                          }}>
                                          Activity
                                        </ButtonBS>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date Request</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Schedule</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="updated_at"
                              type="text"
                              component="input"
                              placeholder="link_report"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        {/* <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Request By</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="request_name"
                                                            type="text"
                                                            component="input"
                                                            placeholder="Request By"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row> */}
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder=""
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="Customer Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        {/* <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Status Activity
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_status_activity"
                              type="text"
                              component="input"
                              placeholder="status_activity"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Data Remark</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_remark"
                              type="text"
                              component="input"
                              placeholder="remark"
                              rows="2"
                            />
                          </Col>
                        </Row> */}
                        
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Ticket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="dt_status_ticket"
                              type="text"
                              component="input"
                              placeholder="status_ticket"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS
                      variant="secondary"
                      size="sm"
                      onClick={handleClose1}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="warning" size="sm" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={showProggres} onHide={handleCloseProggres}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Form
              onSubmit={onSubmit1}
              initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">

                      {" "}

                      <Chrono

                        items={datas}
                        mode="VERTICAL"
                        flipLayout
                      />

                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Activity</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            component="input"
                            rows="2"
                            type="text"
                            placeholder=" activity"
                            name="activity"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Summary Activity
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="flag_activity"
                            component="textarea"
                            placeholder="Summary Activity"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row>

                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleCloseProggres}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={showProggres2} onHide={handleCloseProggres2}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Form
              onSubmit={onSubmit1}
              initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">

                      {" "}

                      <Chrono

                        items={datas}
                        mode="VERTICAL"
                        flipLayout
                      />

                      {/* <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Activity</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            className="form-control sm"
                            component="input"
                            rows="2"
                            type="text"
                            placeholder=" activity"
                            name="activity"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Keteranagan
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="flag_activity"
                            component="textarea"
                            placeholder="Ticket Description"
                            className="form-control"
                            rows="2"
                          />
                        </Col>
                      </Row> */}

                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {/* <ButtonBS variant="secondary" onClick={handleCloseProggres}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS> */}
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                // request_name: tiket?.User.request_name,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY"),
                request_name: tiket?.request_name,
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date Request</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Schedule</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="updated_at"
                              type="text"
                              component="input"
                              placeholder="Schedule"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="primary_plan"
                              rows="2"
                            />
                          </Col>
                        </Row>
                       
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="dt_customer_name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                             disabled
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row>
                       

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Ticket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                            
                              className="form-control sm"
                              name="dt_status_ticket"
                              type="text"
                              component="input"
                              placeholder="status_ticket"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

        </div>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title text-bold">All Data Ticket</div>
                  </div>
                  <div className="card-body">
                    <Tabs
                      id="controlled-tab-example"
                      transition={true}
                      activeKey={key}
                      onSelect={(k) => setKey(k)}>
                      <Tab eventKey="home" title="Open">
                        <div className="table-responsive table-sm">
                          <table
                            id="table_user"
                            className="table-striped table-sm ">
                            <thead>
                              <tr>
                                <th>Ticket Number</th>
                                <th>Date Request</th>
                                <th>Request By</th>
                                <th>Location</th>
                                <th>Ticket Name</th>
                                <th>Region</th>
                                <th>Status Ticket</th>
                                <th>Category</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="table-sm">
                              {tikets.map((orang) => (
                                <tr key={orang.id}>
                                  <td>{orang.dt_ticket_number}</td>
                                  <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                                  <td>{orang.User?.request_name}</td>
                                  <td>{orang.dt_city}</td>
                                  <td>{orang.dt_primary_plan}</td>
                                  <td>{orang.dt_region}</td>
                                  <td>
                                    <Badge pill bg="primary">
                                      {orang.dt_status_ticket}
                                    </Badge>{" "}
                                  </td>
                                  <td>{orang.dt_category}</td>
                                  <td>
                                    <div className="btn-group">
                                      <div className="btn-group">
                                        
                                      <ButtonBS 
                                      class="alert alert-success"
                                       role="alert"
                                        variant="warning float-right"
                                        onClick={() => {
                                          if (orang?.updated_at != null) {
                                            swal("failed", "data sudah di masukan", "error");
                                            handleClose2();
                                          } else {
                                          handleShow2();
                                          tulisJudul("Schedule");
                                          getTiket(orang.id);
                                        }}}>
                                        Schedule
                                      </ButtonBS>
                                        <ButtonBS
                                          variant="success float-right"
                                          onClick={() => {
                                           
                                            handleShow1();
                                            tulisJudul("Detail Data Ticket");
                                            getTiket(orang.id);
                                          }}>
                                          Show
                                        </ButtonBS>
                                      </div>
                                    
                                      <ButtonBS
                                        size="sm"
                                        variant="primary"
                                        onClick={() => {
                                          getactivityTiket(orang.dt_ticket_number);
                                          getTiket(orang.id);
                                          //  handleProggres();

                                        }}>
                                        Activity
                                      </ButtonBS>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Tab>
                      <Tab eventKey="profile" title="Close">
                        <div className="table-responsive table-sm">
                          <table
                            id="table_user_data"
                            className="table-striped table-sm ">
                            <thead>
                              <tr>
                                <th>Ticket Number</th>
                                <th>Date Request</th>
                                <th>Request By</th>
                                <th>Location</th>
                                <th>Ticket Name</th>
                                <th>Region</th>
                                <th>Status Ticket</th>
                                <th>Category</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="table-sm">
                              {tiketsClose.map((orang) => (
                                <tr key={orang.id}>
                                  <td>{orang.dt_ticket_number}</td>
                                  <td>{moment.utc(orang.dt_request_date).format("D MMMM YYYY")}</td>
                                  <td>{orang.User?.request_name}</td>
                                  <td>{orang.dt_city}</td>
                                  <td>{orang.dt_primary_plan}</td>
                                  <td>{orang.dt_region}</td>
                                  <td>
                                    <Badge pill bg="success">
                                      {orang.dt_status_ticket}
                                    </Badge>{" "}
                                  </td>
                                  <td>{orang.dt_category}</td>
                                  <td>
                                    <div className="btn-group">
                                      <div className="btn-group">
                                        <ButtonBS
                                          variant="success float-right"
                                          onClick={() => {
                                            handleShow1();
                                            tulisJudul("Detail Data Ticket");
                                            getTiket(orang.id);
                                          }}>
                                          Show
                                        </ButtonBS>
                                        <ButtonBS
                                          size="sm"
                                          variant="primary"
                                          onClick={() => {
                                            getactivityTiket(orang.dt_ticket_number);
                                            getTiket(orang.id);
                                            //  handleProggres();

                                          }}>
                                          Activity
                                        </ButtonBS>
                                      </div>
                                      {/* <ButtonBS
                                        variant="warning float-right"
                                        onClick={() => {
                                          handleShow2();
                                          tulisJudul("Edit User");
                                          getTiket(orang.id);
                                        }}>
                                        Schedule
                                      </ButtonBS> */}

                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                dt_ticket_number: "DT" + randomString(10, "n"),
                dt_area: user.area.nama,
                dt_category: tiket?.dt_category
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="tiket_number"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Kota</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="Kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Primary Plan</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="Planing"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="Customer Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Pic Tsel</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_pic_request_tsel"
                              type="text"
                              component="input"
                              placeholder="Pic Tsel"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Issue</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_issue"
                              type="text"
                              component="input"
                              placeholder="Issue"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="Site Cover"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label"> Remark</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_remark"
                              type="text"
                              component="input"
                              placeholder="remark"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Link Report</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_link_report"
                              type="text"
                              component="input"
                              placeholder="link_report"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                // request_name: tiket?.User.request_name,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date Request</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Schedule</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled="true"
                              className="form-control sm"
                              name="updated_at"
                              type="text"
                              component="input"
                              placeholder="link_report"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="primary_plan"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="dt_customer_name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>

                        {/* <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row> */}
                        

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Ticket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_status_ticket"
                              type="text"
                              component="input"
                              placeholder="status_ticket"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={actiondate}
              initialValues={{
                id: tiket?.id,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Alert color="danger">
                              <Field
                                className="form-control sm"
                                name="updated_at"
                                type="date"
                                component="input"
                                placeholder="date"
                              />
                            </Alert>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                          {tiket?.updated_at != null ? <label className="form-label">Date sudah di Update</label> : null }
                          </Col>
                          <Col xs={8} md={9}>
                            <Alert color="danger">
                            {tiket?.updated_at != null ? <Field
                                className="form-control sm"
                                name="updated_at"
                                type="datetime"
                                component="input"
                                placeholder="date"
                              /> : null }
                            </Alert>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose2}>
                      Close
                    </ButtonBS>
                    <ButtonBS
                      variant="primary"
                      type="submit"
                     >
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Schedule
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

          <Modal show={showProggres} onHide={handleCloseProggres}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Form
              onSubmit={onSubmit1}
              initialValues={{ dt_ticket_number: tiket?.dt_ticket_number }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">

                      {" "}

                      <Chrono

                        items={datas}
                        mode="VERTICAL"
                        flipLayout
                      />

                  

                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
        </div>
      </>
    );
  }
}
