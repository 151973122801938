import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
// import Combobox from "react-widgets/Combobox";
import axios from "axios";
// import {
//     Button as ButtonBS,

// } from "react-bootstrap";




export default function DetaiTicket(props) {

    // State array variable to save and show data



    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const user = JSON.parse(localStorage.getItem("user"));
    const [tikets, setTikets] = useState([]);

    // const [setShow1] = useState(false);
    // const handleShow1 = () => setShow1(true);

    useEffect(() => {
        getTikets();




        $(document).ready(function () {
            setTimeout(function () {
                $("#table_user").DataTable();
            }, 1000);
        });
    }, []);
    const getTikets = async () => {

        await axios.get(`${Url}/tiket?tiketid=&status=${props.name}`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        }).then(value => {
            setTikets(value.data.photos);
        }).catch(err => {
            console(`errorrr${err}`);
        });
    };
    // const getTiket = async (id) => {
    //     await axios.get(`${Url}/tiketbyId/${id}`, {
    //         headers: {
    //             Authorization: `${localStorage.getItem("accessToken")}`
    //         }
    //     }).then(value => {
    //         setTiket(value.data.data);
    //     }).catch(err => {
    //         console(`errorrr${err}`);
    //     });



    // };



    return (

        <>
            <Navbar />
            <div className="main">
                <Sidebar />
                <main className="content">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body m-sm-3 m-md-5">
                                <div className="mb-4">
                                    Hello <strong>{user.username}</strong>,

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="text-muted">Payment No.</div>
                                        <strong>741037024</strong>
                                    </div>
                                    <div className="col-md-6 text-md-right">
                                        <div className="text-muted">Payment Date</div>
                                        <div className="date">
                                            <h3> {date}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <table className="container table" id="table_user">
                                        <thead>
                                            <tr>
                                                <th>Ticket Number</th>
                                                <th>Request By</th>
                                                <th>Kota</th>
                                                <th>Primary Plan</th>
                                                <th>Region</th>
                                                <th>Status Ticket</th>
                                                <th>Category</th>
                                                <th>Link Report</th>
                                                <th>action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tikets.map((orang) => (
                                                <tr key={orang.id}>
                                                    <td>{orang.dt_ticket_number}</td>
                                                    <td>{orang.User?.request_name}</td>
                                                    <td>{orang.dt_city}</td>
                                                    <td>{orang.dt_primary_plan}</td>
                                                    <td>{orang.dt_region}</td>
                                                    <td>{orang.dt_status_ticket}</td>
                                                    <td>{orang.dt_category}</td>
                                                    <td><a href={orang.dt_link_report} target="_blank" rel="noopener noreferrer">link driver</a></td>
                                                    <td>
                                                        {/* <div className="btn-group">
                                                            <ButtonBS
                                                                variant="success float-right"
                                                                onClick={() => {
                                                                    handleShow1();
                                                                    getTiket(orang.id);
                                                                }}>
                                                                Show
                                                            </ButtonBS>

                                                        </div> */}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="text-center">
                                    <p className="text-sm">
                                        <strong>Extra note:</strong> Please send all items at the same time to the shipping address. Thanks in advance.
                                    </p>

                                    <a href="#" className="btn btn-primary">
                                        Print this receipt
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>

            </div>
        </>
    );
}