import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ApexCharts from 'apexcharts';
import { Url } from "../global_konstanta/global";
import {
  RocketFilled
} from '@ant-design/icons';
const Barcolumn = () => {
  const [data, setData] = useState({});
  const [region, setRegion] = useState('all');
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url}/dashboard/tiket_bycategory_total?region=${region}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });

        


        const chartData = {
          series: [
            {
              // name: response.data.result.map(item => item.kategori),
              data: response.data.result.map(item => item.count)
            }
           
          ],
          xaxis: {
            categories: response.data.result.map(item => item.kategori)
          }
        };

        setData(chartData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [region]);
 
    useEffect(() => {
      if (data.series) {
        const chart = new ApexCharts(chartRef.current, {
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '40%',
              endingShape: 'rounded',
              colors: {
                ranges: [
                  {
                    from: 1,
                    to: 1,
                    color: '#008FFB'
                  },
                  {
                    from: 1,
                    to: 2,
                    color: '#00E396'
                  },
                  {
                    from: 2,
                    to: 3,
                    color: '#FEB019'
                  },
                  {
                    from: 3,
                    to: 4,
                    color: '#FF4560'
                  },
                  {
                    from: 4,
                    to: 5,
                    color: '#775DD0'
                  },
                  {
                    from: 5,
                    to: 6,
                    color: '#546E7A'
                  }
                ]
              }
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          fill: {
            opacity: 1
          },
          series: data.series,
          xaxis: data.xaxis
        });
        chart.render();
        
        return () => {
          if (chartRef.current) {
            chartRef.current.innerHTML = '';
          }
        };
      }
    }, [data, region]);
    
    

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
 
  return (
    <div>
           <div className='region ml-5'>
      <RocketFilled />  <label>Region :</label>
        <select value={region} onChange={handleRegionChange}>
        {""}  <option value="all">All</option>
          <option value="SUMBAGUT">SUMBAGUT</option>
          <option value="SUMBAGTENG">SUMBAGTENG</option>
          <option value="SUMBAGSEL">SUMBAGSEL</option>
          <option value="BALINUS">BALINUS</option>
          <option value="JATIM">JATIM</option>
          <option value="JATENG">JATENG</option>
        </select>
      </div>
      <div ref={chartRef} style={{ width: "170%", height: "180px", maxWidth: "768px"  }} />
    </div>
  );
};

export default Barcolumn;
