import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ApexCharts from 'apexcharts';
import { Url } from "../global_konstanta/global";
import {
  RocketFilled
} from '@ant-design/icons';
const LineChart = () => {
  const [data, setData] = useState({});
  const [region, setRegion] = useState('all');
  const chartRef = useRef(null);

  useEffect(() => {
 
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url}/dashboard/tiket_trenline?region=${region}&tahun=2024`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });
       
      
        

        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];

        const chartData = {
          series: [
            {
              name: 'Open',
              data: response.data.result.map(item => item.open)
            },
            {
              name: 'Close',
              data: response.data.result.map(item => item.close)
            }
          ],
          xaxis: {
            categories: response.data.result.map(item => monthNames[item.month - 1])
          }
        };

        setData(chartData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [region]);
 
  useEffect(() => {
    if (data.series) {
      const chart = new ApexCharts(chartRef.current, {
        chart: {
          type: 'line',
          height: 340,
        },
        series: data.series,
        xaxis: data.xaxis,
        annotations: {
          xaxis: [
            {
              x: 'March',
              strokeDashArray: 0,
              borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                text: 'Annotation for March',
              },
            },
          ],
        },
      });
      chart.render();
      
      return () => {
        if (chartRef.current) {
          chartRef.current.innerHTML = '';
        }
      };
    }
  }, [data, region]);
  
    

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
 
  return (
    <div className='LineChart ml-3'>
      <div className='region ml-5'>
      <RocketFilled />  <label>Region :</label>
        <select value={region} onChange={handleRegionChange}>
        {""}  <option value="all">All</option>
          <option value="SUMBAGUT">SUMBAGUT</option>
          <option value="SUMBAGTENG">SUMBAGTENG</option>
          <option value="SUMBAGSEL">SUMBAGSEL</option>
          <option value="BALINUS">BALINUS</option>
          <option value="JATIM">JATIM</option>
          <option value="JATENG">JATENG</option>
        </select>
      </div>
      <div ref={chartRef} style={{ width: "170%", height: "100px",maxWidth: "768px" }} />
    </div>
  );
};

export default LineChart;
