import React from 'react';
import Navbar from '../../components/Navbar';
// import Footer from './components/Footer';
import Sidebar from '../../components/Sidebar';
//jQuery libraries
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';


//For API Requests

import {
    Form, Button
} from "react-bootstrap";

export default function Wa_number() {



    return (

        <>
            <Navbar />
            <div className="main">
                <Sidebar />
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>NO Telp</Form.Label>
                        <Form.Control type="text" placeholder="NO TELP" />

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Messege</Form.Label>
                        <Form.Control type="text" placeholder="MESSEGE" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>

            </div>
        </>
    );
}
