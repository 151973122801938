import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ApexCharts from 'apexcharts';
import { Url } from "../global_konstanta/global";
import {
  RocketFilled
} from '@ant-design/icons';

const PieChart = () => {
  const [data, setData] = useState({});
  const [region, setRegion] = useState('all');
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url}/dashboard/tiket_tottal?region=${region}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });

        const chartData = {
          series: [
            response.data.Tiket[0].total,
            response.data.Tiket[1].total
          ],
          labels: ['Open','Close'],
        };

        setData(chartData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [region]);
 
  useEffect(() => {
    if (data.series) {
      const chart = new ApexCharts(chartRef.current, {
        chart: {
          id: 'pie-chart',
          type: 'pie',
          height: 360,
          animations: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          background: '#ffffff'
        },
        series: data.series,
        labels: ['Open','Close'],
        colors: ['#2196F3','#F44336'],
        fill: {
          type: 'gradient',
          // gradient: {
          //   shade: 'dark',
          //   shadeIntensity: 0.5,
          //   opacityFrom: 0.8,
          //   opacityTo: 0.5,
          //   stops: [0, 100]
          // }
        },
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 200,
          options: {
            chart: {
              width: 200
            }
          }
        }],
        plotOptions: {
          pie: {
            donut: {
              size: '85%',
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true
                }
              }
            },
            dataLabels: {
              style: {
                fontSize: '10px'
              }
            }
          }
        }
      });
      chart.render();
      return () => {
        if (chartRef.current) {
          chartRef.current.innerHTML = '';
        }
      };
    }
  }, [data, region]);
  
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  return (
    <div>
      <div className='region ml-3'>
        <RocketFilled />  <label>Region :</label>
        <select value={region} onChange={handleRegionChange}>
          <option value="all">All</option>
          <option value="SUMBAGUT">SUMBAGUT</option>
          <option value="SUMBAGTENG">SUMBAGTENG</option>
          <option value="SUMBAGSEL">SUMBAGSEL</option>
          <option value="BALINUS">BALINUS</option>
          <option value="JATIM">JATIM</option>
          <option value="JATENG">JATENG</option>
        </select>
      </div>
      <div ref={chartRef} style={{ width: "170%", height: "100px",maxWidth: "768px" }} />
    </div>
  );
};

export default PieChart;