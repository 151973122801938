import  React, { useEffect,useState } from 'react';
import Navbar from '../../components/Navbar';
// import Footer from './components/Footer';
import Sidebar from '../../components/Sidebar';
import Carousel from 'react-bootstrap/Carousel';
// import Button from 'react-bootstrap/Button';

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { Field, Form } from "react-final-form";
import { Url } from "../../global_konstanta/global";
import $ from "jquery";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import { FcOk } from "react-icons/fc";
import {
    Button as ButtonBS,
     Col,
    Form as FormBS,
    Modal,
     Row
  } from "react-bootstrap";
  import swal from "sweetalert";


export default function Profile() {
   
  const [show, setShow] = useState(false);
     const [User ,setUser] = useState([]);
     const [judul, setJudul] = useState();
     const tulisJudul = (jdl) => setJudul(jdl);
    //  const [Users ,setUsers] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        getUser();
        // getUsers();
        $(document).ready(function () {
          setTimeout(function () {
            $("#table_user").DataTable();
          }, 1000);
        });
      }, []);
      const getUser = async (id) => {
        const response = await axios.get(`${Url}/usersbyId/${id}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });
         console.log( response.data.data.id);
        setUser(response.data.data);
      };
      const onUpdate = async (values) => {
        await axios({
          method: "post",
          url: `${Url}/update_password`, headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          },
          data: values
        })
          .then(function (response) {
            console.log(response);
            getUser();
            swal(
              "Pemberitahuan",
              "Change Password telah berhasil !",
              'info'
            );
            handleClose("/");
          })
          .catch(function (error) {
            console.log(error);
            swal(
              "Pemberitahuan",
              "Change Password telah Gagal !",
              'info'
            );
          });
      };
        const user = JSON.parse(localStorage.getItem("user"));
        return (
            <>
                <Navbar />
                <div className="main">
                    <Sidebar />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="col-12">
                                <Carousel fade>
                                    <Carousel.Item interval={1500}>
                                        <img
                                            className="d-block w-100 rounded"
                                            src="/img/logo/banner.gif"
                                            alt="Third slide"
                                        />
                                        <Carousel.Caption>
                                            <h3 className='text-white'>First slide label</h3>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1500}>
                                        <img
                                            className="d-block w-100 rounded"
                                            src="/img/logo/banner.png"
                                            alt="Third slide"
                                        />

                                        <Carousel.Caption>
                                            <h3 className='text-white'>First slide label</h3>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1500}>
                                        <img
                                            className="d-block w-100 rounded"
                                            src="/img/logo/banner.gif"
                                            alt="Third slide"
                                        />


                                    </Carousel.Item>
                                </Carousel>
                                <div className="container-fluid mt-5 p-0">



                                    <div className="row">
                                        <div className="col-md-4 col-xl-3">
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    <h5 className="card-title mb-0">Profile Details</h5>
                                                </div>
                                                <div className="card-body text-center">
                                                    <img className="rounded-circle"
                                                        src="/img/logo/tl.jpeg"
                                                        alt="Chris Wood"
                                                        width="80"
                                                        height="80" />
                                                    <h5 className="mt-4">{user.username}</h5>

                                                    <div className="mb-5"> <FcOk></FcOk>{user.role}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-8 col-xl-9">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="card-actions float-right">
                                                        <div className="dropdown show">
                                                            <a href="#" data-toggle="dropdown" data-display="static">
                                                                <i className="align-middle" data-feather="more-horizontal"></i>
                                                            </a>

                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a className="dropdown-item" href="#">Action</a>
                                                                <a className="dropdown-item" href="#">Another action</a>
                                                                <a className="dropdown-item" href="#">Something else here</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5 className="card-title mb-0">Information</h5>
                                                </div>
                                                <div className="card-body h-100">
                                                    <table className="table table-striped" >
                                                        <thead>
                                                            <tr>
                                                                <th>Email</th>
                                                                <th>Phone</th>
                                                                <th>Jabatan</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <td>{user.email}</td>
                                                            <td>{user.nomor_handphone}</td>
                                                            <td>{user.role}</td>
                                                            <td>
                                                            </td>
                                                        </tbody>
                                                    </table>
                                                    <hr />

                                                    <a href="#" className="btn btn-warning btn-block">Edit Profile</a>
                                                    <ButtonBS className="btn btn-warning btn-block" onClick={() => {
                                                        handleShow();
                                                        tulisJudul("Change Password");
                                                        getUser(User.id);
                                                    }}>
                                                        Change Password
                                                    </ButtonBS>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </main>
                    <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{judul}</Modal.Title>
          </Modal.Header>
          <Form
            onSubmit={onUpdate}
            // initialValues={{
            //   password: User.password,
            

            // }}
            render={({ handleSubmit }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="col-12">
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">New Password</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="password"
                            component="input"
                            className="form-control"
                            type="password"
                            placeholder="password"
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Confirm Password</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="passwordConfirm"
                            component="input"
                            className="form-control"
                            type="password"
                            placeholder="passwordConfirm"
                          />
                        </Col>
                      </Row>
                      
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose}>
                    Close
                  </ButtonBS>
                  <ButtonBS variant="primary" type="submit">
                    <Icon.Check2Square className="mx-1" size={15} />
                    Simpan User
                  </ButtonBS>
                </Modal.Footer>
              </FormBS>
            )}
          />
        </Modal>
                </div>
            </>
        );
    
}


