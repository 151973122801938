import React from "react";


function Pagenotfound() {
 
  return (
    <main className="main">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="d-table-cell align-middle">
              <div className="card mt-5">
                <div className="card-body">
                <div className="text-center">
                      <img
                        src="/img/logo/PAGE404.png"
                        className="img-fluid"
                      />
                    </div>
                </div>
               <div className="p-2">
               <a className="btn btn-warning btn-sm mb-4 p-2 container-fluid" href="dashboard">Back to Home</a>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Pagenotfound;
