import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import * as Icon from "react-bootstrap-icons";
//import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import $ from "jquery";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import axios from "axios";
import { Form, Field } from "react-final-form";
import {
    Button as ButtonBS,
    Col,
    Form as FormBS,
    Modal,
    Row
} from "react-bootstrap";



//import { useParams } from "react-router-dom";

// import Button from "../../components/Button";

// import Modaltes from "../../components/Modaltes";

export default function Categori() {
    const [validated, setValidated] = useState(false);
    const [categoris, setCategoris] = useState([]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [categori, setCategori] = useState([]);
    const [showSpinner, setshowSpinner] = useState(false);
    //  const navigate = useNavigate();
    const handleClosevalid = () => setValidated(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);
    // const { id } = useParams();
    //const [name, setName] = useState("");
    useEffect(() => {
        getCategoris();
        getCategori();
        $(document).ready(function () {
            setTimeout(function () {
                $("#table_user").DataTable();
            }, 1000);
        });
    }, []);

    const getCategoris = async () => {
        const response = await axios.get(`${Url}/category`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }

        });
        console.log(`ini=>` + response.data.category);
        setCategoris(response.data.category);
    };

    const onSubmit = async (event) => {
        const data = new FormData(event.currentTarget);
        const values = Object.fromEntries(data.entries());
        console.log(values.start_date);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setshowSpinner(true);
        }

        await axios({
            method: 'post',
            url: Url + '/category', headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            },
            data: {
                name: values.name

            }
        })
            .then(function () {
                setTimeout(() => {
                    getCategoris();
                    handleClose();
                    setValidated(false);
                    setshowSpinner(false);
                }, 1000);
            })
            .catch(function (error) {
                setshowSpinner(false);
                console.log(error);
            });
        setValidated(true);
        setTimeout(() => {
            setValidated(false);
        }, 5000);

    };
    // const handleChange = event => {
    //    const { values, name } = event.target;
    //    setGroups({ ...groups, [name]: values });
    // };
    const closeModal = () => {
        handleClose();
        handleClosevalid();
    };
    const onUpdate = async (values) => {
        await axios({
            method: 'put',
            url: Url + '/category/' + values.category_id, headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            },
            data: values
        })
            .then(function () {
                getCategoris();
                handleClose1();
            });

    };

    const handleDelete = async (id) => {
        
            try {
                await axios.delete(`${Url}/category/${id}`, {
                    headers: {
                        Authorization: `${localStorage.getItem("accessToken")}`
                    }
                });
                getCategoris();
            } catch (error) {
                console.log(error);
            }
        
    };
    const getCategori = async (id) => {
        await axios
            .get(`${Url}/categorybyId/${id}`, {
                headers: {
                    Authorization: `${localStorage.getItem("accessToken")}`
                }
            })
            .then((value) => {
                console.log(value.data.data.category_id);
                setCategori(value.data.data);
            })
            .catch((err) => {
                console(`errorrr${err}`);
            });
    };
    // const getCategori = async (id) => {
    //     const response = await axios.get(`${Url}/categorybyId/${id}`, {
    //         headers: {
    //             Authorization: `${localStorage.getItem("accessToken")}`
    //         }
    //     });
    //     // console.log(`ini=>` + response.data.data.Groups[0].Role.name);
    //     console.log('byid=>' + response.data.data.name);
    //     setCategori(response.data.data);
    // };


    return (
        <>
            <Navbar />
            <div className="main">
                <Sidebar />
                <main className="content">
                    <div className="container">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">Master data Category</div>
                                    <ButtonBS
                                        variant="outline-warning float-right"
                                        onClick={handleShow}>
                                        <Icon.PlusCircleFill className="mx-1" size={17} />
                                        Tambah data Category
                                    </ButtonBS>
                                </div>
                                <div className="table-responsive table-sm ">
                                    <table className="table table-striped" id="table_user">
                                        <thead>
                                            <tr>
                                                <th>Name Category</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categoris.map((orang) => (
                                                <tr key={orang.category_id}>
                                                    <td>{orang.name}</td>
                                                    <td>
                                                        <ButtonBS
                                                            size="sm"
                                                            className="mx-1"
                                                            variant="warning"
                                                            onClick={() => {
                                                                handleShow1();
                                                                getCategori(orang.category_id);
                                                            }}>
                                                            <Icon.PencilFill className="mx-1" size={16} />
                                                            Edit
                                                        </ButtonBS>

                                                        <ButtonBS
                                                            size="sm"
                                                            className="mx-1"
                                                            variant="danger"
                                                            onClick={() => handleDelete(orang.category_id)}>

                                                            <Icon.XOctagonFill className="mx-1" size={16} />
                                                            Hapus
                                                        </ButtonBS>
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>

                    </Modal.Header>
                    <Modal.Body>
                        {(showSpinner) ? <div className="justify-content-center text-center"><img src="/img/logo/LOADING.png" className="img-fluid" alt="oke" /></div> : <FormBS noValidate validated={validated}
                            onSubmit={onSubmit}>
                            <Modal.Body>
                                <FormBS.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <FormBS.Label>Name</FormBS.Label>
                                    <FormBS.Control
                                        required
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                    />
                                    <FormBS.Control.Feedback type="invalid">
                                        Please Input name.
                                    </FormBS.Control.Feedback>
                                </FormBS.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <ButtonBS variant="secondary" onClick={closeModal}>
                                    Close
                                </ButtonBS>
                                <ButtonBS variant="warning" type="submit">
                                    <Icon.Check2Square className="mx-1" size={15} />
                                    Simpan User
                                </ButtonBS>
                            </Modal.Footer>
                        </FormBS>}
                    </Modal.Body>
                </Modal>
                <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Data Categori</Modal.Title>
                    </Modal.Header>
                    {console.log("name" + categori)}
                    <Form
                        onSubmit={onUpdate}
                        initialValues={{
                            name: categori.name,
                            category_id: categori.category_id


                        }}


                        render={({ handleSubmit }) => (
                            <FormBS onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <div className="col-12">
                                        <form>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">name</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        name="name"
                                                        component="input"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="name"
                                                    />
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonBS variant="secondary" onClick={handleClose1}>
                                        Close
                                    </ButtonBS>
                                    <ButtonBS variant="primary" type="submit" onClick={handleClose}>
                                        <Icon.Check2Square className="mx-1" size={15} />
                                        Simpan Edit
                                    </ButtonBS>
                                </Modal.Footer>
                            </FormBS>
                        )}
                    />
                </Modal>
            </div>
        </>
    );


}