import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import * as Icon from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import { Url } from "../../../global_konstanta/global";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import swal from "sweetalert";
import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row
} from "react-bootstrap";


export default function TicketDraft(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [tikets, setTikets] = useState([]);
  const [categoris, setCategoris] = useState([]);
  const [kotas, setKotas] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [judul, setJudul] = useState();
  const [tiket, setTiket] = useState([]);
  const [showA, setShowA] = useState(true);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleShow2 = () => setShow2(true);
  const handleShow1 = () => setShow1(true);
  const tulisJudul = (jdl) => setJudul(jdl);
  const toggleShowA = () => setShowA(!showA);

  const handleSubmit = async (values) => {
    if (confirm(values) == true) {
      try {
        await axios.delete(`${Url}/tiket/${values.id}`, {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          }
        });
        getTikets();
        toggleShowA();
        handleClose3();
      } catch (error) {
        console.log(error);
      }
    }
  };
  function randomString(len, an) {
    an = an && an.toLowerCase();
    var str = "",
      i = 0,
      min = an == "a" ? 10 : 0,
      max = an == "n" ? 10 : 62;
    for (; i++ < len;) {
      var r = (Math.random() * (max - min) + min) << 0;
      str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
    }
    return str;
  }
  const onSubmit1 = async (values) => {
    if (values === null) {
      console.log(values);
    } else {
      await axios({
        method: "put",
        url: Url + `/tiket/${values.id}`,
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        },
        data: {
          dt_status_ticket: "ClosePending"
        }
      }).then(function () {
        getTikets();
        handleClose2();
      });
    }
  };

  const onSubmit = async (values) => {
    await axios({
      method: "post",
      url: `${Url}/tiket?tiketid=&status=all`,
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },

      data: values
    })
      .then(function (response) {
        console.log(response);
        getTikets();
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getTikets();
    getCategoris();
    getKotas();
    getTiket();
    //     getUser();
    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 1000);
    });
  }, []);

  const getTikets = async () => {
    await axios
      .get(`${Url}/tiket?tiketid=&status=${props.name}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTikets(value.data.photos);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };
  const getCategoris = async () => {
    const response = await axios.get(`${Url}/category`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.table(`ini=>` + response.data.category);
    setCategoris(response.data.category);
  };

  const getKotas = async () => {
    const response = await axios.get(`${Url}/kota`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    // console.log('byid=>' + response.data.data);
    setKotas(response.data.data);
  };

  const getTiket = async (id) => {
    await axios
      .get(`${Url}/tiketbyId/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        }
      })
      .then((value) => {
        setTiket(value.data.data);
      })
      .catch((err) => {
        console(`errorrr${err}`);
      });
  };
  const onUpdate1 = async (values) => {
    if (user.role === "pm") {
      if (tiket.status_link === "waiting") {
        swal(
          "Pemberitahuan",
          "Please Waiting Report Link Aproved To Leader !",
          'info'
        );
      } else {
        await axios({
          method: 'put',
          url: Url + `/tiket/${values.id}`, headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          },
          data:
            values,
        })
          .then(function () {
            getTikets();
            handleClose2();
          });
      }
    } else {
      if (values === null) {
        console.log(values);
      } else {
        await axios({
          method: 'put',
          url: Url + `/tiket/${values.id}`, headers: {
            Authorization: `${localStorage.getItem("accessToken")}`
          },
          data:
            values,
        })
          .then(function () {
            getTikets();
            handleClose();
          });
      }
    }


  };

  const onUpdate = async (values) => {
    if (values === null) {
      console.log(values);
    } else {
      await axios({
        method: "put",
        url: Url + `/tiket/${values.id}`,
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`
        },
        data: values
      }).then(function () {
        getTikets();
        handleClose1();
      });
    }
  };
  if (user.role === "pm") {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                  </div>
                  <div className="table-responsive table-sm ">
                    <table className="container table" id="table_user">
                      <thead>
                        <tr>
                          <th>Ticket Number</th>
                          <th>Request By</th>
                          <th>Location</th>
                          <th>Ticket Name</th>
                          <th>Region</th>
                          <th>Status Ticket</th>
                          <th>Category</th>
                          <th>Catatan Reject</th>
                          {/* <th>Link Report</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tikets.map((orang) => (
                          <tr key={orang.id}>
                            <td>{orang.dt_ticket_number}</td>
                            <td>{orang.User?.request_name}</td>
                            <td>{orang.dt_city}</td>
                            <td>{orang.dt_primary_plan}</td>
                            <td>{orang.dt_region}</td>
                            <td>{orang.dt_status_ticket}</td>
                            <td>{orang.dt_category}</td>
                            <td>{orang.dt_issue_reject2}</td>
                            {/* <td><a href={orang.dt_link_report} target="_blank" rel="noopener noreferrer">link driver</a></td> */}
                            <td>
                              <div className="btn-group">
                                <ButtonBS
                                  size="sm"
                                  variant="primary"
                                  onClick={() => {
                                    handleShow2();
                                    tulisJudul("Tiket Update");
                                    getTiket(orang.id);
                                  }}>
                                  Action
                                </ButtonBS>
                                {/* <ButtonBS
                                variant="primary float-right"
                                onClick={() => {
                                  handleShow1();
                                  tulisJudul("Edit Tiket");
                                  getTiket(orang.id);
                                }}>
                                Edit
                              </ButtonBS> */}
                                <ButtonBS
                                  variant="danger float-right"
                                  onClick={() => {
                                    handleShow3();
                                    getTiket(orang.id);
                                  }}>
                                  Hapus
                                </ButtonBS>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                dt_ticket_number: "DT" + randomString(10, "n"),
                dt_area: user.area.nama
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="tiket_number"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Select
                              name="dt_city"
                              options={kotas.map((e) => ({
                                label: e.name,
                                value: e.name,
                              }))}
                            />
                            {/* <Field name="dt_city" className="form-control" component="select">
                                                        <option >
                                                            -Location -
                                                        </option>
                                                        {kotas.map((aturan) => (
                                                            <option value={aturan.name} key={aturan.name}>
                                                                {aturan.name}
                                                            </option>
                                                        ))}
                                                    </Field> */}
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="Ticket Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="Customer Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="Phone Number Customer"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_category"
                              className="form-control"
                              component="select">
                              <option>- Category -</option>
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                dt_ticket_number: "DT" + randomString(10, "n"),
                id: tiket?.id,
                // dt_ticket_number: tiket?.dt_ticket_number,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category
                // dt_link_report: tiket?.dt_link_report,
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="tiket_number"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_city"
                              className="form-control"
                              component="select">
                              <option>-Location -</option>
                              {kotas.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="Ticket Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="Customer Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="Phone Number Customer"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_category"
                              className="form-control"
                              component="select">
                              <option>- Category -</option>
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose1}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate1}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                dt_team: tiket?.dt_team,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Tiket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_status_ticket"
                              className="form-control"
                              component="select">
                              <option value="Open">Open</option>
                              <option value="ClosePending">Close</option>
                            </Field>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS
                      variant="secondary"
                      size="sm"
                      onClick={handleClose2}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="warning" size="sm" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Update Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                dt_ticket_number: "DT" + randomString(10, "n"),
                id: tiket?.id,
                // dt_ticket_number: tiket?.dt_ticket_number,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category
                // dt_link_report: tiket?.dt_link_report,
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>Apa anda yakin Ingin Hapus?</Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose3}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="danger" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Hapus
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
        </div>
      </>
    );

  } else {
    return (
      <>
        <Navbar />
        <div className="main">
          <Sidebar />
          <main className="content">
            <div className="container">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                  </div>
                  <div className="table-responsive table-sm ">
                    <table className="container table" id="table_user">
                      <thead>
                        <tr>
                          <th>Ticket Number</th>
                          <th>Request By</th>
                          <th>Location</th>
                          <th>Ticket Name</th>
                          <th>Region</th>
                          <th>Status Ticket</th>
                          <th>Category</th>
                          <th>Catatan Reject</th>
                          {/* <th>Link Report</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tikets.map((orang) => (
                          <tr key={orang.id}>
                            <td>{orang.dt_ticket_number}</td>
                            <td>{orang.User?.request_name}</td>
                            <td>{orang.dt_city}</td>
                            <td>{orang.dt_primary_plan}</td>
                            <td>{orang.dt_region}</td>
                            <td>{orang.dt_status_ticket}</td>
                            <td>{orang.dt_category}</td>
                            <td>{orang.dt_issue_reject2}</td>
                            {/* <td><a href={orang.dt_link_report} target="_blank" rel="noopener noreferrer">link driver</a></td> */}
                            <td>
                              <div className="btn-group">
                                <ButtonBS
                                  variant="success float-right"
                                  onClick={() => {
                                    handleShow1();
                                    tulisJudul("Detail Data Ticket");
                                    getTiket(orang.id);
                                  }}>
                                  Show
                                </ButtonBS>
                                {/* <ButtonBS
                                    variant="primary float-right"
                                    onClick={() => {
                                      handleShow1();
                                      tulisJudul("Edit Tiket");
                                      getTiket(orang.id);
                                    }}>
                                    Edit
                                  </ButtonBS>
                                  <ButtonBS
                                    variant="danger float-right"
                                    onClick={() => {
                                      handleShow3();
                                      getTiket(orang.id);
                                    }}>
                                    Hapus
                                  </ButtonBS> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                dt_ticket_number: "DT" + randomString(10, "n"),
                dt_area: user.area.nama
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              disabled
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="tiket_number"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Select
                              name="dt_city"
                              options={kotas.map((e) => ({
                                label: e.name,
                                value: e.name,
                              }))}
                            />
                            {/* <Field name="dt_city" className="form-control" component="select">
                                                            <option >
                                                                -Location -
                                                            </option>
                                                            {kotas.map((aturan) => (
                                                                <option value={aturan.name} key={aturan.name}>
                                                                    {aturan.name}
                                                                </option>
                                                            ))}
                                                        </Field> */}
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="Ticket Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="Customer Name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="Phone Number Customer"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              name="dt_category"
                              className="form-control"
                              component="select">
                              <option>- Category -</option>
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Simpan Tiket
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>{judul}</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onUpdate}
              initialValues={{
                id: tiket?.id,
                dt_ticket_number: tiket?.dt_ticket_number,
                dt_area: tiket?.dt_area,
                dt_region: tiket?.dt_region,
                dt_request_date: moment(tiket?.dt_request_date).format(
                  "D MMMM YYYY"
                ),
                // request_name: tiket?.User.request_name,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category,
                updated_at: moment(tiket?.updated_at).format("D MMMM YYYY")
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="col-12">
                      <form>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Number</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_ticket_number"
                              component="input"
                              type="text"
                              placeholder="Tiket ID"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Area</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_area"
                              component="input"
                              type="text"
                              placeholder="area"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Region</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_region"
                              component="input"
                              type="text"
                              placeholder="region"
                            />
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Date Request</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_request_date"
                              type="datetime"
                              component="input"
                              placeholder="date"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Schedule</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="updated_at"
                              type="text"
                              component="input"
                              placeholder="link_report"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Location</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_city"
                              type="text"
                              component="input"
                              placeholder="kota"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Ticket Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_primary_plan"
                              type="text"
                              component="input"
                              placeholder="primary_plan"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Ticket Description
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              name="dt_issue"
                              component="textarea"
                              placeholder="Ticket Description"
                              className="form-control"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Customer Name</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_name"
                              type="text"
                              component="input"
                              placeholder="dt_customer_name"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">
                              Phone Number Customer
                            </label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_customer_phone_number"
                              type="text"
                              component="input"
                              placeholder="number customer"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        {/* 
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Troubleshoot</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_action_troubleshoot"
                              type="text"
                              component="input"
                              placeholder="troubleshoot"
                              rows="2"
                            />
                          </Col>
                        </Row>
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Site Cover</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                              className="form-control sm"
                              name="dt_site_cover"
                              type="text"
                              component="input"
                              placeholder="site_cover"
                              rows="2"
                            />
                          </Col>
                        </Row>
         */}
                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Category</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              name="dt_category"
                              className="form-control"
                              component="select">
                              {categoris.map((aturan) => (
                                <option value={aturan.name} key={aturan.name}>
                                  {aturan.name}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>

                        <Row className="form-group">
                          <Col xs={4} md={3}>
                            <label className="form-label">Status Ticket</label>
                          </Col>
                          <Col xs={8} md={9}>
                            <Field
                                 disabled="true"
                              className="form-control sm"
                              name="dt_status_ticket"
                              type="text"
                              component="input"
                              placeholder="status_ticket"
                              rows="2"
                            />
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={onSubmit1}
              initialValues={{
                dt_ticket_number: "DT" + randomString(10, "n"),
                id: tiket?.id,
                // dt_ticket_number: tiket?.dt_ticket_number,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category
                // dt_link_report: tiket?.dt_link_report,
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>
                    Apakah anda yakin Ingin Submit Data Ticket?{" "}
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose2}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="primary" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Submit
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Notice</Modal.Title>
            </Modal.Header>
            {/* {console.log("inisalah=" + user.username)} */}
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                dt_ticket_number: "DT" + randomString(10, "n"),
                id: tiket?.id,
                // dt_ticket_number: tiket?.dt_ticket_number,
                dt_city: tiket?.dt_city,
                dt_primary_plan: tiket?.dt_primary_plan,
                dt_customer_phone_number: tiket?.dt_customer_phone_number,
                dt_customer_name: tiket?.dt_customer_name,
                dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                dt_issue: tiket?.dt_issue,
                dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                dt_site_cover: tiket?.dt_site_cover,
                // dt_status_activity: tiket?.dt_status_activity,
                dt_status_ticket: tiket?.dt_status_ticket,
                dt_remark: tiket?.dt_remark,
                dt_category: tiket?.dt_category
                // dt_link_report: tiket?.dt_link_report,
              }}
              render={({ handleSubmit }) => (
                <FormBS onSubmit={handleSubmit}>
                  <Modal.Body>Apa anda yakin Ingin Hapus?</Modal.Body>
                  <Modal.Footer>
                    <ButtonBS variant="secondary" onClick={handleClose3}>
                      Close
                    </ButtonBS>
                    <ButtonBS variant="danger" type="submit">
                      <Icon.Check2Square className="mx-1" size={15} />
                      Hapus
                    </ButtonBS>
                  </Modal.Footer>
                </FormBS>
              )}
            />
          </Modal>
        </div>
      </>
    );
  }
}