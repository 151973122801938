// import { Link } from "react-router-dom";
import React from "react";
import { FcOk, FcKindle, FcCrystalOscillator, FcKey } from "react-icons/fc";
import { ImHome } from "react-icons/im";

function Navbar(props) {
  const user = JSON.parse(localStorage.getItem("user"));
    if (user.role === "manager") {
      return (
        <nav className="sidebar">
          <div className="sidebar-content ">
            <a className="sidebar-brand" href="dashboard">
              <img
                src="/img/logo/telkom.png"
                width={180}
              />
            </a>
            <ul className="sidebar-nav">
              <li className="sidebar-header">menu</li>
              <li className="sidebar-item">
                <a className="sidebar-link" href="dashboard">
                  <i className="align-middle" data-feather="book-open"></i>{" "}
                  <span className="align-middle"><ImHome></ImHome>Dashboard </span>
                </a>
              </li>
              <li className="sidebar-item">
                <a
                  href="#tiket"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="layout"></i>
                  <span className="align-middle"><FcKindle></FcKindle>Data Ticket </span>
                </a>
                <ul id="tiket" className="sidebar-dropdown list-unstyled collapse ">
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="ticket">
                      All Data Ticket
                    </a>
                  </li>
                  <li className="sidebar-item">
                  <a className="sidebar-link" href="ticket-draft">
                    Draft Ticket
                  </a>
                </li>
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="ticket-aprove">
                      Request Ticket Approval
                    </a>
                  </li>
                  {/* <li className="sidebar-item">
                    <a className="sidebar-link" href="rejectuser">
                      Reject Ticket
                    </a>
                  </li> */}
                  {/* <li className="sidebar-item">
                    <a className="sidebar-link" href="closeaprove">
                      Closing Approval
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="sidebar-item">
                <a
                  href="#tiket1"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="layout"></i>
                  <span className="align-middle"><FcKindle></FcKindle>Data Report </span>
                </a>
                <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-pending">
                    Waiting Approval
                  </a>
                </li>
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="report">
                       Approved
                    </a>
                  </li>
                </ul>

              </li>
              {/* <li className="sidebar-header">Master User</li> */}
              <li className="sidebar-item">
                {/* <a
                  href="#ui"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="grid"></i>{" "}
                  <span className="align-middle"><FcBusinessman></FcBusinessman>Users Anggota </span>
                </a> */}
                {/* <ul id="ui" className="sidebar-dropdown list-unstyled collapse ">
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="user">
                      Users
                    </a>
                  </li>
                  <li className="sidebar-item">
                  </li>
                </ul> */}
              </li>
            </ul>
            {/* <div className="align-text-bottom mt-6">
              <img
                src="/img/logo/sidebar.png"
                className="img-fluid mt-6"
              />
            </div> */}
            <div className="sidebar-bottom d-none d-lg-block">
              <div className="media">
                <img
                  className="rounded-circle mr-3"
                  src="/img/logo/telkom.png"
                  alt="Chris Wood"
                  width="40"
                  height="40"
                />
                <div className="media-body">
                  <h5 className="mb-1">{user.username}</h5>
                  <div>
                    <FcOk></FcOk> {user.role}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      );
    } else if (user.role === "root") {
      return (
        <nav className="sidebar">
          <div className="sidebar-content ">
            <a className="sidebar-brand" href="dashboard">
              <img
                src="/img/logo/telkom.png"
                width={180}
              />
            </a>
            <ul className="sidebar-nav">
              <li className="sidebar-header">menu</li>
              <li className="sidebar-item">
                <a className="sidebar-link" href="dashboard">
                  <i className="align-middle" data-feather="book-open"></i>{" "}
                  <span className="align-middle"><ImHome></ImHome>Dashboard </span>
                </a>
              </li>
              <li className="sidebar-item">
                <a
                  href="#tiket"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="layout"></i>
                  <span className="align-middle"><FcKindle></FcKindle>Data Tiket  </span>
                </a>
                <ul id="tiket" className="sidebar-dropdown list-unstyled collapse ">
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="ticket-aprove">
                      Request Ticket Approval
                    </a>
                  </li>
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="ticket">
                      All Data Ticket
                    </a>
                  </li>
                </ul>
              </li>

              <li className="sidebar-item">
                <a
                  href="#ui"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="grid"></i>{" "}
                  <span className="align-middle"><FcCrystalOscillator></FcCrystalOscillator>Data Users </span>
                </a>
                <ul id="ui" className="sidebar-dropdown list-unstyled collapse ">
                  {/* <li className="sidebar-item">
                    <a className="sidebar-link" href="role">
                      Role
                    </a>
                  </li> */}
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="user">
                      All Data Users
                    </a>
                  </li>
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="userAssigment">
                      Users Assignment
                    </a>
                  </li>
                  <li className="sidebar-item">
                  </li>
                </ul>
              </li>
              <li className="sidebar-item">
                <a
                  href="#umkm"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="layout"></i>{" "}
                  <span className="align-middle"><FcKey></FcKey>Data Master </span>
                </a>
                <ul id="umkm" className="sidebar-dropdown list-unstyled collapse ">
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="/region">
                      Data Regional
                    </a>
                  </li>
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="area">
                      Data Area
                    </a>
                  </li>
                  {/* <li className="sidebar-item">
                    <a className="sidebar-link" href="group">
                      Data Group
                    </a>
                  </li> */}
                  {/* <li className="sidebar-item">
                    <a className="sidebar-link" href="level">
                      Data Level
                    </a>
                  </li> */}
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="categori">
                      Data Category
                    </a>
                  </li>
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="subcategori">
                      Data Sub Category
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li className="sidebar-header">Web Management</li>
              <li className="sidebar-item">
                <a
                  href="#wa"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="grid"></i>{" "}
                  <span className="align-middle"><FcCrystalOscillator></FcCrystalOscillator>WhatsApp Otp </span>
                </a>
                <ul id="wa" className="sidebar-dropdown list-unstyled collapse ">
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="waotp">
                      Settings Otp
                    </a>
                  </li>
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="#">
                      Share Informasi
                    </a>
                  </li>
                  <li className="sidebar-item">
                  </li>
                </ul>
              </li>
              <li className="sidebar-item">
                <a
                  href="#web"
                  data-toggle="collapse"
                  className="sidebar-link collapsed">
                  <i className="align-middle" data-feather="layout"></i>{" "}
                  <span className="align-middle"><FcKey></FcKey>Informasi Systems </span>
                </a>
                <ul id="web" className="sidebar-dropdown list-unstyled collapse ">
                  <li className="sidebar-item">
                    <a className="sidebar-link" href="#">
                      Pengaturan Infomasi web
                    </a>
                  </li>
                </ul>
              </li> */}
            </ul>
            {/* <div className="align-text-bottom mt-6">
              <img
                src="/img/logo/sidebar.png"
                className="img-fluid mt-6"
              />
            </div> */}
            <div className="sidebar-bottom d-none d-lg-block">
              <div className="media">
                <img
                  className="rounded-circle mr-3"
                  src="/img/logo/telkom.png"
                  alt="Chris Wood"
                  width="40"
                  height="40"
                />
                <div className="media-body">
                  <h5 className="mb-1">{user.username}</h5>
                  <div>
                    <FcOk></FcOk> {user.role}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav >
      );
    }
   else if (user.role === "pm") {
    return (
      <nav className="sidebar">
        <div className="sidebar-content ">
          <a className="sidebar-brand" href="dashboard">
            <img
              src="/img/logo/telkom.png"
              width={180}
            />
          </a>
          <ul className="sidebar-nav">
            <li className="sidebar-header">menu</li>
            <li className="sidebar-item">
              <a className="sidebar-link" href="dashboard">
                <i className="align-middle" data-feather="book-open"></i>{" "}
                <span className="align-middle"><ImHome></ImHome>Dashboard </span>
              </a>
            </li>
            <li className="sidebar-item">
              <a
                href="#tiket"
                data-toggle="collapse"
                className="sidebar-link collapsed">
                <i className="align-middle" data-feather="layout"></i>
                <span className="align-middle"><FcKindle></FcKindle>Data Ticket  </span>
              </a>
              <ul id="tiket" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="ticket">
                    All Data Ticket
                  </a>
                </li>
                {/* <li className="sidebar-item">
                  <a className="sidebar-link" href="rejectuser">
                    Reject Ticket
                  </a>
                </li>
                <li className="sidebar-item">
                  <a className="sidebar-link" href="closeaprove">
                    Closing Approval
                  </a>
                </li> */}
              </ul>


            </li>
            <li className="sidebar-item">
              <a
                href="#tiket1"
                data-toggle="collapse"
                className="sidebar-link collapsed">
                <i className="align-middle" data-feather="layout"></i>
                <span className="align-middle"><FcKindle></FcKindle>Data Report  </span>
              </a>

              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-submit">
                    Insert Report
                  </a>
                </li>
              </ul>
              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-pending">
                    Waiting Approval
                  </a>
                </li>
              </ul>
              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-riject">
                     Rejected  </a>
                </li>
              </ul>
              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report">
                     Approved
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <div className="align-text-bottom mt-6">
            {/* <img
              src="/img/logo/sidebar.png"
              className="img-fluid mt-6"
            /> */}
          </div>
          <div className="sidebar-bottom d-none d-lg-block">
            <div className="media">
              <img
                className="rounded-circle mr-3"
                src="/img/logo/telkom.png"
                alt="Chris Wood"
                width="40"
                height="40"
              />
              <div className="media-body">
                <h5 className="mb-1">{user.username}</h5>
                <div>
                  <FcOk></FcOk> {user.role}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav >
    );
  } else if (user.role === "leader") {
    return (
      <nav className="sidebar">
        <div className="sidebar-content ">
          <a className="sidebar-brand" href="dashboard">
            <img
              src="/img/logo/telkom.png"
              width={180}
            />
          </a>
          <ul className="sidebar-nav">
            <li className="sidebar-header">menu</li>
            <li className="sidebar-item">
              <a className="sidebar-link" href="dashboard">
                <i className="align-middle" data-feather="book-open"></i>{" "}
                <span className="align-middle"><ImHome></ImHome>Dashboard </span>
              </a>
            </li>
            <li className="sidebar-item">
              <a
                href="#tiket"
                data-toggle="collapse"
                className="sidebar-link collapsed">
                <i className="align-middle" data-feather="layout"></i>
                <span className="align-middle"><FcKindle></FcKindle>Data Ticket  </span>
              </a>
              <ul id="tiket" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="ticket">
                    All Data Ticket
                  </a>
                </li>
                {/* <li className="sidebar-item">
                  <a className="sidebar-link" href="rejectuser">
                    Reject Ticket
                  </a>
                </li>
                <li className="sidebar-item">
                  <a className="sidebar-link" href="closeaprove">
                    Closing Approval
                  </a>
                </li> */}
              </ul>
            </li>
            <li className="sidebar-item">
              <a
                href="#tiket1"
                data-toggle="collapse"
                className="sidebar-link collapsed">
                <i className="align-middle" data-feather="layout"></i>
                <span className="align-middle"><FcKindle></FcKindle>Data Report  </span>
              </a>
              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-submit">
                    Insert Report
                  </a>
                </li>
              </ul>
              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-pending">
                    Waiting Approval
                  </a>
                </li>
              </ul>
              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-riject">
                     Rejected  </a>
                </li>
              </ul>
              <ul id="tiket1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report">
                     Approved
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <div className="align-text-bottom mt-6">
            {/* <img
              src="/img/logo/sidebar.png"
              className="img-fluid mt-6"
            /> */}
          </div>
          <div className="sidebar-bottom d-none d-lg-block">
            <div className="media">
              <img
                className="rounded-circle mr-3"
                src="/img/logo/telkom.png"
                alt="Chris Wood"
                width="40"
                height="40"
              />
              <div className="media-body">
                <h5 className="mb-1">{user.username}</h5>
                <div>
                  <FcOk></FcOk> {user.role}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav >
    );
  } else {
    return (
      <nav className="sidebar">
        <div className="sidebar-content ">
          <a className="sidebar-brand" href="dashboard">
            <img
              src="/img/logo/telkom.png"
              width={180}
            />
          </a>
          <ul className="sidebar-nav">
            <li className="sidebar-header">{props.data} menu</li>
            <li className="sidebar-item">
              <a className="sidebar-link" href="dashboard">
                <i className="align-middle" data-feather="book-open"></i>{" "}
                <span className="align-middle"><ImHome></ImHome>Dashboard </span>
              </a>
            </li>
            <li className="sidebar-item">
              <a
                href="#umkm"
                data-toggle="collapse"
                className="sidebar-link collapsed">
                <i className="align-middle" data-feather="layout"></i>{" "}
                <span className="align-middle"><FcKindle></FcKindle>Data Ticket </span>
              </a>
              <ul id="umkm" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="ticket">
                    All Data Ticket
                  </a>
                </li>
              </ul>
              <ul id="umkm" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="ticket-draft">
                    Draft Ticket
                  </a>
                </li>
              </ul>
              {/* <ul id="umkm" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="rejectuser">
                    Reject Ticket
                  </a>
                </li>
              </ul> */}
              <ul id="umkm" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="pendinguser">
                    Request Ticket Approval
                  </a>
                </li>
              </ul>
              {/* <ul id="umkm" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="closeaprove">
                    Closing Approval
                  </a>
                </li>
              </ul> */}
            </li>
            <li className="sidebar-item">
              <a
                href="#umkm1"
                data-toggle="collapse"
                className="sidebar-link collapsed">
                <i className="align-middle" data-feather="layout"></i>{" "}
                <span className="align-middle"><FcKindle></FcKindle> Data Report </span>
              </a>
              <ul id="umkm1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report-pending">
                    Waiting Approval
                  </a>
                </li>
              </ul>
              <ul id="umkm1" className="sidebar-dropdown list-unstyled collapse ">
                <li className="sidebar-item">
                  <a className="sidebar-link" href="report">
                     Approved
                  </a>
                </li>
              </ul>

            </li>
          </ul>
          <div className="align-text-bottom mt-6">
            {/* <img
              src="/img/logo/sidebar.png"
              className="img-fluid mt-6"
            /> */}
          </div>
          <div className="sidebar-bottom d-none d-lg-block">
            <div className="media">
              <img
                className="rounded-circle mr-3"
                src="/img/logo/telkom.png"
                alt="Chris Wood"
                width="40"
                height="40"
              />
              <div className="media-body">
                <h5 className="mb-1">{user.username}</h5>
                <div>
                  <FcOk></FcOk> {user.role}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
