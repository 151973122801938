import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// const io = require("socket.io-client");
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { MdSensorsOff , MdSensors} from "react-icons/md";
import QRCode from 'qrcode.react';
// import { Url } from "../../global_konstanta/global";

// const socket = io(Url, {
//   withCredentials: true,
// });

class PageWa extends React.Component {
   
      constructor(props) {
        super(props);
        this.state = {qrcode: ""};
        this.state = {konek: false};
      }
    
      componentDidMount() {
        setInterval(
            () => this.tick(),
            2000
          );
      }
    
      componentWillUnmount() {
        clearInterval(this.timerID);
      }

      componentDidUpdate() {
        setInterval(
            () => this.tick(),
            5000
          );
      }

      tick() {
        // if (socket.connected) {
        //     socket.on("qrcode", (arg) => {
        //         this.setState({
        //             qrcode: arg
        //         });
        //     });

        //     socket.on("ready", (value) => {
        //         if(value === "true"){
        //             this.setState({
        //                 konek: true
        //             });
        //         }
               
        //     });
        // } else {
        //     socket.on("qrcode", (arg) => {
        //         this.setState({
        //             qrcode: arg
        //         });
        //     });

        //     socket.on("ready", (value) => {
        //         if(value === "true"){
        //             this.setState({
        //                 konek: true
        //             });
        //         }
               
        //     });
        // }
       
      }


    render(){
        return (
            <>
                <Navbar />
                <div className="main" >
                    <Sidebar />
                    <main className="content">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                <p className="text">
                                        <strong className="text-warning">Disconected</strong> {this.state.konek ? <MdSensors size={20}></MdSensors> : <MdSensorsOff className="danger" size={20}></MdSensorsOff>}  
                                    </p> 
   
                                    <div className="row">
                                        <div className="col-md-6">
                                            <strong></strong>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                    <div className="text-center">
                                    <p className="text ">
                                        <strong>Scan Me :</strong> Mohon Tunggu Beberapa Saat ya  .<BsWhatsapp></BsWhatsapp>
                                    </p>
                                    {this.state.konek ? <img
                        className="img-fluid rounded"
                        src="/img/logo/WHATSAPP.png" width="40%"
                        alt="Third slide"
                      /> : <QRCode className="text-sm mb-4" value={this.state.qrcode} renderAs="canvas" size="200" />  }
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </main>
    
                </div>

            </>
        );
    }
    


    
}

export default PageWa;
