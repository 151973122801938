import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import * as Icon from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import { Url } from "../../../global_konstanta/global";
import axios from "axios";
import moment from "moment";
import {
    Button as ButtonBS,
    Col,
    Form as FormBS,
    Modal,
    Row
} from "react-bootstrap";

export default function Pending_Pm(props) {

    const [setTikets] = useState([]);
    const [tiketsClose, setTiketsClose] = useState([]);
    const [setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [setJudul] = useState();
    const [tiket, setTiket] = useState([]);


    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const tulisJudul = (jdl) => setJudul(jdl);

    // function refreshPage() {
    //     window.location.reload(false);
    //   }
    // const handleSubmit = async (id) => {
    //     if (confirm(id) == true) {
    //         try {
    //             await axios.delete(`${Url}/tiket/${id}`, {
    //                 headers: {
    //                     Authorization: `${localStorage.getItem("accessToken")}`
    //                 }
    //             });
    //             getTikets();
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // };

    // const onSubmit = async (values) => {
    //     await axios({
    //         method: "post",
    //         url: `${Url}/tiket?tiketid=&status=all`, headers: {
    //             Authorization: `${localStorage.getItem("accessToken")}`
    //         },

    //         data: values
    //     })
    //         .then(function (response) {
    //             console.log(response);
    //             getTikets();
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };

    // const Aproved = async (id) => {
    //     await axios({
    //         method: "put",
    //         url: `${Url}/tiket/${id}`, headers: {
    //             Authorization: `${localStorage.getItem("accessToken")}`
    //         },

    //         data: {
    //             "dt_status_ticket": "Close"
    //         }
    //     })
    //         .then(function (response) {
    //             console.log(response);
    //             getTikets();
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             getTikets();
    //         });
    // };

    useEffect(() => {
        getTikets();
        getTiket();
        getTiketsClose();
        //     getUser();
        $(document).ready(function () {
            setTimeout(function () {
                $("#table_user").DataTable();
            }, 1000);
        });
    }, []);

    const getTikets = async () => {
        await axios.get(`${Url}/tiket?tiketid=&status=${props.name}`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        }).then(value => {
            setTikets(value.data.photos);
        }).catch(err => {
            console.log(`errorrr${err}`);
        });


    };

    const getTiket = async (id) => {
        await axios.get(`${Url}/tiketbyId/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        }).then(value => {
            setTiket(value.data.data);
        }).catch(err => {
            console(`errorrr${err}`);
        });



    };
    const getTiketsClose = async () => {

        await axios.get(`${Url}/tiket?tiketid=&status=ClosePending`, {
            headers: {
                Authorization: `${localStorage.getItem("accessToken")}`
            }
        }).then(value => {
            setTiketsClose(value.data.photos);
        }).catch(err => {
            console(`errorrr${err}`);
        });



    };

    const onUpdate = async (values) => {
        if (values === null) {
            console.log(values);
        } else {
            await axios({
                method: 'put',
                url: Url + `/tiket/${values.id}`, headers: {
                    Authorization: `${localStorage.getItem("accessToken")}`
                },
                data: values
            })
                .then(function () {
                    getTikets();
                    handleClose();
                });
        }


    };
    return (
        <>
            <Navbar />
            <div className="main">
                <Sidebar />
                <main className="content">
                    <div className="container">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title text-bold">
                                        Data Tiket Closing Approval
                                    </div>
                                </div>
                                <div className="table-responsive table-sm">
                                    <table className="container table" id="table_user">
                                        <thead>
                                            <tr>
                                                <th>Ticket Number</th>
                                                <th>Request By</th>
                                                <th>Kota</th>
                                                <th>Primary Plan</th>
                                                <th>Region</th>
                                                <th>Status Ticket</th>
                                                <th>Category</th>
                                                {/* <th>Link Report</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tiketsClose.map((orang) => (
                                                <tr key={orang.id}>
                                                    <td>{orang.dt_ticket_number}</td>
                                                    <td>{orang.User?.request_name}</td>
                                                    <td>{orang.dt_city}</td>
                                                    <td>{orang.dt_primary_plan}</td>
                                                    <td>{orang.dt_region}</td>
                                                    <td>{orang.dt_status_ticket}</td>
                                                    <td>{orang.dt_category}</td>
                                                    {/* <td><a href={orang.dt_link_report} target="_blank" rel="noopener noreferrer">link driver</a></td> */}
                                                    <td>
                                                        <div className="btn-group">
                                                            <ButtonBS
                                                                variant="success float-right"
                                                                onClick={() => {
                                                                    handleShow1();
                                                                    tulisJudul("Detail Data Ticket");
                                                                    getTiket(orang.id);
                                                                }}>
                                                                Show
                                                            </ButtonBS>
                                                            {/* <ButtonBS
                                                                variant="primary float-right"
                                                                onClick={() => {
                                                                    Aproved(orang.id);
                                                                }}>
                                                                Aproved
                                                            </ButtonBS> */}

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>



                <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detail Data Ticket</Modal.Title>
                    </Modal.Header>
                    {/* {console.log("inisalah=" + user.username)} */}
                    <Form
                        onSubmit={onUpdate}
                        initialValues={{
                            id: tiket?.id,
                            dt_ticket_number: tiket?.dt_ticket_number,
                            dt_area: tiket?.dt_area,
                            dt_region: tiket?.dt_region,
                            dt_request_date: moment(tiket?.dt_request_date).format('D MMMM YYYY'),
                            // request_name: tiket?.User.request_name,
                            dt_city: tiket?.dt_city,
                            dt_primary_plan: tiket?.dt_primary_plan,
                            dt_customer_phone_number: tiket?.dt_customer_phone_number,
                            dt_customer_name: tiket?.dt_customer_name,
                            dt_pic_request_tsel: tiket?.dt_pic_request_tsel,
                            dt_issue: tiket?.dt_issue,
                            dt_action_troubleshoot: tiket?.dt_action_troubleshoot,
                            dt_site_cover: tiket?.dt_site_cover,
                            dt_status_activity: tiket?.dt_status_activity,
                            dt_status_ticket: tiket?.dt_status_ticket,
                            dt_remark: tiket?.dt_remark,
                            dt_category: tiket?.dt_category,
                            updated_at: moment(tiket?.updated_at).format('D MMMM YYYY'),

                        }}
                        render={({ handleSubmit, }) => (
                            <FormBS onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <div className="col-12">
                                        <form>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Ticket Number</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_ticket_number"
                                                        component="input"
                                                        type="text"
                                                        placeholder="Tiket ID"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Area</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_area"
                                                        component="input"
                                                        type="text"
                                                        placeholder="area"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Region</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_region"
                                                        component="input"
                                                        type="text"
                                                        placeholder="region"
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Date Request</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_request_date"
                                                        type="datetime"
                                                        component="input"
                                                        placeholder="date"
                                                    />
                                                </Col>
                                            </Row>
                                            {/* <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Schedule</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        disabled="true"
                                                        className="form-control sm"
                                                        name="updated_at"
                                                        type="text"
                                                        component="input"
                                                        placeholder="link_report"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row> */}
                                            {/* <Row className="form-group">
                                                    <Col xs={4} md={3}>
                                                        <label className="form-label">Request By</label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Field
                                                            className="form-control sm"
                                                            name="request_name"
                                                            type="text"
                                                            component="input"
                                                            placeholder="Request By"
                                                            rows="2"
                                                        />
                                                    </Col>
                                                </Row> */}
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Location</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_city"
                                                        type="text"
                                                        component="input"
                                                        placeholder="kota"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Ticket Name</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_primary_plan"
                                                        type="text"
                                                        component="input" placeholder="primary_plan"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Ticket Description</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_issue"
                                                        type="text"
                                                        component="input"
                                                        placeholder="Issue"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Customer Name</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_customer_name"
                                                        type="text"
                                                        component="input"
                                                        placeholder="dt_customer_name"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Phone Number Customer</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_customer_phone_number"
                                                        type="text"
                                                        component="input"
                                                        placeholder="number customer"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>

                                            {/* <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Troubleshoot</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        className="form-control sm"
                                                        name="dt_action_troubleshoot"
                                                        type="text"
                                                        component="input"
                                                        placeholder="troubleshoot"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Site Cover</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        className="form-control sm"
                                                        name="dt_site_cover"
                                                        type="text"
                                                        component="input"
                                                        placeholder="site_cover"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row> */}
                                            {/* <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Status Activity</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        className="form-control sm"
                                                        name="dt_status_activity"
                                                        type="text"
                                                        component="input"
                                                        placeholder="status_activity"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Data Remark</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                        className="form-control sm"
                                                        name="dt_remark"
                                                        type="text"
                                                        component="input"
                                                        placeholder="remark"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row> */}
                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Category</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field  disabled="true" name="dt_category" className="form-control" type="text" component="input">

                                                    </Field>
                                                </Col>
                                            </Row>

                                            <Row className="form-group">
                                                <Col xs={4} md={3}>
                                                    <label className="form-label">Status Ticket</label>
                                                </Col>
                                                <Col xs={8} md={9}>
                                                    <Field
                                                         disabled="true"
                                                        className="form-control sm"
                                                        name="dt_status_ticket"
                                                        type="text"
                                                        component="input"
                                                        placeholder="status_ticket"
                                                        rows="2"
                                                    />
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    {/* <ButtonBS variant="secondary" onClick={handleClose1}>
                                        Close
                                    </ButtonBS>
                                    <ButtonBS variant="primary" type="submit">
                                        <Icon.Check2Square className="mx-1" size={15} />
                                        Update Tiket
                                    </ButtonBS> */}
                                </Modal.Footer>
                            </FormBS>
                        )}
                    />
                </Modal>
            </div>
        </>
    );
}
