import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import * as Icon from "react-bootstrap-icons";

import {
  Button as ButtonBS,
  Col,
  Form as FormBS,
  Modal,
  Row
} from "react-bootstrap";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";

import $ from "jquery";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Url } from "../../global_konstanta/global";
import axios from "axios";

export default function UserAssigment() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [show, setShow] = useState(false);
  const [roles, setRoles] = useState([]);
  const [Levels, setLevels] = useState([]);
  // const [groups, setGroups] = useState([]);
  const [regions, setRegions] = useState({});
  const [areas, setAreas] = useState({});
  const [role, setRole] = useState({});
  const [level, setLevel] = useState({});
  const [group, setGroup] = useState({});
  const [region, setRegion] = useState({});
  const [area, setArea] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUsers();
    getRoles();
    getLevels();
    // getGroups();
    getRegions();
    getAreas();
    $(document).ready(function () {
      setTimeout(function () {
        $("#table_user").DataTable();
      }, 500);
    });
  }, []);

  // const onSubmit = async (values) => {
  //   await axios({
  //     method: 'put',
  //     url: 'http://localhost:4100/users/' + values.id, headers: {
  //       Authorization: `${localStorage.getItem("accessToken")}`

  //     },
  //     data: values
  //   })
  //     .then(function () {
  //       getUsers();
  //       // getUser();
  //       handleClose();
  //     });

  // };
  const onSubmit = async (values) => {
    await axios({
      method: 'put',
      url: Url + '/users/' + values.id, headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      },
      data: values
    })
      .then(function (response) {
        console.log(response);
        getUsers();
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUsers = async () => {
    const response = await axios.get(`${Url}/users`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.log('userdaata=>' + response.data.data);
    setUsers(response.data.data);


  };
  const getUser = async (id) => {
    const response = await axios.get(`${Url}/usersbyId/${id}`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });

    setUser(response.data.data);
    setRole(response.data.data.Groups[0].Role.id);
    setLevel(response.data.data.Groups[0].Level.id);
    setGroup(response.data.data.Groups[0].Groupuser.id);
    setRegion(response.data.data.Groups[0].Region.id_region);
    setArea(response.data.data.Groups[0].Area.id_area);
  };
  const getRoles = async () => {
    const response = await axios.get(`${Url}/role`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.table(`ini=>` + response.data.role);
    setRoles(response.data.role);
  };
  const getLevels = async () => {
    const response = await axios.get(`${Url}/level`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.table(`ini=>` + response.data?.role);
    setLevels(response.data.role);
  };

  // const getGroups = async () => {
  //   const response = await axios.get(`${Url}/group`, {
  //     headers: {
  //       Authorization: `${localStorage.getItem("accessToken")}`
  //     }

  //   });
  //   console.table(`ini=>` + response.data.role);
  //   setGroups(response.data.role);
  // };
  const getRegions = async () => {
    const response = await axios.get(`${Url}/region`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }
    });
    console.log('region=>' + response.data.id_region);
    setRegions(response.data.data);
  };

  const getAreas = async () => {
    const response = await axios.get(`${Url}/area`, {
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`
      }

    });
    console.log(`ini=>` + response.data.role.id);
    setAreas(response.data.role);
  };
  return (
    <>
      <Navbar />
      <div className="main">
        <Sidebar />
        <main className="content">
          <div className="container">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title text-strong">
                    <h1>Users Assignment</h1>
                  </div>
                </div>
                <div className="table-responsive table-sm">
                  <table className="table table-striped" id="table_user">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Level</th>
                        {/* <th>Group</th> */}
                        <th>Region</th>
                        <th>Area</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((orang) => (
                        <tr key={orang.id}>
                          <td>{orang?.nama}</td>
                          <td>{orang?.email}</td>
                          <td>{orang?.Groups[0].Role.name}</td>
                          <td>{orang?.Groups[0].Level.name}</td>
                          {/* <td>{orang?.Groups[0].Groupuser.name}</td> */}
                          <td>{orang?.Groups[0].Region.region}</td>
                          <td>{orang?.Groups[0].Area.area}</td>
                          <td>
                            <ButtonBS
                              size="sm"
                              className="mx-1"
                              variant="warning"
                              onClick={() => {
                                handleShow();
                                getUser(orang.id);
                              }}>
                              <Icon.PersonCheckFill
                                className="mx-1 mb-1"
                                size={18}
                              />
                              Set Role
                            </ButtonBS>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Penugasan User</Modal.Title>
          </Modal.Header>
          {console.log("role =>" + role)}
          {console.log("level =>" + level)}
          {console.log("group =>" + group)}
          {console.log("region =>" + region)}
          {console.log("area =>" + area)}
          <Form
            onSubmit={onSubmit}
            initialValues={{
              id: user?.id,
              username: user?.username,
              email: user?.email,
              role_id: role,
              level_id: level,
              group_id: group,
              region_id: region,
              id_area: area
            }}
            render={({ handleSubmit }) => (
              <FormBS onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="col-12">
                    <form>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">username</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="username"
                            component="input"
                            type="text"
                            className="form-control"
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">
                            Email address
                          </label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field
                            name="email"
                            component="input"
                            type="text"
                            className="form-control"
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Role</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field name="role_id" className="form-control" component="select">
                            {roles.map((aturan) => (
                              <option value={aturan.id} key={aturan.id}>
                                {aturan.name}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row>

                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Level</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field name="level_id" className="form-control" component="select">
                            {Levels.map((aturan) => (
                              <option value={aturan.id} key={aturan.id}>
                                {aturan.name}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row>

                      {/* <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Group</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field name="group_id" className="form-control" component="select">
                            {groups.map((aturan) => (
                              <option value={aturan.id} key={aturan.id}>
                                {aturan.name}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row> */}
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Region</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field name="region_id" className="form-control" component="select">
                            {regions.map((aturan) => (
                              <option value={aturan.id_region} key={aturan.id_region}>
                                {aturan.region}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col xs={4} md={3}>
                          <label className="form-label">Group</label>
                        </Col>
                        <Col xs={8} md={9}>
                          <Field name="id_area" className="form-control" component="select">
                            {areas.map((aturan) => (
                              <option value={aturan.id_area} key={aturan.id_area}>
                                {aturan.area}
                              </option>
                            ))}
                          </Field>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonBS variant="secondary" onClick={handleClose}>
                    Batal
                  </ButtonBS>
                  <ButtonBS variant="primary" type="submit">
                    <Icon.Check2Square className="mx-1" size={15} />
                    Simpan Pengaturan
                  </ButtonBS>
                </Modal.Footer>

              </FormBS>
            )}
          />
        </Modal>
      </div>
    </>
  );
}
